import Pepperoni from "../assets/pepperoni.jpg";
import Margherita from "../assets/margherita.jpg";
import PedroTechSpecial from "../assets/pedrotechspecial.jpg";
import Vegan from "../assets/vegan.jpg";
import Pineapple from "../assets/pineapple.jpg";
import Expensive from "../assets/expensive.jpg";

export const ProfileList = [
  {
    id:1,
    name: "EP-1",
    // image: Pepperoni,
    role: "Web Developer",
    department: "Development",
    email: "shivneel@oceanic.com.fj",
    phone: 9999999,
    link: '/profile',
  },

  {
    id:2,
    name: "EP-2",
    // image: Margherita,
    role: "Web Developer",
    department: "Development",
    email: "shivneel@oceanic.com.fj",
    phone: 679-8765432,
    link: '/profile',
  },

  {
    id:3,
    name: "EP-3",
    image: Vegan,
    role: "Web Developer",
    department: "Development",
    email: "shivneel@oceanic.com.fj",
    phone: 679-8765432,
    link: '/profile',
  },
];