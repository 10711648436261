import React from "react";
import "../styles/TeamProfile.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from './../components/Navbar';
import "../styles/Main.css";
import axios from "axios";
import { faLink, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import $ from 'jquery';
import Swal from 'sweetalert2';
import staffImg from '../assets/img/OceanicLogo.png';
import { useLocation } from 'react-router-dom';
import dayjs from "dayjs";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { faBars, faAsterisk, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'


function Staff_Inside_Page() {
    const location = useLocation();
    const navigate = useNavigate();
    let [selected, setSelected] = useState(1);
    let [genderList, setgenderList] = useState([]);
    let [roles, setRoles] = useState([]);
    let [employmentTypeList, setEmploymentTypeList] = useState([]);
    let [postionList, setPostionList] = useState([]);
    let [cityTownList, setCityTownList] = useState([]);
    let [departmentList, setDepartmentList] = useState([]);
    let [emergencyContact, setEmergencyContact] = useState('');
    let [emergencyName, setEmergencyName] = useState('');
    let [fname, setfname] = useState('');
    let [lname, setlname] = useState('');
    let [TIN, setTIN] = useState('');
    let [dateOfBirth, setdateOfBirth] = useState('');
    let [startDate, setStartDate] = useState('');
    let [contractEndDate, setContractEndDate] = useState('');
    let [phone1, setphone1] = useState('');
    let [email, setemail] = useState('');
    let [streetAddress, setStreetAddress] = useState('');
    let [streetAddressLine2, setStreetAddressLine2] = useState('');
    let [staff_Gender, setstaff_Gender] = useState('');
    let [city, setcity] = useState('');
    let [position, setposition] = useState('');
    let [employmentType, setemploymentType] = useState('');
    let [reportsTo, setreportsTo] = useState('');
    let [password, setpassword] = useState([]);
    let [FNPF, setFNPF] = useState([]);
    let check = '';
    let [department, setDepartment] = useState('');

    let lookupsSubmit = e => {
        e.preventDefault();
    }

    let updateStaffDetailsForm = e => {
        e.preventDefault();
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        var genderID = $('#genderDDL').find(":selected").val();
        var cityID = $('#selectCityTown').find(":selected").val();
        var postionTitleID = $('#selectPostionTitle').find(":selected").val();
        var departmentID = $('#selectDepartment').find(":selected").val();
        var employmentTypeID = $('#selectEmploymentType').find(":selected").val();

        axios({
            method: "POST",
            url: "staffmanagement/addStaff",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                id: location.state.staffInfo.id,
                first_name: fname,
                last_name: lname,
                email: email,
                dob: dateOfBirth,
                street_address: streetAddress,
                street_address_line2: streetAddressLine2,
                city: parseInt(cityID),
                tin: TIN,
                fnpf: FNPF,
                phone1: phone1,
                emergency_contact_name: emergencyName,
                emergency_contact_phone: emergencyContact,
                gender: parseInt(genderID),
                department_type: parseInt(departmentID),
                employment_type: parseInt(employmentTypeID),
                position_title: parseInt(postionTitleID),
                start_date: startDate,
                end_date: contractEndDate,
                // Contract End Date (if applicable)
                // E-mail 
            },
        })
            .then(async result => {
                if (result.data.code == 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: result.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                    })
                } else if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Staff details updated successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    //navigate to prev page
                    navigate("/staff")
                } else if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to update this page.',
                        showConfirmButton: false,
                        timer: 2500,
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin!',
                    showConfirmButton: false,
                    timer: 1500,
                })
            });
    }

    let disbaleStaffDetailsForm = () => {
        $("#fname").prop("disabled", true);
        $("#lname").prop("disabled", true);
        // $("#staff_OtherNames").prop("disabled", true);
        $("#email").prop("disabled", true);
        $("#tin").prop("disabled", true);
        $("#fnpf").prop("disabled", true);
        $("#selectCityTown").prop("disabled", true);
        $("#gender").prop("disabled", true);
        $("#dateOfBirth").prop("disabled", true);
        $("#phone1").prop("disabled", true);
        $("#emergencyName").prop("disabled", true);
        $("#emergencyContact").prop("disabled", true);
        $("#streetAddress").prop("disabled", true);
        $("#genderDDL").prop("disabled", true);
        $("#isactive_yes").prop("disabled", true);
        $("#isactive_no").prop("disabled", true);
        $("#streetAddressLine2").prop("disabled", true);
        $("#selectPostionTitle").prop("disabled", true);
        $("#selectDepartment").prop("disabled", true);
        $("#selectEmploymentType").prop("disabled", true);
        $("#startDate").prop("disabled", true);
        $("#contractEndDate").prop("disabled", true);
        $(".updateStaffDetailsForm").css("display", "none");
        // $(".cancelStaffDetailsForm").css("display", "none");
    }

    let enbaleStaffDetailsForm = () => {
        $("#fname").prop("disabled", false);
        $("#lname").prop("disabled", false);
        // $("#staff_OtherNames").prop("disabled", true);
        $("#email").prop("disabled", false);
        $("#tin").prop("disabled", false);
        $("#fnpf").prop("disabled", false);
        $("#selectCityTown").prop("disabled", false);
        $("#gender").prop("disabled", false);
        $("#dateOfBirth").prop("disabled", false);
        $("#phone1").prop("disabled", false);
        $("#emergencyName").prop("disabled", false);
        $("#emergencyContact").prop("disabled", false);
        $("#streetAddress").prop("disabled", false);
        $("#genderDDL").prop("disabled", false);
        $("#isactive_yes").prop("disabled", false);
        $("#isactive_no").prop("disabled", false);
        $("#streetAddressLine2").prop("disabled", false);
        $("#selectPostionTitle").prop("disabled", false);
        $("#selectDepartment").prop("disabled", false);
        $("#selectEmploymentType").prop("disabled", false);
        $("#startDate").prop("disabled", false);
        $("#contractEndDate").prop("disabled", false);
        // $(".updateStaffDetailsForm").css("display", "none");
        // $(".cancelStaffDetailsForm").css("display", "none");
    }

    const handleChange = e => {
        setSelected(e.target.value);
    }

    let closeStaffDetailsForm = e => {
        e.preventDefault();
        navigate("/staff")
    }

    let editStaffDetailsForm = e => {
        e.preventDefault();
        $(".updateStaffDetailsForm").css("display", "block");
        $(".cancelStaffDetailsForm").css("display", "block");
        $(".editstaffDetailsFor").css("display", "none");
        enbaleStaffDetailsForm();
    }

    useEffect(() => {
        let dobDate = location.state.staffInfo.dob;
        dobDate = dayjs(dobDate).format("YYYY-MM-DD");
        let startDate = location.state.staffInfo.start_date;
        startDate = dayjs(startDate).format("YYYY-MM-DD");
        let endDate = location.state.staffInfo.end_date;
        endDate = dayjs(endDate).format("YYYY-MM-DD");
        setfname(location.state.staffInfo.first_name);
        setlname(location.state.staffInfo.last_name);
        // setstaff_OtherNames(location.state.staffInfo.other_names);
        setstaff_Gender(location.state.staffInfo.gender_str);
        setdateOfBirth(dobDate);
        setemail(location.state.staffInfo.email);
        setTIN(location.state.staffInfo.tin);
        setFNPF(location.state.staffInfo.fnpf);
        setEmergencyName(location.state.staffInfo.emergency_contact_name);
        setEmergencyContact(location.state.staffInfo.emergency_contact_phone);
        setphone1(location.state.staffInfo.phone1);
        setStartDate(startDate);
        setContractEndDate(endDate);
        setStreetAddress(location.state.staffInfo.street_address);
        setStreetAddressLine2(location.state.staffInfo.street_address_line2);
        setcity(location.state.staffInfo.staffs_city_str);
        setposition(location.state.staffInfo.staffs_posTitle_str);
        setemploymentType(location.state.staffInfo.staffs_emplyType_str);
        setreportsTo(location.state.staffInfo.staffs_reportTo_str);
        setDepartment(location.state.staffInfo.staffs_departType_str);

        //User Status - set Active/Inactive
        if (location.state.staffInfo.isActive == true) {
            setSelected(1);
            $("#isactive_yes").prop("checked", true);
        } else {
            setSelected(0);
            $("#isactive_no").prop("checked", true);
        }

        //diable form fields
        disbaleStaffDetailsForm();

        //Get genderlist here
        const fetchata = async () => {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);

            axios({
                method: "GET",
                url: "lookups/getAddEmployeesData",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(async result => {
                    setRoles(result.data.roles);
                    setgenderList(result.data.genderList);
                    setEmploymentTypeList(result.data.employmentTypeList);
                    setPostionList(result.data.postionList);
                    setCityTownList(result.data.cityTownList);
                    setDepartmentList(result.data.departmentList);

                    //change the value of drop down on matching the id
                    genderList = result.data.genderList;
                    check = genderList.find(v => v.id == location.state.staffInfo.gender);
                    // $(".ddl_default").val(check.id); 
                    // $('.ddl_default').text(check.name); 
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin 111',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
        // Call the function
        fetchata();
    }, []);

    return (
        <div className="staffInsidePage p-4">
            <Navbar />
            <div className="staffInsidePageDiv">
                <form id="staffDetailsForm" onSubmit={lookupsSubmit}>
                    <div className="row">
                        <div className="col-12 bg-dark text-center">
                            <img className="img-responsive img-rounded" src={staffImg}></img>
                        </div>
                        <h5 className="mt-4 fw-bold">Personal Information</h5>
                        <div className="row mt-4">
                            <label htmlFor="name" className="form-label align-self-end mb-0">Name</label>
                            {/* First Name */}
                            <div className="form-group col-md-6 pb-0">
                                <div className="input-group">
                                    <input type="text" className="form-control" id="fname" name="fname" value={fname} onChange={(e) => setfname(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <label htmlFor="name" className="form-label align-self-end mt-2 text-secondary greyLabelCls mb-0">First Name&nbsp;</label>
                                <small id="fname_msg" className="showmsg"></small>
                            </div>
                            {/* Last name */}
                            <div className="form-group col-md-6 pb-0">
                                <div className="input-group">
                                    <input type="text" className="form-control" id="lname" name="lname" value={lname} onChange={(e) => setlname(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <label htmlFor="name" className="form-label align-self-end mt-2 text-secondary greyLabelCls mb-0">Last Name&nbsp;</label>
                                <small id="lname_msg" className="showmsg"></small>
                            </div>


                            {/* Select Gender */}
                            <div className="form-group dropdown col-md-6">
                                <label htmlFor="genderDDL" className="form-label align-self-end mb-0 pb-2">Gender</label>
                                <div className="input-group">
                                    <select id="genderDDL" className="form-select" aria-label="Gender list DDl" onChange={(e) => setstaff_Gender(e.target.value)}>
                                        <option id="genderDDL_ID" hidden disabled value={location.state.staffInfo.gender} selected>{staff_Gender}</option>
                                        {
                                            genderList.map((list, key) =>
                                                <option key={key} className="dropdown-item" value={list.id}>{list.name}</option>
                                            )
                                        }
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                            </div>

                            {/* Date of Birth */}
                            {/* <div className="row"> */}
                            <div className="form-group col-md-6">
                                <label htmlFor="name" className="form-label align-self-end mb-0">Birth Date&nbsp;</label>
                                <div className="input-group">
                                    <input type="date" className="form-control" id="dateOfBirth" placeholder="Date of Birth" name="dateOfBirth" value={dateOfBirth} onChange={(e) => setdateOfBirth(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="dateOfBirth_msg" className="showmsg"></small>
                            </div>
                            {/* </div> */}

                            {/* TIN */}
                            <div className="form-group col-md-6">
                                <label htmlFor="tin" className="form-label align-self-end mb-0  pb-2">TIN</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="tin" name="tin" value={TIN} onChange={(e) => setTIN(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="tin_msg" className="showmsg"></small>
                            </div>
                            {/* FNPF */}
                            <div className="form-group col-md-6">
                                <label htmlFor="fnpf" className="form-label align-self-end mb-0  pb-2">FNPF</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="fnpf" name="fnpf" value={FNPF} onChange={(e) => setFNPF(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="fnpf_msg" className="showmsg"></small>
                            </div>
                            {/* Address - Street Address*/}
                            <label className="form-label align-self-end mb-0 mt-2">Address</label>
                            <div className="form-group col-md-12">
                                <div className="input-group">
                                    <input className="form-control" id="streetAddress" name="streetAddress" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <label htmlFor="streetAddress" className="form-label align-self-end mt-2 mb-0 text-secondary greyLabelCls">Street Address&nbsp;</label>
                                <small id="streetAddress_msg" className="showmsg"></small>
                            </div>
                            {/* Street Address Line 2 */}
                            <div className="form-group col-md-12">
                                <div className="input-group">
                                    <input className="form-control" id="streetAddressLine2" name="streetAddressLine2" value={streetAddressLine2} onChange={(e) => setStreetAddressLine2(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <label htmlFor="streetAddressLine2" className="form-label align-self-end mt-2 mb-0 text-secondary greyLabelCls">Street Address Line 2&nbsp;</label>
                                <small id="setStreetAddressLine2_msg" className="showmsg"></small>
                            </div>
                            {/* City */}
                            <div className="form-group col-md-12">
                                <div className="input-group">
                                    {/* <input className="form-control" id="city" name="city" value={city} onChange={(e) => setCity(e.target.value)}/> */}
                                    {/* Get City here */}
                                    <select id="selectCityTown" className="form-select" aria-label="RoleList list DDl">
                                        <option hidden disabled value={location.state.staffInfo.city} selected>{city}</option>
                                        {
                                            cityTownList.map((cityTown, key) =>
                                                <option key={key} className="dropdown-item" value={cityTown.id}>{cityTown.name}</option>
                                            )
                                        }
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <label htmlFor="city" className="form-label align-self-end mt-2 mb-0 text-secondary greyLabelCls">City&nbsp;</label>
                                <small id="city_msg" className="showmsg"></small>
                            </div>
                            {/* Phone */}
                            <div className="form-group col-md-6">
                                <label className="form-label align-self-end">Phone Number&nbsp;</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="phone1" name="phone1" value={phone1} onChange={(e) => setphone1(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="phone1_msg" className="showmsg"></small>
                            </div>
                            {/* Email */}
                            <div className="form-group col-md-6">
                                <label className="form-label align-self-end">E-mail&nbsp;</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="email_msg" className="showmsg"></small>
                            </div>
                            {/* Emergency Contact Name*/}
                            <div className="form-group col-md-6">
                                <label className="form-label align-self-end">Emergency Contact Name&nbsp;</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="emergencyName" name="emergencyName" value={emergencyName} onChange={(e) => setEmergencyName(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="emergencyName_msg" className="showmsg"></small>
                            </div>
                            {/* Emergency Contact Phone Number*/}
                            <div className="form-group col-md-6">
                                <label className="form-label align-self-end">Emergency Contact Phone Number</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" id="emergencyContact" name="emergencyContact" value={emergencyContact} onChange={(e) => setEmergencyContact(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="emergencyContact_msg" className="showmsg"></small>
                            </div>
                            {/* Position Information */}
                            <h5 className="mt-4 fw-bold">Position Information</h5>
                            {/* Position Title*/}
                            <div className="form-group col-md-6">
                                <label className="form-label align-self-end">Position Title</label>
                                <div className="input-group">
                                    {/* <input type="text" className="form-control" id="positionTitle" name="positionTitle" value={positionTitle} onChange={(e) => setPositionTitle(e.target.value)}/> */}
                                    <select id="selectPostionTitle" className="form-select" aria-label="positionTitle DDL">
                                        <option hidden disabled value={location.state.staffInfo.position_title} selected>{position}</option>
                                        {
                                            postionList.map((position, key) =>
                                                <option key={key} className="dropdown-item" value={position.id}>{position.name}</option>
                                            )
                                        }
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="positionTitle_msg" className="showmsg"></small>
                            </div>
                            {/* Reports To*/}
                            <div className="form-group col-md-6">
                                <label className="form-label align-self-end">Reports To Department</label>
                                <div className="input-group">
                                    <select id="selectDepartment" className="form-select" aria-label="RoleList list DDl">
                                        <option hidden disabled selected>{department}</option>
                                        {
                                            departmentList.map((dep, key) =>
                                                <option key={key} className="dropdown-item" value={dep.id}>{dep.name}</option>
                                            )
                                        }

                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="reportsTo_msg" className="showmsg"></small>
                            </div>
                            {/* Employment Type*/}
                            <div className="form-group col-md-6">
                                <label className="form-label align-self-end">Employment Type</label>
                                <div className="input-group">
                                    {/* <input type="text" className="form-control" id="employmentType" name="employmentType" value={employmentType} onChange={(e) => setEmploymentType(e.target.value)}/> */}
                                    <select id="selectEmploymentType" className="form-select" aria-label="Employment Type DDL">
                                        <option hidden disabled value={location.state.staffInfo.employment_type} selected>{employmentType}</option>
                                        {
                                            employmentTypeList.map((employmentType, key) =>
                                                <option key={key} className="dropdown-item" value={employmentType.id}>{employmentType.name}</option>
                                            )
                                        }
                                    </select>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="employmentType_msg" className="showmsg"></small>
                            </div>
                            {/* Start Date */}
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="name" className="form-label align-self-end mt-2 ">Start Date&nbsp;</label>
                                    <div className="input-group">
                                        <input type="date" className="form-control" id="startDate" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                        </div>
                                    </div>
                                    <small id="startDate_msg" className="showmsg"></small>
                                </div>
                                {/* Contract End Date (if applicable) */}
                                <div className="form-group col-md-6">
                                    <label htmlFor="name" className="form-label align-self-end mt-2">Contract End Date (if applicable)</label>
                                    <div className="input-group">
                                        <input type="date" className="form-control" id="contractEndDate" name="contractEndDate" value={contractEndDate} onChange={(e) => setContractEndDate(e.target.value)} />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                        </div>
                                    </div>
                                    <small id="contractEndDate_msg" className="showmsg"></small>
                                </div>
                            </div>
                            {/* Staff => Acitve/ Inactive */}
                            {/* <div className="col-6">
                                <div className="form-group d-flex px-0">
                                    <label htmlFor="isactive" className="display_block">Active*&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="isactive" id="isactive_yes" value="1" onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="isactive_yes">
                                            Yes
                                        </label>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="isactive" id="isactive_no" value="0" onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="isactive_no">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            {/* Edit - Update - Cancel */}
                            <div className="row mt-3">
                                <div className="col-3 editstaffDetailsFor">
                                    <button type="submit" className="btn btn-primary my-2 col-12" id="" onClick={editStaffDetailsForm}>Edit</button>
                                </div>
                                <div className="col-3 updateStaffDetailsForm">
                                    <button type="submit" className="btn btn-warning my-2 col-12" id="" onClick={updateStaffDetailsForm}>Update</button>
                                </div>
                                <div className="col-3 cancelStaffDetailsForm">
                                    <button type="submit" className="btn btn-secondary my-2 col-12" id="" onClick={closeStaffDetailsForm}><FontAwesomeIcon title="Go Back" className="text-light" icon={faArrowAltCircleLeft} />&nbsp; Go Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Staff_Inside_Page;