import React from "react";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import Navbar from './../components/Navbar';
import $ from 'jquery';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPencil, faArrowRight, faCheck, faPlus, faAsterisk } from '@fortawesome/free-solid-svg-icons';



function TS() {
    const [userDetails, setUserDetails] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [userID, SetUserID] = useState('');
    const [chck, setchck] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [leaveType, setLeavetype] = useState([]);
    const [startTime, setStartTime] = useState('08:00');
    const [endTime, setEndTime] = useState('17:00');
    // let [weeklyTSDate, setWeeklyTSDate] = useState(false);
    let [addLeaveList, setAddLeaveList] = useState([]);
    let [start_T, setStart_T] = useState(null);
    let [end_T, setEnd_T] = useState(null);
    const [items, setItems] = useState([]);
    let [boolAbsent, setBoolAbsent] = useState(false);

    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);

    let addLeaveArray = [];
    var bool_present = false;
    let [allLeaveList, settAllLeaveList] = useState([]);
    let getAddDataLeaveArray = [];

    let tempLeaveArray = [];

    let TestFun = () => {


    }

    //Adds leave to an array
    const handleEditSave = (e) => {
        $('.showmsg').addClass("d-none");
        var error = new Array();
        start_T = $("#addLeaveStartTime_" + userID).val();
        end_T = $("#addLeaveEndTime_" + userID).val();
        var leaveID = $('select#leaveTypeDDL_' + userID + ' ' + 'option:selected').val();

        if (start_T == '') {
            $('#startTime_msg').html('');
            $('#addLeaveStartTime_' + userID).addClass('invalid');
            $('#startTime_msg').html('Please enter start time');
            $('#startTime_msg').removeClass("d-none");
            error.push('true');
        }
        if (end_T == '') {
            $('#endTime_msg').html('');
            $('#addLeaveEndTime_' + userID).addClass('invalid');
            $('#endTime_msg').html('Please enter end time');
            $('#endTime_msg').removeClass("d-none");
            error.push('true');
        }
        if (leaveID == '-1') {
            $('#leaveTypeDDL_msg').html('');
            $('#leaveTypeDDL_' + userID).addClass('invalid');
            $('#leaveTypeDDL_msg').html('Please select leave type');
            $('#leaveTypeDDL_msg').removeClass("d-none");
            error.push('true');
        }

        var exist = !$.inArray("true", error);
        if (exist == false) {
            //check if leave exists in all leave array
            //if exixsts then refill form
            setIsEditModalVisible(false);
            // start_T = $("#addLeaveStartTime_" + userID).val();
            // end_T = $("#addLeaveEndTime_" + userID).val();
            var leaveTypeID = $('select#leaveTypeDDL_' + userID + ' option:selected').val();
            var bool_fullday = ($("#fullDay_" + userID).prop('checked'));
            var rowID = $('#userId_' + userID).attr('data-row-id');
            if (rowID !== undefined) { rowID = rowID; } else { rowID = ''; }
            //Comments
            var comments = $("#comments_" + userID).val();
            // console.log(bool_fullday, "----bool_fullday-----");

            let newItem = {
                rowID,
                userID,
                start_T,
                end_T,
                leaveTypeID,
                bool_present,// if present or absenet radio is selected
                bool_fullday,// if fullday is checked or not
                comments
            }

            if (newItem !== '') {
                // Update the list of items with the new item
                setItems(prevItems => [...prevItems, newItem]);
                setAddLeaveList((addLeaveList) => [...addLeaveList, newItem]);
                // Clear the current item input
                // setItems('');   
                addLeaveArray.push(newItem);
                tempLeaveArray = addLeaveArray;
                // localStorage.setItem("leaveTempData", JSON.stringify(addLeaveArray));
            }
            start_T = "";
            end_T = "";
        }
        return false;
    };

    const handleRadioChange = (userid, e) => {
        SetUserID(userid);
    };

    let checkPresentAbsent = (e, item) => {
        if ((e.target.value) == ('absent_' + item.userID)) {

            setBoolAbsent(true);

            let $button = $('<button>', {
                text: 'Add Leave' + item.userID,
                click: (e) => handleAddLeave(e, item.userID),
                class: 'btn btn-primary p-0 indiAddLeave',
                id: item.userID,
            });

            $(".leaveDiv_" + item.userID).html("");
            $(".timeInOutIndexVal_" + item.userID).css("display", "none");
            $(".leaveDiv_" + item.userID).append($button);
            $(".leaveDiv_" + item.userID).css("display", "block");

            // Empty values assigned to T/in, Out, Adj
            $("#timeIn_" + item.userID).val(null);
            $("#timeOut_" + item.userID).val(null);
            $("#timeAdj_" + item.userID).val(null);

        } else {
            setBoolAbsent(false);

            // SetUserID(item.userID);
            $(".leaveDiv_" + item.userID).css("display", "none");
            $(".addLeaveIndexVal_" + item.userID).css("display", "none");
            $(".timeInOutIndexVal_" + item.userID).css("display", "flex");

            //update addleave list
            removeItem(item.userID);
        };
    }

    const removeItem = (idToRemove) => {
        // console.log(addLeaveList, "------------addLeaveList------");
        const newArray = [];
        for (let i = 0; i < addLeaveList.length; i++) {
            if (idToRemove !== addLeaveList[i].userID) {
                newArray.push(addLeaveList[i]);
                // console.log(newArray, "--newArray--")
            }
        }
        setAddLeaveList(newArray);
    };

    const setAddLeaveTime = (e) => {

    }

    const handleChangeAddLeaveCheckBox = (e) => {
        setchck(e.target.checked);
        if ((chck)) {
            $("#addLeaveStartTime_" + userID).val(null);
            $("#addLeaveEndTime_" + userID).val(null);
        } else {
            $("#addLeaveStartTime_" + userID).val(startTime);
            $("#addLeaveEndTime_" + userID).val(endTime);
        }
    }

    useEffect(() => {
        const fetchata = async () => {
            // let user_str = localStorage.getItem("HrApiData");
            // let user = JSON.parse(user_str);

            axios({
                method: "GET",
                url: "timesheet2/getTimeSheetDetails",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(async result => {
                    // SetpermisionListUX(result.data.permissionList[0]);
                    if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'No records Found',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                    else if (result.data.code == 99) {
                        //Hide Add Staff Btn & Data table Header Fields
                        // //Show A Div with Message - User Not Permitted
                        // $(".accessDeniedMsg").css("display", "block");
                        // $("#btn_addStaff").css("display", "none");
                        // $(".staffProfileDiv").css("display", "none");
                        // $(".accessDeniedMsg h1").html("User Not Permitted");

                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 2500,
                        })
                    }
                    else {
                        setUserDetails(result.data.stafflist);
                        setLeavetype(result.data.leaveTypeList[0]);

                        setTimeout(() => {
                            for (let i = 0; i < result.data.stafflist.length; i++) {
                                $("#present_" + result.data.stafflist[i].userID).attr('checked', true);
                            }
                        }, 0);
                    }

                    // perArray = result.data.permissionList[0];
                    // if (perArray.includes("canaddstaff")) {
                    //     $("#btn_addStaff").addClass("d-block");
                    // }


                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });

        }
        // Call the function
        fetchata();
    }, []);

    //gets TS data on click datafield
    let getWeeklyTSDate = e => {
        //clear form function
        // clearform();

        if (userDetails.length > 0) {
            for (let i = 0; i < userDetails.length; i++) {
                //Calendar Date
                // $("#weeklyTSDate").val(''); // do not empty calendar date 
                //Presnt/ Absent column
                // $("#present_" + userDetails[i].userID).prop('checked', false);
                // $("#absent_" + userDetails[i].userID).prop('checked', false);
                //TIn OUt ADJ
                $("#timeIn_" + userDetails[i].userID).val('');
                $("#timeOut_" + userDetails[i].userID).val('');
                $("#timeAdj_" + userDetails[i].userID).val('');
                //Comments
                $("#comments_" + userDetails[i].userID).val('');
                //error message
                $('.showmsg').addClass("d-none");
                //hide leave button show time In/Out details
                $(".leaveDiv_" + userDetails[i].userID).css("display", "none");
                $(".timeInOutIndexVal_" + userDetails[i].userID).css("display", "flex");
            }
        }

        //call time out
        setTimeout(
            function () {
                //call API
                const entryTSDate = $("#weeklyTSDate").val();
                axios({
                    method: "POST",
                    url: "timesheet2/getWeeklyTSDate",
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                    data: { entryTSDate: entryTSDate },
                })
                    .then(async result => {
                        if (result.data.code == 1) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Previous record found',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                            var resultData = result.data.allLeaveList;
                            getAddDataLeaveArray = resultData;
                            console.log("--resultData--", resultData);
                            for (var i = 0; i < resultData.length; i++) {
                                var userID = resultData[i].user_id;
                                var rowID = resultData[i].rowID;
                                //updates userID and rowID to each row data for Name Column
                                $('#userId_' + userID).attr('data-row-id', resultData[i].rowID);
                                //updates rowID to each row data for Present Absent Column
                                $('#userIsPresnt_' + userID).attr('data-row-id', resultData[i].rowID);
                                //updates Radio button with reseptive record data
                                if (resultData[i].isPresent == true) {
                                    $("#present_" + userID).prop('checked', true);
                                } else {
                                    $("#absent_" + userID).prop('checked', true);
                                    //append add leave button

                                    // setBoolAbsent(true);
                                    let $button = $('<button>', {
                                        text: 'Add Leave' + userID,
                                        click: (e) =>  handleAddLeave(e, userID),
                                        class: 'btn btn-primary p-0 indiAddLeave',
                                        id: userID,
                                    });

                                    $(".leaveDiv_" + userID).html("");
                                    $(".timeInOutIndexVal_" + userID).css("display", "none");
                                    $(".leaveDiv_" + userID).append($button);
                                    $(".leaveDiv_" + userID).css("display", "block");
                                    //set row id
                                    $('.leaveDiv_' + userID + " .indiAddLeave").attr('data-row-id', resultData[i].rowID);

                                    // Empty values assigned to T/in, Out, Adj
                                    $("#timeIn_" + userID).val(null);
                                    $("#timeOut_" + userID).val(null);
                                    $("#timeAdj_" + userID).val(null);

                                }
                                //updates timein/out/Adj for each rowID and data for timein/out/Adj Column
                                $('#userTimeInout_' + userID).attr('data-row-id', resultData[i].rowID);
                                //Time In data append
                                if (resultData[i].timeIn !== null || resultData[i].timeIn !== undefined || resultData[i].timeIn !== "") {
                                    $("#timeIn_" + userID).val(resultData[i].timeIn);
                                }
                                //Time Out data append
                                if (resultData[i].timeOut !== null || resultData[i].timeOut !== undefined || resultData[i].timeOut !== "") {
                                    $("#timeOut_" + userID).val(resultData[i].timeOut);
                                }
                                //Time Out data append
                                if (resultData[i].timeAdj !== null || resultData[i].timeAdj !== undefined || resultData[i].timeAdj !== "") {
                                    $("#timeAdj_" + userID).val(resultData[i].timeAdj);
                                }
                                //updates user comments, rowID and data for Comments column
                                $('#userComments_' + userID).attr('data-row-id', resultData[i].rowID);
                                //Comments data append
                                if (resultData[i].comments !== null || resultData[i].comments !== undefined || resultData[i].comments !== "") {
                                    $("#comments_" + userID).val(resultData[i].comments);
                                }
                            }
                        }
                        else if (result.data.code == 2) {
                            if (userDetails.length > 0) {
                                for (let i = 0; i < userDetails.length; i++) {
                                    //Presnt/ Absent column
                                    $("#present_" + userDetails[i].userID).prop('checked', true);
                                    $("#absent_" + userDetails[i].userID).prop('checked', false);
                                }
                            }

                            Swal.fire({
                                position: 'top-end',
                                icon: 'warning',
                                title: 'No records Found',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                        } else if (result.data.code == 0) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'warning',
                                title: 'An error occured. Please contact Admin!',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                        } else if (result.data.code == 99) {
                            //Hide Add Staff Btn & Data table Header Fields
                            // //Show A Div with Message - User Not Permitted
                            // $(".accessDeniedMsg").css("display", "block");
                            // $("#btn_addStaff").css("display", "none");
                            // $(".staffProfileDiv").css("display", "none");
                            // $(".accessDeniedMsg h1").html("User Not Permitted");

                            // Swal.fire({
                            //     position: 'top-end',
                            //     icon: 'warning',
                            //     title: 'Access Denied!',
                            //     text: 'User does not have permission to access this page.',
                            //     showConfirmButton: false,
                            //     timer: 2500,
                            // })
                        }
                        // perArray = result.data.permissionList[0];
                        // if (perArray.includes("canaddstaff")) {
                        //     $("#btn_addStaff").addClass("d-block");
                        // }
                    })
                    .catch(err => {
                        // Swal.fire({
                        //     position: 'top-end',
                        //     icon: 'warning',
                        //     title: 'An error occured. Please contact Admin!',
                        //     showConfirmButton: false,
                        //     timer: 1500,
                        // })
                    });
                //if data exits
                //else show blank form
            }, 0);
    }

    // saveDetails to database
    let saveDetails = e => {
        e.preventDefault();
        const timeInOutAdjComList = [];
        const entryTSDate = $("#weeklyTSDate").val();

        //valdation
        $('.showmsg').addClass("d-none");
        var error = new Array();
        var maskAlpha = /^[a-zA-Z ]+$/;
        var mask5 = /\S+@\S+\.\S+/;

        if (entryTSDate == '') {
            $('#weeklyTSDate_msg').html('');
            $('#weeklyTSDate').addClass('invalid');
            $('#weeklyTSDate_msg').html('Please select date');
            $('#weeklyTSDate_msg').removeClass("d-none");
            error.push('true');
        }
        else {
            $('#weeklyTSDate').removeClass('invalid');
        }
        if (userDetails.length > 0) {
            for (let i = 0; i < userDetails.length; i++) {
                {
                    var checkPresent = ($('input[data-id="present' + userDetails[i].userID + '"]').is(':checked'))
                    var checkAbsent = ($('input[data-id="absent' + userDetails[i].userID + '"]').is(':checked'))
                    //Present Absent Radio Validation
                    if (checkPresent === false && checkAbsent === false) {
                        $('#presntAbsentRadio_msg' + userDetails[i].userID).html('');
                        $('#userIsPresnt_' + userDetails[i].userID).addClass('invalid');
                        $('#presntAbsentRadio_msg' + userDetails[i].userID).html('Please select an option');
                        $('#presntAbsentRadio_msg' + userDetails[i].userID).removeClass("d-none");
                        error.push('true');
                    }
                    // Validate Time In Out Fields
                    var checkTimeIn = $("#timeIn_" + userDetails[i].userID).val();
                    var checkTimeOut = $("#timeOut_" + userDetails[i].userID).val();
                    if (checkTimeIn == '' || checkTimeOut == '') {
                        // timeInOut_msg
                        $('#timeInOut_msg' + userDetails[i].userID).html('');
                        $('#timeInOut_msg' + userDetails[i].userID).html('Please enter time details');
                        $('#timeInOut_msg' + userDetails[i].userID).removeClass("d-none");
                        // if (checkTimeIn == '') {$('#timeIn_' + userDetails[i].userID).addClass('invalid');}
                        // else {$('#timeIn_' + userDetails[i].userID).removeClass('invalid');}
                        // if (checkTimeOut == '') { $('#timeOut_' + userDetails[i].userID).addClass('invalid'); }
                        // else {$('#timeOut_' + userDetails[i].userID).removeClass('invalid'); }
                        error.push('true');
                    }
                    if (checkAbsent === true) {
                        $('#timeInOut_msg' + userDetails[i].userID).html('');
                        var checkLeave = addLeaveList.filter(item => item.userID === parseInt(userDetails[i].userID));
                        if (checkLeave.length == 0) {
                            $('#timeInOut_msg' + userDetails[i].userID).html('Please fill leave form details');
                        }
                    }
                }
            }
        }

        var exist = !$.inArray("true", error);
        if (exist == false) {

            for (let i = 0; i < userDetails.length; i++) {
                //user ID
                var user_ID = userDetails[i].userID;
                //Row ID
                var rowID = $('#userId_' + user_ID).attr('data-row-id');
                if (rowID !== undefined) { rowID = rowID; } else { rowID = ''; }
                //TimeIn - TimeOut - Time Adj
                var timeIn = $("#timeIn_" + userDetails[i].userID).val();
                var timeOut = $("#timeOut_" + userDetails[i].userID).val();
                var timeAdj = $("#timeAdj_" + userDetails[i].userID).val();
                //Comments
                var comments = $("#comments_" + userDetails[i].userID).val();
                bool_present = true;

                let timeInOutAdjComData = {
                    rowID,
                    user_ID,
                    timeIn,
                    timeOut,
                    timeAdj,
                    comments,
                    bool_present
                }
                timeInOutAdjComList.push(timeInOutAdjComData);
            }
            bool_present = false;

            //get data using jquery
            // var error = new Array();
            // var maskAlpha = /^[a-zA-Z ]+$/;
            // var mask5 = /\S+@\S+\.\S+/;
            // var exist = !$.inArray("true", error);
            // if (exist == false) {

            axios({
                method: "POST",
                url: "timesheet2/addTimeSheet1",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {
                    entryTSDate: entryTSDate,
                    addLeaveList: addLeaveList,
                    timeInOutAdjComList: timeInOutAdjComList,
                    //check if 8.00am - 5pm is still being shown in the list in the db? 
                },
            })
                .then(async result => {
                    if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: result.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    } else if (result.data.code == 1) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Timesheet added successfully",
                            showConfirmButton: false,
                            timer: 1500,
                        })
                        //navigate to prev page
                        //navigate("/staff")
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });

        }
        return false;
    }

    // Opens Add Leave Form in a Modal
    let handleAddLeave = (e, id) => {
        e.preventDefault();
        //create a callback function

        //get prev records from varibale
        var userId_PreRec = e.target.id;
        var rowId_PreRec = ($(".leaveDiv_" + userId_PreRec + " .indiAddLeave").attr("data-row-id"));;

        if (rowId_PreRec !== undefined) {
            // appen data into model
            setIsEditModalVisible(true);
            SetUserID(userId_PreRec);
            //filter loop with matching id and append corresponding data
            var getIDMatchRecord = getAddDataLeaveArray.filter(item => item.user_id === parseInt(userId_PreRec));
            var matchId = parseInt(userId_PreRec);
            //append data after model is open
            setTimeout(
                function () {
                    $('#addLeaveStartTime_' + matchId).val(getIDMatchRecord[0].start_T);
                    $('#addLeaveEndTime_' + matchId).val(getIDMatchRecord[0].end_T);
                    $("#leaveTypeDDL_" + matchId).val(getIDMatchRecord[0].leaveTypeID);
                    $('#saveDetailsBtn').attr('data-userid', userId_PreRec);
                    $('#saveDetailsBtn').attr('data-row-id', rowId_PreRec);
                    if (getIDMatchRecord[0].bool_fullday == true) {
                        $("#fullDay_" + matchId).prop("checked", true);
                    } else {
                        $("#fullDay_" + matchId).prop("checked", false);
                    }
                }, 0);
        } else {
            //new entry
            setIsEditModalVisible(true);
            $('#saveDetailsBtn').attr('data-userid', id);
            SetUserID(id);

            // let leaveTempDataJson = localStorage.getItem("leaveTempData");
            // let leaveTempData = JSON.parse(leaveTempDataJson);
            // var checkIfEntryExists = leaveTempData.filter(item => item.userID === parseInt(id));

            var checkIfEntryExists = tempLeaveArray.filter(item => item.userID === parseInt(id));

            //append data after model is open
            if (checkIfEntryExists.length > 0) {
                setTimeout(
                    function () {
                        $('#addLeaveStartTime_' + id).val(checkIfEntryExists[0].start_T);
                        $('#addLeaveEndTime_' + id).val(checkIfEntryExists[0].end_T);
                        $("#leaveTypeDDL_" + id).val(checkIfEntryExists[0].leaveTypeID);
                        // $('#saveDetailsBtn').attr('data-userid', userId_PreRec);
                        // $('#saveDetailsBtn').attr('data-row-id', rowId_PreRec);
                        if (checkIfEntryExists[0].bool_fullday == true) {
                            $("#fullDay_" + id).prop("checked", true);
                        } else {
                            $("#fullDay_" + id).prop("checked", false);
                        }
                    }, 0);
            }

        }
    };

    const formatInput = (id) => {
        // let twodecimal = parseFloat($("#timeAdj_" + id).val()).toFixed(2);
        // if (twodecimal === NaN || twodecimal === "NaN") {
        //     this.single_unit_market_rent = 0;
        //     $("#timeAdj_" + id).val(0);
        // }
        // else {
        //     $("#timeAdj_" + id).val(twodecimal);
        // }
    }

    let newEntry = (e) => {
        e.preventDefault();
        clearform();
    }

    let clearform = () => {
        // e.preventDefault();
        //Clear values rom Tine In - Out - Adj fields
        if (userDetails.length > 0) {
            for (let i = 0; i < userDetails.length; i++) {
                //Calendar Date

                $("#weeklyTSDate").val('');
                //Presnt/ Absent column
                $("#present_" + userDetails[i].userID).prop('checked', true);
                $("#absent_" + userDetails[i].userID).prop('checked', false);
                //TIn OUt ADJ
                $("#timeIn_" + userDetails[i].userID).val('');
                $("#timeOut_" + userDetails[i].userID).val('');
                $("#timeAdj_" + userDetails[i].userID).val('');
                //Comments
                $("#comments_" + userDetails[i].userID).val('');
                //error message
                $('.showmsg').addClass("d-none");
                //hide leave button show time In/Out details
                $(".leaveDiv_" + userDetails[i].userID).css("display", "none");
                $(".timeInOutIndexVal_" + userDetails[i].userID).css("display", "flex");
            }
        }
    }

    return (
        <div className="tsDiv px-3">
            <Navbar />
            {/* Form Div */}
            <div>
                <form className="">
                    <div className="row border">
                        <div className="col-12">
                            <input type="date" id="weeklyTSDate" onChange={(e) => getWeeklyTSDate(e.target.value)} ></input>
                            <div className="py-2">
                                <small id="weeklyTSDate_msg" className="showmsg"></small>
                            </div>
                        </div>

                        {/* Header */}
                        <div className="row">
                            <div className="col-3 py-2 border">
                                <label>Name</label>
                            </div>
                            <div className="col-2 py-2 border">
                                <label>Present/Absent</label>
                            </div>
                            <div className="col-4 py-2 border text-center">
                                {/* <label>Time In/Out</label> */}
                                <div className="row border">
                                    <div className="col-4 border">T/IN</div>
                                    <div className="col-4 border">T/OUT</div>
                                    <div className="col-4 border">ADJUST</div>
                                </div>
                            </div>
                            <div className="col-3 py-2 border text-center">
                                <label>Comments</label>
                            </div>
                        </div>

                        {/* Details */}
                        <div className="border row">
                            {/* Staff Name */}
                            <div className="border col-3" id="userName">
                                {userDetails.map((item, index) => (
                                    <div id={"userId_" + item.userID} className="border py-2" data-userid={item.userID} key={index}>{item.first_name} {item.last_name}</div>
                                ))}
                            </div>
                            {/* Radio Button */}
                            <div className="border col-2 text-center">
                                {userDetails.map((item, index) => (
                                    <div id={"userIsPresnt_" + item.userID} className="border py-2" data-userid={item.userID} key={index}>
                                        <label >
                                            <input
                                                id={'present_' + item.userID}
                                                type="radio"
                                                name={"radioGroup" + item.userID}
                                                value={'present_' + item.userID}
                                                data-id={'present' + item.userID}
                                                // checked="true"
                                                data-index={index}
                                                // defaultChecked
                                                // checked={selectedRow === index}
                                                // onChange={() => { handleRadioChange(index); setSelectedRow(index) }}
                                                onChange={() => { handleRadioChange(item.userID); setSelectedRow(item.userID) }}
                                                onClick={(e) => checkPresentAbsent(e, item)}
                                            />
                                            &nbsp;
                                            Present
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <label >
                                            <input
                                                id={'absent_' + item.userID}
                                                type="radio"
                                                name={"radioGroup" + item.userID}
                                                value={'absent_' + item.userID}
                                                data-id={'absent' + item.userID}
                                                data-index={index}
                                                // checked={selectedRow === index}
                                                // onChange={() => { handleRadioChange(index); setSelectedRow(index) }}
                                                onChange={() => { handleRadioChange(item.userID); setSelectedRow(item.userID) }}
                                                onClick={(e) => checkPresentAbsent(e, item)}
                                            />
                                            &nbsp;
                                            Absent
                                        </label>
                                        <br></br>
                                        <small id={"presntAbsentRadio_msg" + item.userID} className="showmsg"></small>
                                    </div>
                                ))}
                            </div>

                            {/* T/In - T/OUT - ADJUST */}
                            <div className={"border col-4 text-center align-self-center timeInOutDiv"}>
                                {userDetails.map((item, index) => (
                                    <div className="mainDiv border py-2" data-userid={item.userID}>
                                        <div className={"row timeInOutIndexVal_" + item.userID} id={"userTimeInout_" + item.userID}>
                                            <div className="col-4">
                                                <input
                                                    className="w-100 text-center"
                                                    type="time"
                                                    id={"timeIn_" + item.userID}
                                                />
                                            </div>
                                            <div className="col-4">

                                                <input
                                                    className="w-100 text-center"
                                                    type="time"
                                                    id={"timeOut_" + item.userID}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    className="w-100 text-center"
                                                    type="number"
                                                    // step="0.01"
                                                    id={"timeAdj_" + item.userID}
                                                    data-userid={item.userID}
                                                    onChange={() => { formatInput(item.userID); }}
                                                    placeholder="00.00"
                                                />
                                            </div>
                                        </div>
                                        <div className={"leaveDiv_" + item.userID} ></div>
                                        <small id={"timeInOut_msg" + item.userID} className="showmsg"></small>
                                    </div>
                                ))}

                            </div>

                            {/*Comments */}
                            <div className="border col-3 text-center align-self-center">
                                {userDetails.map((item, index) => (
                                    <div className="row py-2 border" data-userid={item.userID}>
                                        <div className="col" id={"userComments_" + item.userID}>
                                            <input
                                                className="w-100"
                                                type="text"
                                                placeholder="Comments here"
                                                id={"comments_" + item.userID}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    {/* Add Leave */}
                    <div className={" col-12 text-center align-self-center mt-5"}>
                        <div className="row ">
                            <div className="col-6 text-end">
                                <button className="btn btn-success" onClick={newEntry}>New Entry</button>
                            </div>
                            <div className="col-6 text-start">
                                <button className="btn btn-primary" onClick={saveDetails}>Save Details</button>
                            </div>
                        </div>
                    </div>

                    <br></br>


                </form>

                {/* Modal start */}
                <Modal
                    show={isEditModalVisible}
                    onHide={() => setIsEditModalVisible(false)}
                    id="LeaveModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="modalTitle">Add Leave Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="input-group">
                                <select id={"leaveTypeDDL_" + userID} className="form-select" aria-label="Gender list DDl">
                                    <option hidden value="-1" selected>Select Leave Type:</option>
                                    {
                                        leaveType.map((list, key) =>
                                            <option key={key} className="dropdown-item" id={"leavetype_" + list.id} value={list.id}>{list.name}</option>
                                        )
                                    }
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="leaveTypeDDL_msg" className="showmsg"></small>
                            <br></br>
                            <div>
                                Enter Leave duration
                                <br></br>
                                <br></br>
                                <div className="row">
                                    <div className="col-6">
                                        <input
                                            id={"addLeaveStartTime_" + userID}
                                            className="w-100 text-center addLeaveStartTime_"
                                            type="time"

                                        />
                                        <small id="startTime_msg" className="showmsg"></small>
                                    </div>
                                    <div className="col-6">

                                        <input
                                            id={"addLeaveEndTime_" + userID}
                                            className="w-100 text-center addLeaveEndTime_"
                                            type="time"
                                        />
                                        <small id="endTime_msg" className="showmsg"></small>
                                    </div>
                                </div>
                                <br></br>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={"fullDay_" + userID}
                                        onChange={handleChangeAddLeaveCheckBox}
                                    >
                                    </input>
                                    <label class="form-check-label" for={"fullDay_" + userID}>
                                        Full Day
                                    </label>
                                </div>
                                <br></br>
                                {/* <button
                                        id="addLeave"
                                    >Add Leave</button> */}

                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" id="saveDetailsBtn" value={userID} onClick={(e) => handleEditSave()}>
                            Add Leave
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* modal end */}

            </div>
        </div>
    );
}

export default TS;