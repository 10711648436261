import React, { useState, useEffect } from "react";
import "../styles/Main.css";
import axios, { Axios } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Swal from "sweetalert2";
import $ from "jquery";

<script src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></script>;

function Login() {
  const navigate = useNavigate();
  const Swal = require("sweetalert2");
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");

  let user_str = localStorage.getItem("HrApiData");
  let user = JSON.parse;

  useEffect(() => {
    // let user_str = localStorage.getItem("HrApiData");
    // let user = JSON.parse(user_str);

    // console.log(user_str, 'login-user track');

    const fetchata = async () => {
      $(".App").removeClass("bg_white_cls");
      $(".App").addClass("bg_blue_cls");
    };
    // Call the function
    fetchata();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleEmail = (e) => {
    e.preventDefault();
  };

  const handlePassword = (e) => {
    e.preventDefault();
  };

  const handleApi = (e) => {
    e.preventDefault();
    // axios.post('users/authenticate', {
    // email: email,
    // password: password
    // })

    axios({
      method: "POST",
      url: "users/authenticate",
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      data: {
        email: email,
        password: password,
      },
    })
      .then((response) => {
        if (response == null || response == {}) {
          alert("Email or Password is incrorrect.");
        } else {
          let result = response;
          if (result.data.emailconfirmed) {
            localStorage.setItem("HrApiData", JSON.stringify(result.data));

            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: "Login Successful",
            });
            navigate("/home");

            $(".App").addClass("bg_white_cls");
            $(".App").removeClass("bg_blue_cls");
            let user_str = localStorage.getItem("HrApiData");
          } else {
            alert("Email or Password is incrorrect.");
          }
        }
      })
      .catch((err) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Login Unuccessful",
          text: "Invalid username or passowrd! Please try again",
        });
      });
  };

  return (
    <div className="auth-form-container">
      <div className="background">
        <div className="shape"></div>
        <div className="shape"></div>
      </div>
      <form onSubmit={handleSubmit} className="login_form">
        <h3>HR APP</h3>
        <label htmlFor="username">Username</label>
        <input
          type="email"
          placeholder=""
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          placeholder="Password"
          id="password"
          value={password}
          onChange={(e) => setPass(e.target.value)}
        ></input>

        <button className="margintop" onClick={handleApi}>
          Log In
        </button>
      </form>
    </div>
  );
}

export default Login;
