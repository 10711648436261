import React from "react";
import "../styles/TeamProfile.css";
import { Link } from "react-router-dom";
import Navbar from './../components/Navbar';
import "../styles/Main.css";
import axios from "axios";
import { faLink, faPencil, faArrowRight, faCheck, faPlus, faCircleCheck, faCircleXmark, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import $ from 'jquery';
import Swal from 'sweetalert2';
import staffImg from '../assets/img/OceanicLogo.png';
import dayjs from "dayjs";
import { Circle, Start } from "@mui/icons-material";
import styled from "styled-components";


function Staff() {
  const [userDetails, setUserDetails] = useState([]);

  let [NonActiveLookupsDetails, setNonActiveLookupsDetails] = useState([]);
  let [nonActive, SetnonActive] = useState(false);
  let [lookupsDetails, setlookupsDetails] = useState([]);
  let [permisionListUX, SetpermisionListUX] = useState([]);
  var perArray;

  useEffect(() => {
    const fetchata = async () => {
      let user_str = localStorage.getItem("HrApiData");
      let user = JSON.parse(user_str);

      axios({
        method: "GET",
        url: "staffmanagement/getAllActiveStaffs",
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: {},
      })
        .then(async result => {
          SetpermisionListUX(result.data.permissionList[0]);
          if (result.data.code == 0) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'No records Found',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          else if (result.data.code == 99) {
            //Hide Add Staff Btn & Data table Header Fields
            //Show A Div with Message - User Not Permitted
            $(".accessDeniedMsg").css("display", "block");
            $("#btn_addStaff").css("display", "none");
            $(".staffProfileDiv").css("display", "none");
            $(".accessDeniedMsg h1").html("User Not Permitted");

            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Access Denied!',
              text: 'User does not have permission to access this page.',
              showConfirmButton: false,
              timer: 2500,
            })
          }
          else {
            setUserDetails(result.data.stafflist);
            $("#active_roles_tbl").DataTable().destroy();
            setTimeout(() => {
              $("#active_roles_tbl").DataTable({
                lengthMenu: [
                  [5, 10, 25, 50, -1],
                  [5, 10, 25, 50, "All"],
                ],
                pageLength: 10,
              });
            });
          }

          perArray = result.data.permissionList[0];
          // // //can add staff
          if (perArray.includes("canaddstaff")) {
            $("#btn_addStaff").addClass("d-block");
          }
          // if(!perArray.includes("caneditstaff")){
          //   $(".actionEdit").css("display", "none");
          // } 

        })
        .catch(err => {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'An error occured. Please contact Admin!',
            showConfirmButton: false,
            timer: 1500,
          })
        });
    }
    // Call the function
    fetchata();
  }, []);

  // Inactive Staffs
  function checkednonchildActive(e) {
    if (!nonActive) {
      let user_str = localStorage.getItem("HrApiData");
      let user = JSON.parse(user_str);
      axios({
        method: "GET",
        url: "staffmanagement/getAllNonActiveStaffs",
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: {},
      })
        .then(result => {
          SetpermisionListUX(result.data.permissionList[0]);
          if (result.data.code == 99) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Access Denied!',
              text: 'User does not have permission to access inactive staffs.',
              showConfirmButton: false,
              timer: 2500,
            })
          } else if (result.data.code == 1) {
            $(".noneActiveDataTableDiv").css('display', 'block');
            $("#nonactive_lookups_parent_tbl").DataTable().destroy();
            setNonActiveLookupsDetails(result.data.stafflist);
            setTimeout(() => {
              $("#nonactive_lookups_parent_tbl").DataTable({
                lengthMenu: [
                  [5, 10, 25, 50, -1],
                  [5, 10, 25, 50, "All"],
                ],
                pageLength: 10,
              });
            });
          }
        })
        .catch(err => {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: "An error occured. Please contact Admin!",
            showConfirmButton: false,
            timer: 1500,
          })
        });
      SetnonActive(true);
    } else {
      SetnonActive(false);
      $(".noneActiveDataTableDiv").css('display', 'none');
    }
  }

  function activateOnClickCheck(e, staff) {
    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);
    axios({
      method: "POST",
      url: "staffmanagement/activateDeactivateStaff",
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
      data: {
        id: staff.id,
      },
    })
      .then(result => {
        if (result.data.code == 99) {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'Access Denied!',
            text: 'User does not have permission to access this page.',
            showConfirmButton: false,
            timer: 1500,
          })
        } else if (result.data.code == 1) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: "Staff activated successfully!",
            showConfirmButton: false,
            timer: 1500,
          })
          // Start - Recall API to get Active users and update staff profile UI Lists
          axios({
            method: "GET",
            url: "staffmanagement/getAllActiveStaffs",
            headers: {
              Authorization: `Bearer ${user.token}`,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0',
            },
            data: {},
          })
            .then(async result => {
              if (result.data.code == 0) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'warning',
                  title: 'An error occured. Please contact Admin',
                  showConfirmButton: false,
                  timer: 1500,
                })
              }
              setUserDetails(result.data.stafflist);
            })
            .catch(err => {
              Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'An error occured. Please contact Admin!',
                showConfirmButton: false,
                timer: 1500,
              })
            });
          // End - Recall API to get Active users and update staff profile UI Lists
          //Start- Recall Non-Active Staffs List
          if (nonActive) {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
              method: "GET",
              url: "staffmanagement/getAllNonActiveStaffs",
              headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
              },
              data: {},
            })
              .then(result => {
                if (result.data.code == 99) {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                } else if (result.data.code == 1) {

                  $(".noneActiveDataTableDiv").css('display', 'block');
                  $("#nonactive_lookups_parent_tbl").DataTable().destroy();
                  setNonActiveLookupsDetails(result.data.stafflist);
                  setTimeout(() => {
                    $("#nonactive_lookups_parent_tbl").DataTable({
                      lengthMenu: [
                        [5, 10, 25, 50, -1],
                        [5, 10, 25, 50, "All"],
                      ],
                      pageLength: 10,
                    });
                  });
                }
              })
              .catch(err => {
                Swal.fire({
                  position: 'top-end',
                  icon: 'warning',
                  title: "An error occured. Please contact Admin!",
                  showConfirmButton: false,
                  timer: 1500,
                })
              });
            SetnonActive(true);
          } else {
            SetnonActive(false);
            // $(".noneActiveDataTableDiv").css('display','none');
          }
          //End- Recall Non-Active Staffs List
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: "An error occured. Please contact Admin!",
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
      .catch(err => {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: "An error occured. Please contact Admin!",
          showConfirmButton: false,
          timer: 1500,
        })
      });
  }

  return (
    <div className="staffProfileList px-3">
      <Navbar />
      <div className="accessDeniedMsg pt-5 mt-5 text-center">
        <h1></h1>
      </div>
      <div className="staffProfileDiv">
        <h1>Staff Profiles</h1>
        <div className="text-start mb-3">
          <Link to="/addstaff"><button type="button" className="btn btn-secondary bg-success" id='btn_addStaff'>
            <FontAwesomeIcon title="Edit Staff Profile" className="text-white" icon={faPlus} /> &nbsp;&nbsp;Add Staff</button>
          </Link>
        </div>
        <div className="profileDiv">
          <div className="staffTemplateDiv">

            <table id="active_roles_tbl">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Gender</th>
                  <th scope="col">TIN</th>
                  <th scope="col" className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  // if(userDetails != null){
                  userDetails.map((user, key) =>
                    <tr key={key}>
                      <td className='table-data'>{user.first_name} {user.last_name} </td>
                      <td className='table-data'>{user.email}</td>
                      <td className='table-data'>{user.gender_str}</td>
                      <td className='table-data'>{user.tin}</td>
                      <td className='table-data text-center'>
                        {permisionListUX.includes("caneditstaff") ? (
                          <Link to="/staffinsidpPage" className="staffinsidpPageDiv px-2 actionEdit" state={{ staffInfo: user }}>
                            <FontAwesomeIcon title="Edit Staff profile" className="text-primary" icon={faEye} />
                          </Link>
                        ) : (
                          <span></span>
                        )}
                      </td>
                    </tr>
                  )
                  // }
                }
              </tbody>
            </table>
          </div>
        </div>

        {/* Show Inactive Staffs */}
        <br></br>
        <label htmlFor="checkednonchildActive">Show Inactive Staffs</label>&nbsp;&nbsp;
        <input className="checkbox_input" name="checkednonchildActive" type="checkbox" onClick={(e) => checkednonchildActive(e)} />
      </div>

      <div className="noneActiveDataTableDiv mt-3">
        <table id="nonactive_lookups_parent_tbl">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Gender</th>
              <th scope="col">TIN</th>
              <th scope="col" className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              NonActiveLookupsDetails.map((staff, key) =>
                <tr key={key}>
                  <td className='table-data'>{staff.first_name} {staff.last_name} </td>
                  <td className='table-data'>{staff.email}</td>
                  <td className='table-data'>{staff.gender_str}</td>
                  <td className='table-data'>{staff.tin}</td>
                  <td className='table-data text-center'>
                    {/* <FontAwesomeIcon title="Activat Lookup Parent" className="text-dark" icon={faCheck} onClick={(e) => activateOnClickCheck(e, staff)} /> */}

                    {permisionListUX.includes("canactivatestaff") ? (
                      <FontAwesomeIcon title="Activat Lookup Parent" className="text-dark" icon={faCheck} onClick={(e) => activateOnClickCheck(e, staff)} />
                    ) : (
                      <span></span>
                    )}

                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Staff;


