import React from "react";
import "../styles/Main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import $ from 'jquery';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import axios, { Axios } from "axios";
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { faLink, faPencil, faTrash, faArrowRight, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import Navbar from './../components/Navbar';

function Lookups() {
    let [childName, setChildName] = useState('');
    let [childLookupsDetails, setChildLookupsDetails] = useState([]);
    let [NonActiveLookupsDetails, setNonActiveLookupsDetails] = useState([]);
    let [editRowID, seteditRowID] = useState('');
    const location = useLocation();
    let [adding_child, SetAdding_child] = useState(false);
    let [nonActive, SetnonActive] = useState(false);
    let [permisionListUI, SetpermisionListUI] = useState([]);
    const [openForm, setopenForm] = useState(false);

    useEffect(() => {
        const fetchata = async () => {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "POST",
                url: "lookups/getActiveLookupsChildren",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: { id: location.state.x.id },
            })
                .then(result => {
                    SetpermisionListUI(result.data.permissionList[0]);
                    if (result.data.permissionList[0].includes("canaddlookups")) {
                        $("#btn_addLookupsChild").addClass("d-block");
                    }

                    if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        setChildLookupsDetails(result.data.lookupchildlist);
                        setTimeout(() => {
                            $("#lookups_child_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        }
        fetchata();
    }, []);

    let lookupsChildSubmit = e => {
        e.preventDefault();
    }

    let addLookupsChild = e => {
        e.preventDefault();
        if (openForm == false) {
            setopenForm(true);
            $("#addLookupsChildForm").css("display", "block");
            $(".saveLookupsChildDiv").css("display", "block");
            $(".updateLookupChildDiv").css("display", "none");
            setChildName('');
        }
        else {
            setopenForm(false);
            $("#addLookupsChildForm").css("display", "none");
        }
    }

    let saveLookupsChildForm = e => {
        e.preventDefault();
        if (!adding_child) {
            let exists = childLookupsDetails.find(x => x.name === childName.trim());
            if (exists) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: "This lookup already exists!",
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                if (childName.trim() == "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "Please Enter Lookup Name.",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    // this.child_name_msg = "";
                    // this.child_name_error = true;
                } else {
                    let user_str = localStorage.getItem("HrApiData");
                    let user = JSON.parse(user_str);
                    axios({
                        method: "POST",
                        url: "lookups/addLookupsChild",
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                        data: {
                            name: childName.trim(),
                            parent_id: location.state.x.id,
                        },
                    })
                        .then(result => {
                            if (result.data.code == 99) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'Access Denied!',
                                    text: 'User does not have permission to access this page.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else if (result.data.code == 3) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: "This lookup already exists!",
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else if (result.data.code == 2) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: "Please Enter Lookup Name.",
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                // this.toast.error("name is required!");
                                // this.name_msg = "Please Enter Lookup Name.";
                                // this.child_name_error = true;
                            } else if (result.data.code == 1) {
                                // this.add_lookup_child_form = false;
                                // this.savechildbutton = false;
                                // this.updatechildbtn = false;
                                // this.child_name = "";
                                // this.child_name_error = false;
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: "Lookup added successfully!",
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                $("#addLookupsChildForm").css("display", "none");
                                setChildName('');
                                $("#lookups_child_tbl").DataTable().destroy();
                                setChildLookupsDetails(result.data.data.lookupchildlist);
                                setTimeout(() => {
                                    $("#lookups_child_tbl").DataTable({
                                        lengthMenu: [
                                            [5, 10, 25, 50, -1],
                                            [5, 10, 25, 50, "All"],
                                        ],
                                        pageLength: 10,
                                    });
                                });
                            } else {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: "An error occured. Please contact Admin!",
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(err => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'warning',
                                title: "An error occured. Please contact Admin!",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        });
                }
            }
        }
    }

    function editOnClickLookupsChild(e, lookups) {
        e.preventDefault();
        setopenForm(true);
        $("#addLookupsChildForm").css("display", "block");
        $(".saveLookupsChildDiv").css("display", "none");
        $(".updateLookupChildDiv").css("display", "block");
        $("#lookupChildName").val("");
        setChildName(lookups.name);
        seteditRowID(lookups.id);
    }

    function deleteOnClickLookupsChild(e, lookups) {
        e.preventDefault();
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        $("#addLookupsChildForm").css("display", "none");
        axios({
            method: "POST",
            url: "lookups/deleteLookupsChild",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                id: lookups.id,
            },
        })
            .then(result => {
                console.log(result, 'delete result-child-lookups- 2')

                if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                } else if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Lookup deleted successfully!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    $("#lookups_child_tbl").DataTable().destroy();
                    setChildLookupsDetails(result.data.data.lookupchildlist);
                    setTimeout(() => {
                        $("#lookups_child_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                    // Inactive table - uncomment to show
                    $("#inactive_lookups_child_tbl").DataTable().destroy();
                    setNonActiveLookupsDetails(result.data.data2.lookupsInactive_childlist);
                    setTimeout(() => {
                        $("#inactive_lookups_child_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "An error occured. Please contact Admin!",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: "This lookup already exists!",
                    showConfirmButton: false,
                    timer: 1500
                })
                this.toast.warning("An error occured. Please contact Admin!");
            });
    }

    let updateLookupsChildForm = e => {
        e.preventDefault();
        // this.adding_child = false;
        if (childName.trim() == "") {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please Enter Lookup Name.',
                showConfirmButton: false,
                timer: 1500
            })
            // this.child_name_error = true;
        } else {
            let lookup = childLookupsDetails.find(x => x.id === editRowID);
            if (!(lookup.name === childName)) {
                let exists = childLookupsDetails.find(x => x.id != editRowID && x.name === childName.trim());
                if (exists) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'This lookup already exists!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    let user_str = localStorage.getItem("HrApiData");
                    let user = JSON.parse(user_str);
                    axios({
                        method: "POST",
                        url: "lookups/updateLookupsChild",
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                        data: {
                            id: editRowID,
                            name: childName.trim(),
                            parent_id: location.state.x.id,
                        },
                    })
                        .then(result => {
                            if (result.data.code == 99) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Access Denied!',
                                    text: 'User does not have permission to access this page.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else if (result.data.code == 3) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'This lookup already exists!',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            } else if (result.data.code == 2) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Please Enter Lookup Name.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                // this.name_error = true;
                            } else if (result.data.code == 1) {
                                // this.add_lookup_child_form = false;
                                // this.savechildbutton = false;
                                // this.updatechildbtn = false;
                                // this.child_name = "";
                                // this.child_name_error = false;
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Lookup updated successfully!',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                $("#addLookupsChildForm").css("display", "none");
                                setChildName('');
                                $("#lookups_child_tbl").DataTable().destroy();
                                setChildLookupsDetails(result.data.data.lookupchildlist);
                                setTimeout(() => {
                                    $("#lookups_child_tbl").DataTable({
                                        lengthMenu: [
                                            [5, 10, 25, 50, -1],
                                            [5, 10, 25, 50, "All"],
                                        ],
                                        pageLength: 10,
                                    });
                                });
                            } else {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'An error occured. Please contact Admin!',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(err => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'warning',
                                title: 'An error occured. Please contact Admin!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        });
                }
            }
        }

    }

    function checkedInactiveChild(e) {
        if (!nonActive) {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "POST",
                url: "lookups/getNonActiveLookupsChild",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {
                    id: location.state.x.id,
                },
            })
                .then(result => {
                    SetpermisionListUI(result.data.permissionList[0]);
                    if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    } else {
                        $(".InActiveChildDataTableDiv").css('display', 'block');
                        $("#inactive_lookups_child_tbl").DataTable().destroy();
                        setNonActiveLookupsDetails(result.data.lookupsInactive_childlist);
                        setTimeout(() => {
                            $("#inactive_lookups_child_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "An error occured. Please contact Admin!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
            SetnonActive(true);
        } else {
            SetnonActive(false);
            $(".InActiveChildDataTableDiv").css('display', 'none');
        }
    }

    function activateInactiveChild(e, childRowData) {
        e.preventDefault();
        // this.adding_child = false;
        // this.add_lookup_child_form = false;
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        axios({
            method: "POST",
            url: "lookups/activateLookupsChild",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                id: childRowData.id,
            },
        })
            .then(result => {
                if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Lookup updated successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    $("#lookups_child_tbl").DataTable().destroy();
                    setChildLookupsDetails(result.data.data.lookupchildlist);
                    setTimeout(() => {
                        $("#lookups_child_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                    $("#inactive_lookups_child_tbl").DataTable().destroy();
                    setNonActiveLookupsDetails(result.data.data2.lookupsInactive_childlist);
                    setTimeout(() => {
                        $("#inactive_lookups_child_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    return (
        <div className="lookupsDiv px-3">
            <Navbar />
            <h2 className="text-capitalize">Lookups Child - {location.state.x.name}</h2>
            <button type="button" className="btn btn-secondary bg-success" id="btn_addLookupsChild" onClick={addLookupsChild}>
                <FontAwesomeIcon title="Edit Looksup Child" className="text-white" icon={faPlus} /> &nbsp;&nbsp;Add Lookup
            </button>
            <form id="addLookupsChildForm" onSubmit={lookupsChildSubmit}>
                <div className="row">
                    {/* childName */}
                    <div className="form-group col-md-12">
                        <div className="input-group">
                            <input type="text" className="form-control" id="lookupChildName" placeholder="Name" name="lookupChildName" value={childName} onChange={(e) => setChildName(e.target.value)} />
                            <div className="input-group-append">
                                {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                            </div>
                        </div>
                        <small id="lookupChildName_msg" className="showmsg"></small>
                    </div>
                    <div className="col-1 saveLookupsChildDiv">
                        <button type="submit" className="btn btn-primary my-2 col-12" id="btn_saveLookupsChild" onClick={saveLookupsChildForm}>Save</button>
                    </div>
                    <div className="col-1 updateLookupChildDiv">
                        <button type="submit" className="btn btn-warning my-2 col-12" id="btn_updateLookupsChild" value={editRowID} onChange={(e) => seteditRowID(e.target.value)} onClick={updateLookupsChildForm}>Update</button>
                    </div>
                    <div className="col-1">
                        <button type="submit" className="btn btn-primary my-2 col-12" id="btn_cancelLookupsChild" onClick={addLookupsChild}> Cancel</button>
                    </div>
                </div>
            </form>
            <div className="dataTableDiv mt-3">
                <div className="datatableDiv" >
                    <table id="lookups_child_tbl">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                {/* <th scope="col">Description</th> */}
                                <th scope="col" className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                childLookupsDetails.map((lookups, key) =>
                                    <tr key={key}>
                                        <td className='table-data'>{lookups.name}</td>
                                        {/* <td className='table-data'>{lookups.childDescription }</td> */}
                                        <td className='table-data text-center'>

                                            {/* <Link to="/adminpermissions" state={{ from: lookups.id }} className="text-dark">
                            <FontAwesomeIcon title="Open Lookup" className="text-success" icon={faArrowRight}/>              
                        </Link> */}

                                            {permisionListUI.includes("candeditlookupschild") ? (
                                                <FontAwesomeIcon title="Edit Lookup" className="text-primary px-3" icon={faPencil} onClick={(e) => editOnClickLookupsChild(e, lookups)} />
                                            ) : (
                                                <span></span>
                                            )}

                                            {permisionListUI.includes("candeletelookupschild") ? (
                                                <FontAwesomeIcon title="Inactivate Lookup" className="text-danger px-3" icon={faTrash} onClick={(e) => deleteOnClickLookupsChild(e, lookups)} />
                                            ) : (
                                                <span></span>
                                            )}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <br></br>
            <label htmlFor="checkednonchildActive">View Non Active Lookups</label>&nbsp;&nbsp;
            <input className="checkbox_input" name="checkednonchildActive" type="checkbox" onClick={(e) => checkedInactiveChild(e)} />
            <div className="InActiveChildDataTableDiv mt-3">
                <table id="inactive_lookups_child_tbl">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col" className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            NonActiveLookupsDetails.map((childRowData, key) =>
                                <tr key={key}>
                                    <td className='table-data'>{childRowData.name}</td>
                                    <td className='table-data'>{childRowData.description}</td>
                                    <td className='table-data text-center'>
                                        {/* <FontAwesomeIcon title="Activat Lookup Parent" className="text-dark" icon={faCheck} onClick={(e) => activateInactiveChild(e, childRowData)} /> */}

                                        {permisionListUI.includes("canactivatelookupschild") ? (
                                            <FontAwesomeIcon title="Activat Lookup Parent" className="text-dark" icon={faCheck} onClick={(e) => activateInactiveChild(e, childRowData)} />
                                        ) : (
                                            <span></span>
                                        )}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default Lookups;