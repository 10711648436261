import { useEffect, useState } from "react";
import React from "react";
import "../styles/Main.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";
import { faLink, faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Navbar from "./../components/Navbar";

export default function AddRoles() {
  let [roles, setRoles] = useState([]);
  let [roleName, setroleName] = useState("");
  let [RowId, setRowId] = useState("");
  let [permisionListUX, SetpermisionListUX] = useState([]);
  let [openForm, setopenForm] = useState(false);

  useEffect(() => {
    const fetchata = async () => {
      let user_str = localStorage.getItem("HrApiData");
      let user = JSON.parse(user_str);
      axios({
        method: "GET",
        url: "users/getAll",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
        data: {},
      })
        .then(async (result) => {
          SetpermisionListUX(result.data.permissions);
          appendDatatable(result.data.roles);
          // $("#AllRolesTable").DataTable().destroy();
          // setRoles(result.data.roles);
          // setTimeout(() => {
          //   $("#AllRolesTable").DataTable({
          //     lengthMenu: [
          //       [5, 10, 25, 50, -1],
          //       [5, 10, 25, 50, "All"],
          //     ],
          //     pageLength: 10,
          //     order: [[0, "asc"]],
          //   });
          // });
          if (result.data.permissions.includes("canaddrole")) {
            $('#addRoles_Btn').css('display', 'block')
          }
        })
        .catch((err) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "warning",
            title: "An error occured. Please contact Admin!",
          });
        });
    };
    // Call the function
    fetchata();
  }, []);

  let addRoles = (e) => {
    e.preventDefault();

    if (openForm == false) {
      setopenForm(true);
      setroleName("");
      $(".btn_saveLookups_div").css("display", "block");
      $(".btn_updateLookups_div").css("display", "none");
      $("#addRoleForm").css("display", "block");
    }
    else {
      setopenForm(false);
      $("#addRoleForm").css("display", "none");
    }
  };

  let lookupsSubmit = (e) => {
    e.preventDefault();
    $("#addRoleForm").css("display", "block");
  };

  let saveRoles = (e) => {
    e.preventDefault();
    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);

    axios({
      method: "POST",
      url: "users/addRole",
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      data: {
        role_name: roleName,
        role_id: user.id,
      },
    })
      .then((result) => {
        if (result.data.msg.type == 99) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: 'Access Denied!',
            text: 'User does not have permission to access this page.',
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (result.data.msg.type == 3) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Please Enter Role Name",
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (result.data.msg.type == 2) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "This Role already exists!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (result.data.msg.type == 1) {

          appendDatatable(result.data.msg.data);
          // $("#AllRolesTable").DataTable().destroy();
          // setRoles(result.data.msg.data);
          // $("#AllRolesTable").DataTable({
          //   lengthMenu: [
          //     [5, 10, 25, 50, -1],
          //     [5, 10, 25, 50, "All"],
          //   ],
          //   pageLength: 10,
          //   order: [[0, "asc"]],
          // });
          addRoles(e);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Role Added Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "An error occured. Please contact Admin.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "An error occured. Please contact Admin.",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  function editOnClick(e, role) {
    e.preventDefault();
    setopenForm(true);
    setroleName(role.role_name);
    setRowId(role.id);
    $("#addRoleForm").css("display", "block");
    $(".btn_saveLookups_div").css("display", "none");
    $(".btn_updateLookups_div").css("display", "block");
  }

  let updateRoles = (e) => {
    e.preventDefault();
    let rowID = $("#btn_updateLookups").val();
    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);

    axios({
      method: "POST",
      url: "users/updateRole",
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      data: {
        role_name: roleName,
        id: rowID,
      },
    })
      .then((result) => {
        if (result.data.msg.type == 99) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: 'Access Denied!',
            text: 'User does not have permission to access this page.',
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (result.data.msg.type == 3) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Please Enter Role Name",
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (result.data.msg.type == 2) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "This Role already exists!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (result.data.msg.type == 1) {

          appendDatatable(result.data.msg.data);

          // $("#AllRolesTable").DataTable().destroy();
          // setRoles(result.data.msg.data);
          // setTimeout(() => {
          //   $("#AllRolesTable").DataTable({
          //     lengthMenu: [
          //       [5, 10, 25, 50, -1],
          //       [5, 10, 25, 50, "All"],
          //     ],
          //     pageLength: 10,
          //   });
          // });

          addRoles(e);

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Role Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "An error occured. Please contact Admin.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "An error occured. Please contact Admin.",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  let appendDatatable = (dataset) => {
    $("#AllRolesTable").DataTable().destroy();
    setRoles(dataset);
    setTimeout(() => {
      $("#AllRolesTable").DataTable({
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, "All"],
        ],
        pageLength: 10,
        order: [[0, "asc"]],
      });
    });
  }

  return (
    <div className=" px-3">
      <Navbar />
      <div className="addRolesDiv">
        <Link to="/allroles">
          <button className="btn btn-secondary bg-success mb-3" id="addRoles_Btn" onClick={addRoles}>
            <FontAwesomeIcon title="Edit Usermanagement" className="text-white" icon={faPlus} /> &nbsp;&nbsp;Add Roles
          </button>
        </Link>
        <form id="addRoleForm" onSubmit={lookupsSubmit}>
          <div className="row">
            {/* Role Name */}
            <div className="form-group col-md-12">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="roleName"
                  placeholder="Role Name"
                  name="roleName"
                  value={roleName}
                  onChange={(e) => setroleName(e.target.value)}
                />
                <div className="input-group-append">
                  {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                </div>
              </div>
              <small id="roleName_msg" className="showmsg"></small>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-1 col-md-2 btn_saveLookups_div">
              <button
                type="submit"
                className="btn btn-primary my-2 col-12"
                id="btn_saveLookups"
                onClick={saveRoles}
              >
                Save
              </button>
            </div>
            <div className="col-1 col-md-2 btn_updateLookups_div">
              <button
                type="submit"
                className="btn btn-warning my-2 col-12"
                id="btn_updateLookups"
                value={RowId}
                onChange={(e) => setRowId(e.target.value)}
                onClick={updateRoles}
              >
                Update
              </button>
            </div>
            <div className="col-1 col-md-2">
              <button
                type="submit"
                className="btn btn-primary my-2 col-12"
                id="btn_cancelLookups"
                onClick={addRoles}
              >
                {" "}
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="datatableDiv">
        <table id="AllRolesTable">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role, key) => (
              <tr key={key}>
                <td className="table-data">{role.id}</td>
                <td className="table-data">{role.role_name}</td>
                <td className="table-data">
                  {/* <FontAwesomeIcon title="Edit" className="text-primary" icon={faPencil} onClick={(e) => editOnClick(e, role)} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/adminpermissions" state={{ from: role.id, from2: role.role_name }} className="text-info">
                    <FontAwesomeIcon title="Map Permission" icon={faLink} />
                  </Link> */}

                  {permisionListUX.includes("caneditrole") ? (
                    <FontAwesomeIcon title="Edit Role" className="text-primary px-3" icon={faPencil} onClick={(e) => editOnClick(e, role)} />
                  ) : (
                    <span></span>
                  )}

                  {permisionListUX.includes("canmappermssion") ? (
                    <Link to="/adminpermissions" state={{ from: role.id, from2: role.role_name }} className="text-info">
                      <FontAwesomeIcon title="Map Permission" icon={faLink} />
                    </Link>) : (
                    <span></span>
                  )}

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
