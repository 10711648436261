import React from "react";
import "../styles/Main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import $ from 'jquery';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import axios, { Axios } from "axios";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { faLink, faPencil, faTrash, faArrowRight, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import Navbar from './../components/Navbar';
import { SpaRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import moment from 'moment';


function Reminder() {
    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);

    let [name, setName] = useState('');
    let [description, setdescription] = useState('');
    let [reminder_date, setreminder_date] = useState('');
    let [initial_date, setinitial_date] = useState('');
    let openRemindersForm = true;
    let [reminderDetails, setreminderDetails] = useState([]);
    let [editRowID, seteditRowID] = useState('');
    const [openForm, setopenForm] = useState(false);
    let [permisionList, SetpermisionList] = useState([]);
    const date = moment().format("DD-MM-YYYY");
    const time = moment().format("HH mm ss");

    useEffect(() => {
        const fetchata = async () => {
            axios({
                method: "GET",
                url: "reminders/getAllReminders",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(async result => {
                    // console.log(result, 'getAllReminders');
                    if (result.data.code == 1) {
                        setreminderDetails(result.data.reminderlist);
                        SetpermisionList(result.data.permissionList[0]);
                        setTimeout(() => {
                            $("#reminders_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });

                        if (result.data.permissionList[0].includes("canaddreminder")) {
                            $("#btn_addReminder").addClass("d-block");
                        }
                    }
                    else if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'No records Found',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                    else if (result.data.code == 99) {
                        // //Hide Add Staff Btn & Data table Header Fields
                        // //Show A Div with Message - User Not Permitted
                        // $(".accessDeniedMsg").css("display", "block");
                        // $(".LeaveFormDiv").css("display", "none");
                        // $(".accessDeniedMsg h1").html("User Not Permitted");

                        // Swal.fire({
                        //     position: 'top-end',
                        //     icon: 'warning',
                        //     title: 'Access Denied!',
                        //     text: 'User does not have permission to access this page.',
                        //     showConfirmButton: false,
                        //     timer: 2500,
                        // })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
        fetchata();
    }, []);

    let addReminders = e => {
        e.preventDefault();

        if (openForm == false) {
            setopenForm(true);
            $("#addRemindersForm").css("display", "block");
            $(".saveRemindersDiv").css("display", "block");
            $(".updateRemindersDiv").css("display", "none");
            setName('');
            setdescription('');
            setinitial_date('');
            setreminder_date('');
        }
        else {
            setopenForm(false);
            $("#addRemindersForm").css("display", "none");
        }
    }

    let saveRemindersForm = e => {
        e.preventDefault();
        $('.showmsg').addClass("d-none");
        var exist = !$.inArray("true", error);
        // dateFilled = dateFilled;

        var error = new Array();
        var maskAlpha = /^[a-zA-Z ]+$/;
        var mask5 = /\S+@\S+\.\S+/;
        if (description == '') {
            $('#reminderDescription_msg').html('');
            $('#reminderDescription').addClass('invalid');
            $('#reminderDescription_msg').html('Description is required');
            $('#reminderDescription_msg').removeClass("d-none");
            error.push('true');
        }
        else if (description.length < 15) {
            $('#reminderDescription_msg').html('');
            $('#reminderDescription').addClass('invalid');
            $('#reminderDescription_msg').html('Description is too short');
            $('#reminderDescription_msg').removeClass("d-none");
            error.push('true');
        }
        if (name == '') {
            $('#productName_msg').html('');
            $('#productName').addClass('invalid');
            $('#productName_msg').html('Please enter name');
            $('#productName_msg').removeClass("d-none");
            error.push('true');
        } else if (!maskAlpha.test(name)) {
            $('#productName_msg').html('');
            $('#productName').addClass('invalid');
            $('#productName_msg').html('Name field can only have alphabetical characters');
            $('#productName_msg').removeClass("d-none");
            error.push('true');
        }
        if (initial_date == '') {
            $('#initial_date_msg').html('');
            $('#initial_date').addClass('invalid');
            $('#initial_date_msg').html('Please select date');
            $('#initial_date_msg').removeClass("d-none");
            error.push('true');
        }
        if (reminder_date == '') {
            $('#reminder_date_msg').html('');
            $('#reminder_date').addClass('invalid');
            $('#reminder_date_msg').html('Please select date');
            $('#reminder_date_msg').removeClass("d-none");
            error.push('true');
        }

        if (exist == false) {
            axios({
                method: "POST",
                url: "reminders/addReminderDetails",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {
                    name: name,
                    description: description,
                    reminder_date: reminder_date,
                    initial_date: initial_date,
                },
            })
                .then(result => {
                    console.log(result, 'after saving details');
                    if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else if (result.data.code == 1) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Reminder added successfully!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        $("#addRemindersForm").css("display", "none");
                        setName('');
                        setdescription('');
                        setinitial_date('');
                        setreminder_date('');
                        $("#reminders_tbl").DataTable().destroy();
                        setreminderDetails(result.data.reminderlist);
                        setTimeout(() => {
                            $("#reminders_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });
                    }
                    else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'An error occured. Please contact Admin!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        }
        return false;
    }

    let updateRemindersForm = e => {
        e.preventDefault();
        var exists = false;
        let reminderlist = reminderDetails;
        let reminderRow = reminderlist.find(x => x.id === editRowID.id);
        if ((reminderRow.name === name && reminderRow.description === description)) {
            exists = true;
            if (exists) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: "Reminder name already exists!",
                    showConfirmButton: false,
                    timer: 1500,
                })
            }

        } else {
            axios({
                method: "POST",
                url: "reminders/addReminderDetails",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {
                    id: editRowID.id,
                    name: name,
                    description: description != null ? description.trim() : "",
                    reminder_date: reminder_date,
                    initial_date: initial_date,
                },
            })
                .then(result => {
                    if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    } else if (result.data.code == 1) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Reminder updated successfully!",
                            showConfirmButton: false,
                            timer: 1500,
                        })
                        $("#addRemindersForm").css("display", "none");
                        setName('');
                        setdescription('');
                        setinitial_date('');
                        setreminder_date('');
                        $("#reminders_tbl").DataTable().destroy();
                        setreminderDetails(result.data.reminderlist);
                        setTimeout(() => {
                            $("#reminders_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: "An error occured. Please contact Admin!",
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "An error occured. Please contact Admin!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
    }

    function editOnClickReminderForm(e, x) {
        e.preventDefault();
        setopenForm(true);
        $("#addRemindersForm").css("display", "block");
        $(".saveRemindersDiv").css("display", "none");
        $(".updateRemindersDiv").css("display", "block");
        setName(x.name);
        setdescription(x.description);
        let initial_date = x.initial_date;
        initial_date = dayjs(initial_date).format("YYYY-MM-DD");
        setinitial_date(initial_date);
        let reminder_date = x.reminder_date;
        reminder_date = dayjs(reminder_date).format("YYYY-MM-DD");
        setreminder_date(reminder_date);
        seteditRowID(x);
    }

    return (
        <div className="remindersDiv px-3">
            <Navbar />
            <div className="accessDeniedMsg pt-5 mt-5 text-center">
                <h1></h1>
            </div>
            <div className="remindersDiv">
                <h1>Reminders</h1>
                <button type="button" className="btn btn-secondary bg-success" id="btn_addReminder" onClick={addReminders}>
                    <FontAwesomeIcon title="Edit Reminder" className="text-white" icon={faPlus} /> &nbsp;&nbsp;Add Reminder
                </button>
                <form id="addRemindersForm" >
                    <div className="row">
                        {/* Name */}
                        <div className="form-group col-md-4">
                            <label htmlFor="productName" className="form-label">Product Name</label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="productName" placeholder="Name" name="productName" value={name} onChange={(e) => setName(e.target.value)} />
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="productName_msg" className="showmsg"></small>
                        </div>
                        {/* Initial Date*/}
                        <div className="form-group col-md-4">
                            <label htmlFor="initial_date" className="form-label">Initial Date</label>
                            <div className="input-group">
                                <input type="date" className="form-control" id="initial_date" placeholder="" name="initial_date" value={initial_date} onChange={(e) => setinitial_date(e.target.value)} />
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="initial_date_msg" className="showmsg"></small>
                        </div>
                        {/* Reminder in Date*/}
                        <div className="form-group col-md-4">
                            <label htmlFor="reminder_date" className="form-label">Reminder In Date</label>
                            <div className="input-group">
                                <input type="date" className="form-control" id="reminder_date" placeholder="" name="reminder_date" value={reminder_date} onChange={(e) => setreminder_date(e.target.value)} />
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="reminder_date_msg" className="showmsg"></small>
                        </div>
                        {/* Description*/}
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <textarea type="text" rows="3" className="form-control" id="reminderDescription" placeholder="Description" name="reminderDescription" value={description} onChange={(e) => setdescription(e.target.value)} />
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="reminderDescription_msg" className="showmsg"></small>
                        </div>
                        <div className="col-2 saveRemindersDiv">
                            <button type="submit" className="btn btn-primary my-2 col-12" id="btn_saveReminder" onClick={saveRemindersForm}>Save</button>
                        </div>
                        <div className="col-2 updateRemindersDiv">
                            <button type="submit" className="btn btn-warning my-2 col-12" id="btn_updatereminder" onClick={updateRemindersForm}>Update</button>
                        </div>
                        <div className="col-2">
                            <button type="submit" className="btn btn-primary my-2 col-12" id="btn_cancelReminders" onClick={addReminders}> Cancel</button>
                        </div>
                    </div>
                </form>

                <div className="dataTableDiv mt-3">
                    <div className="datatableDiv" >
                        <table id="reminders_tbl">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Initial Date</th>
                                    <th scope="col">Reminder Date</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    reminderDetails.map((x, key) =>
                                        <tr key={key}>
                                            <td className='table-data'>{x.name}</td>
                                            <td className='table-data'>{x.description}</td>
                                            <td className='table-data'>{moment(x.initial_date).format("DD-MM-YYYY")}</td>
                                            <td className='table-data'>{moment(x.reminder_date).format("DD-MM-YYYY")}</td>
                                            <td className='table-data text-center'>
                                                {permisionList.includes("caneditreminder") ? (
                                                    <FontAwesomeIcon title="Edit Reminder" className="text-primary px-3" icon={faPencil} onClick={(e) => editOnClickReminderForm(e, x)} />
                                                ) : (
                                                    <span></span>
                                                )}

                                                {/* {permisionListUX.includes("deletelookupsparent") ? ( */}
                                                {/* <FontAwesomeIcon title="Inactivate Reminder" className="text-danger px-3" icon={faTrash} /> */}
                                                {/* ) : ( */}
                                                {/* <span></span> */}
                                                {/* )} */}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Reminder;