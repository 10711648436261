import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";
import { faBars, faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { useState, useEffect, useRef } from "react";
import Swal from 'sweetalert2';
import "../styles/Main.css";
import axios from "axios";
import Navbar from './../components/Navbar';

function Permissions() {
    const navigate = useNavigate();
    let [permissions, setPermissions] = useState([]);
    let [permissionName, setPermissionName] = useState('');
    let [permissionDescription, setpermissionDescription] = useState('');
    let [permissionRowId, setpermissionRowId] = useState('');
    const [permissioDetails, setpermissioDetails] = useState([]);
    let [permisionListUX, SetpermisionListUX] = useState([]);
    const ref = useRef(null);
    const [openForm, setopenForm] = useState(false);

    useEffect(() => {
        const fetchata = async () => {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "GET",
                url: "permission/getallpermissions",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
            })
                .then(async result => {
                    console.log(result.data.permissionList[0]);
                    SetpermisionListUX(result.data.permissionList[0]);
                    if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page',
                            showConfirmButton: false,
                            timer: 1500
                        })

                        $(".accessDeniedMsg").css("display", "block");
                        $(".permissionsDIv").css("display", "none");
                        $(".accessDeniedMsg h1").html("User Not Permitted");

                    } else {
                        $("#AllPermissionsTable").DataTable().destroy();
                        setPermissions(result.data.permissions);
                        setTimeout(() => {
                            $("#AllPermissionsTable").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                                "order": [
                                    [0, "asc"]
                                ]
                            });
                        });
                    }

                    if (result.data.permissions) {
                        $("#btn_addPermission").css("display", "block");
                    }
                })
                .catch(err => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!'
                    });
                });
        }
        // Call the function
        fetchata();
    }, []);

    const addPermission = e => {
        e.preventDefault();

        if (openForm == false) {
            setopenForm(true);
            setPermissionName('');
            setpermissionDescription('');
            $("#addPermissionForm").css("display", "block");
            $(".updatePermissionDiv").css("display", "none");
            $(".savePermissionDiv").css("display", "block");
        }
        else {
            setopenForm(false);
            $("#addPermissionForm").css("display", "none");
        }
    }

    const lookupsSubmit = e => {
        e.preventDefault()
    }

    const savePermission = e => {
        e.preventDefault()
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        axios({
            method: "POST",
            url: "permission/addpermission",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                permission_name: permissionName,
                description: permissionDescription,
                createdBy: user.id
            },
        })
            .then(result => {
                if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else if (result.data.code == 3) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Please Enter Permission Name',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else if (result.data.code == 2) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'This Permission already exists!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else if (result.data.code == 1) {
                    $("#AllPermissionsTable").DataTable().destroy();
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Permissions Added Successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    addPermission(e);
                    setPermissions(result.data.data);
                    $("#AllPermissionsTable").DataTable({
                        lengthMenu: [
                            [5, 10, 25, 50, -1],
                            [5, 10, 25, 50, "All"],
                        ],
                        pageLength: 10,
                        "order": [
                            [0, "asc"]
                        ]
                    });
                }
                else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin.',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    function editOnClick(e, permission) {
        e.preventDefault();
        setopenForm(true);
        $("#addPermissionForm").css("display", "block");
        $(".savePermissionDiv").css("display", "none");
        $(".updatePermissionDiv").css("display", "block");
        $("#permissionName").val("");
        $("#permissionDescription").val("");

        setPermissionName(permission.permission_name);
        setpermissionDescription(permission.description);
        setpermissionRowId(permission.id);
    }

    let updatePermission = (e) => {
        e.preventDefault();
        let permissionRowId = $("#btn_updatePermission").val();
        if (permissionName.trim() == "") {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Please enter permission name.',
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "POST",
                url: "permission/updatepermission",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {
                    permission_name: permissionName,
                    description: permissionDescription ?? "",
                    id: permissionRowId,
                },
            })
                .then(result => {
                    if (result.data.code == 1) {
                        setPermissions(result.data.data);
                        $("#AllPermissionsTable").DataTable().destroy();
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Permission updated successfully.',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                        addPermission(e);
                        $("#AllPermissionsTable").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                            "order": [
                                [0, "asc"]
                            ]
                        });
                        //  hide form on update success
                    } else if (result.data.code == 2) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Please enter permission name.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else if (result.data.code == 3) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'This permission already exists.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        this.toast.warning("An error occured. Please contact Admin!");
                    }
                })
                .catch(err => {
                    this.toast.warning("An error occured. Please contact Admin!");
                });
        }
    }

    return (
        <div className="px-3 permissionListDiv">
            <Navbar />
            <div className="accessDeniedMsg pt-5 mt-5 text-center">
                <h1></h1>
            </div>
            <div className="permissionsDIv">
                <h1>Permission Mapping Table</h1>
                <button className="btn btn-secondary bg-success mb-3" id='btn_addPermission' onClick={addPermission}>
                    <FontAwesomeIcon title="Edit Permission" className="text-white" icon={faPlus} /> &nbsp;&nbsp;Add Permission
                </button>
                <form id="addPermissionForm" onSubmit={lookupsSubmit}>
                    <div className="row">
                        {/* Role Name */}
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <input type="text" className="form-control" id="permissionName" placeholder="Permission Name" name="permissionName" value={permissionName} onChange={(e) => setPermissionName(e.target.value)} />
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="permissionName_msg" className="showmsg"></small>
                        </div>
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <textarea
                                    rows="3"
                                    placeholder="Enter description" type="text" className="form-control" id="permissionDescription" name="permissionDescription" value={permissionDescription} onChange={(e) => setpermissionDescription(e.target.value)}
                                ></textarea>
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="permissionDescription_msg" className="showmsg"></small>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-1 col-md-2 savePermissionDiv">
                            <button type="submit" className="btn btn-primary my-2 col-12" id="btn_savePermissions" onClick={savePermission}>Save</button>
                        </div>
                        <div className="col-1 col-md-2 updatePermissionDiv">
                            <button type="submit" className="btn btn-warning my-2 col-12" id="btn_updatePermission" value={permissionRowId} onChange={(e) => setpermissionRowId(e.target.value)} onClick={updatePermission}>Update</button>
                        </div>
                        <div className="col-1 col-md-2">
                            <button type="submit" className="btn btn-primary my-2 col-12" id="btn_cancelPermissions" onClick={addPermission}>Cancel</button>
                        </div>
                    </div>
                </form>

                <div className="datatableDiv mt-3">
                    <table id="AllPermissionsTable">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Permission Name</th>
                                <th scope="col">Permission Description</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                permissions.map((permission, key) =>
                                    <tr key={key}>
                                        <td className='table-data'>{permission.id}</td>
                                        <td className='table-data'>{permission.permission_name}</td>
                                        <td className='table-data'>{permission.description}</td>
                                        <td className='table-data'>
                                            {permisionListUX.includes("canupdatepermissions") ? (
                                                <FontAwesomeIcon title="Edit" className="text-primary" icon={faPencil} onClick={(e) => editOnClick(e, permission)} />
                                            ) : (
                                                <span></span>
                                            )}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    );
}

export default Permissions;