import React, { Component, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Greet from "./components/Greet";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Staff from "./pages/Staff";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";
import TeamProfile from "./pages/TeamProfile";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import AddStaff from "./pages/Add_Staff";
import Lookups from "./pages/Lookups";
import UserManagement from "./pages/UserManagement";
import AddRoles from "./pages/AddRoles";
import Permissions from "./pages/Permissions";
import AdminPermissions from "./pages/AdminPermissions";
import Lookups_Child from "./pages/Lookups_Child";
import Staff_Inside_Page from "./pages/Staff_Inside_Page";
import LeaveManagement from "./pages/LeaveManagement";
import Leave from "./pages/Leave";
import LeaveInsidePage from "./pages/LeaveInsidePage";
import VerifyEmail from "./pages/VerifyEmail";
import Dashboard from "./pages/Dashboard";
// import Logout from './pages/Logout';
import Register from "./pages/Register";
import LeaveTally from "./pages/LeaveTally";
import Reminder from "./pages/Reminder";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DataTable from "./js/datatables.net-dt";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "../../hello-world/src/styles/Main.css";
import Timesheet from "./pages/Timesheet";
import TS from "./pages/TS";

// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"

function App() {
  const [currentForm, setCurrentForm] = useState("login");
  const toggleForm = (formName) => {
    setCurrentForm(formName);
  };
  return (
    <div className="App appPadLeft bg_white_cls bg_blue_cls">
      <Router>
        {/* <Navbar /> */}
        {/* {window.location.pathname !== "/" ? ( <Navbar />) : null} */}
        <Routes>
          {currentForm === "login" ? (
            <Route
              path="/login"
              element={<Login onFormSwitch={toggleForm} />}
            />
          ) : (
            <Route
              onFormSwitch={toggleForm}
              path="/register"
              element={<Register />}
            />
          )}
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/lookups" element={<Lookups />} />
          <Route path="/addstaff" element={<AddStaff />} />
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route path="/addroles" element={<AddRoles />} />
          <Route path="/permissions" element={<Permissions />} />
          <Route path="/adminpermissions" element={<AdminPermissions />} />
          <Route path="/lookupschild" element={<Lookups_Child />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/teamprofile" element={<TeamProfile />} />
          <Route path="/staffinsidpPage" element={<Staff_Inside_Page />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/leavemanagement" element={<LeaveManagement />} />
          <Route path="/verifyEmail" element={<VerifyEmail />} />
          <Route path="/leaves" element={<Leave />} />
          <Route path="/leavesinsidepage" element={<LeaveInsidePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/leavetally" element={<LeaveTally />} />
          <Route path="/reminder" element={<Reminder />} />
          <Route path="/timesheet" element={<Timesheet />} />
          <Route path="/ts" element={<TS />} />
          {/* <Route path="/register"  element={<Register/>} /> */}
          {/* <Route path="/logout"  element={<LogOut/>} /> */}
        </Routes>
        {/* {window.location.pathname !== "/" ? ( <Footer />) : null} */}
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
