import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./../components/Navbar";
import moment from "moment";
import DataTable from "react-data-table-component";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { Link } from "react-router-dom";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import $ from "jquery";
import Swal from "sweetalert2";
import "../styles/Timesheet.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileUpload from "../components/FileUpload";

function Timesheet() {
  const [apiResponse, setApiResponse] = useState("");
  const [tableData, setTableData] = useState([]);
  const [timeDetails, setTimeDetails] = useState([]);
  const [permisionListUX, SetpermisionListUX] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    selectedEntry ? new Date(selectedEntry.date) : new Date()
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const makeTimesheetAPICall = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: "timesheet/makeAPICallController",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });

      setApiResponse(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: "GET",
        url: "timesheet/makeAPICallController",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
        data: {},
      })
        .then(async (result) => {
          if (result.data.code === 1) {
            SetpermisionListUX(result.data.permissionList[0]);
            setTimeDetails(result.data.leavelist);

            setTimeout(() => {
              $("#leaves_tbl").DataTable({
                lengthMenu: [
                  [5, 10, 25, 50, -1],
                  [5, 10, 25, 50, "All"],
                ],
                pageLength: 10,
              });
            });
          } else if (result.data.code === 0) {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "No records Found",
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (result.data.code === 99) {
            $(".accessDeniedMsg").css("display", "block");
            $(".LeaveFormDiv").css("display", "none");
            $(".accessDeniedMsg h1").html("User Not Permitted");

            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Access Denied!",
              text: "User does not have permission to access this page.",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "An error occurred. Please contact Admin",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      const uniqueDates = [
        ...new Set(
          apiResponse.data.map((entry) =>
            entry.inDateTime ? entry.inDateTime.split(" ")[0] : ""
          )
        ),
      ].sort();

      const getUsernameFromApiResponse = () => {
        if (apiResponse && apiResponse.data && apiResponse.data.length > 0) {
          const username = apiResponse.data[0].userFullName;
          return username;
        }
        return "";
      };

      const username = getUsernameFromApiResponse();

      const newData = uniqueDates.map((date) => {
        const entriesForDate = apiResponse.data.filter(
          (entry) => entry.inDateTime && entry.inDateTime.split(" ")[0] === date
        );

        const validEntries = entriesForDate.filter(
          (entry) =>
            entry.inDateTime.split(" ")[1] !== "00:00:00" &&
            entry.outDateTime.split(" ")[1] !== "00:00:00"
        );

        const timeIn =
          validEntries.length > 0
            ? validEntries[0].inDateTime.split(" ")[1]
            : "";

        const timeOut =
          validEntries.length > 0
            ? validEntries[validEntries.length - 1].outDateTime.split(" ")[1]
            : "";

        const jobName = validEntries.length > 0 ? validEntries[0].jobName : "";

        const lunchBreakEntry = entriesForDate.find(
          (entry) =>
            entry.managerComment &&
            entry.managerComment.toLowerCase() === "lunch break"
        );

        const lunchBreakHours = lunchBreakEntry
          ? `-${moment
            .utc()
            .hours(Math.abs(lunchBreakEntry.hours))
            .minutes(0)
            .format("HH [hours] mm [minutes]")}`
          : "";

        const totalHours = validEntries.reduce((sum, entry) => {
          const inDateTime = moment(entry.inDateTime);
          const outDateTime = moment(entry.outDateTime);

          const duration = moment.duration(outDateTime.diff(inDateTime));
          const hours = duration.hours();
          const minutes = duration.minutes();

          sum += moment
            .utc()
            .hours(hours)
            .minutes(minutes)
            .format("HH [hours] mm [minutes]");

          return sum;
        }, "");

        return {
          username,
          date,
          timeIn,
          timeOut,
          lunchBreakHours,
          totalHours,
          jobName,
        };
      });

      setTableData(newData);
    }
  }, [apiResponse]);

  let user_str = localStorage.getItem("HrApiData");
  let user = JSON.parse(user_str);

  useEffect(() => {
    if (tableData.length > 0) {
      $("#leaves_tbl").DataTable({
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, "All"],
        ],
        pageLength: 10,
      });
    }
  }, [tableData]);

  const handleEditClick = (row) => {
    setSelectedEntry(row);
    setIsEditModalVisible(true);
  };

  const handleEditSave = () => {
    setIsEditModalVisible(false);
  };

  return (
    <div className="home">
      <Navbar />
      <div className="">
        <div className="text-center">
          <h1 className="header">Timesheet</h1>
        </div>

        {/*Component call to the File Upload*/}
        <FileUpload />

        <div className="contentView">
          <div className="buttons">
            <div className="viewTimeSheetBtn">
              <button
                type="button"
                className="btn btn-secondary bg-success"
                onClick={makeTimesheetAPICall}
              >
                View Timesheet
              </button>
            </div>
            <div className="addEntryBtn">
              <button
                type="button"
                className="btn btn-secondary bg-success"
                onClick={makeTimesheetAPICall}
              >
                Add Entry
              </button>
            </div>
          </div>
          <div>
            <br />
            {tableData.length > 0 && (
              <DataTable
                columns={[
                  { name: "Staff Name", selector: "username", sortable: true },
                  {
                    name: "Date",
                    selector: "date",
                    sortable: true,
                    cell: (row) => moment(row.date).format("DD-MM-YYYY"),
                  },
                  {
                    name: "Time In",
                    selector: "timeIn",
                    sortable: true,
                    cell: (row) =>
                      moment(row.timeIn, "HH:mm:ss").format("hh:mm A"),
                  },
                  {
                    name: "Time Out",
                    selector: "timeOut",
                    sortable: true,
                    cell: (row) =>
                      moment(row.timeOut, "HH:mm:ss").format("hh:mm: A"),
                  },
                  {
                    name: "Lunch Break Hours",
                    selector: "lunchBreakHours",
                    sortable: true,
                  },
                  {
                    name: "Total Hours",
                    selector: "totalHours",
                    sortable: true,
                  },
                  { name: "Job/Absence", selector: "jobName", sortable: true },
                  {
                    name: "Actions",
                    cell: (row) => (
                      <div>
                        <Link>
                          <FontAwesomeIcon
                            title="Edit Entry"
                            className="text-primary"
                            icon={faPencil}
                            onClick={() => handleEditClick(row)}
                          />
                        </Link>
                        <Link>
                          <FontAwesomeIcon
                            title="Delete Entry"
                            className="text-danger px-3"
                            icon={faTrash}
                          />
                        </Link>
                      </div>
                    ),
                  },
                ]}
                data={tableData}
                pagination
                striped
                highlightOnHover
              />
            )}
          </div>
        </div>

        <Modal
          show={isEditModalVisible}
          onHide={() => setIsEditModalVisible(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedEntry && (
              <form>
                <div className="form-group">
                  <label>Date:</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Time In:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={moment(selectedEntry.timeIn, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Time Out:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={moment(selectedEntry.timeOut, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Lunch Break Hours:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedEntry.lunchBreakHours}
                  />
                </div>
                <div className="form-group">
                  <label>Job Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedEntry.jobName}
                  />
                </div>
              </form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsEditModalVisible(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleEditSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Timesheet;
