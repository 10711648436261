import React from "react";
import "../styles/Add_Staff.css";
import { Link, useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";
import { faBars, faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faLink, faPencil, faTrash, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { useState, useEffect, useRef } from "react";
import Swal from 'sweetalert2';
import Navbar from './../components/Navbar';
import axios from "axios";

export default function Add_Staff() {

    const navigate = useNavigate();
    let [fname, setfname] = useState('');
    let [lname, setlname] = useState('');
    let [TIN, setTIN] = useState('');
    let [FNPF, setFNPF] = useState('');
    let [email, setemail] = useState('');
    let [streetAddress, setStreetAddress] = useState('');
    let [streetAddressLine2, setStreetAddressLine2] = useState('');
    let [city, setCity] = useState('');
    let [emergencyContact, setEmergencyContact] = useState('');
    let [emergencyName, setEmergencyName] = useState('');
    let [positionTitle, setPositionTitle] = useState('');
    let [reportsTo, setReportsTo] = useState('');
    let [employmentType, setEmploymentType] = useState('');
    let [dateOfBirth, setdateOfBirth] = useState('');
    let [startDate, setStartDate] = useState('');
    let [contractEndDate, setContractEndDate] = useState('');
    let [same_residential_address, setsame_residential_address] = useState('');
    let [residentialAddress, setresidentialAddress] = useState('');
    let [phone1, setphone1] = useState('');
    let [phone2, setphone2] = useState('');
    let [otherNames, setotherNames] = useState('');
    // let [gender, setgender] = useState('');
    let [selected, setSelected] = useState(1);
    let [genderList, setgenderList] = useState([]);
    let [roles, setRoles] = useState([]);
    let [employmentTypeList, setEmploymentTypeList] = useState([]);
    let [postionList, setPostionList] = useState([]);
    let [cityTownList, setCityTownList] = useState([]);
    let [password, setpassword] = useState([]);
    let [department, setDepartment] = useState([]);
    let expanded = false;
    const checkbox = useRef();

    useEffect(() => {
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);

        const fetchata = async () => {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "GET",
                url: "lookups/getAddEmployeesData",
                headers: { Authorization: `Bearer ${user.token}` },
                data: {},
            })
                .then(async result => {
                    setRoles(result.data.roles);
                    setgenderList(result.data.genderList);
                    setEmploymentTypeList(result.data.employmentTypeList);
                    setPostionList(result.data.postionList);
                    setCityTownList(result.data.cityTownList);
                    setDepartment(result.data.departmentList
                    );
                })
                .catch(err => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!'
                    });
                });
        }
        // Call the function
        fetchata();
    }, []);

    const handleClick = (e) => {
        if (checkbox.current.checked == true) {
            $(".postalDiv").css("display", "none");
        } else {
            $(".postalDiv").css("display", "flex");
            $("#residentialAddress").val('');
            residentialAddress = '';
        }
    }

    const handleChange = e => {
        setSelected(e.target.value);
    };

    //Disable future date for datetime picker - Date of birth
    $(function () {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        var maxDate = year + '-' + month + '-' + day;
        $('#dateOfBirth').attr('max', maxDate);
    });

    //Disable future date for datetime picker - Employment start date
    $(function () {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        var maxDate = year + '-' + month + '-' + day;
        $('#startDate').attr('max', maxDate);
    });

    let saveStaffDetailsForm = e => {
        e.preventDefault();
        $('.showmsg').addClass("d-none");
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        var genderID = $('select#genderDDL option:selected').val();
        var CityTownID = $('select#selectCityTown option:selected').val();
        var postionTitleID = $('select#selectPostionTitle option:selected').val();
        var role_id = $('select#selectRole option:selected').val();
        var employmentTypeID = $('select#selectEmploymentType option:selected').val();
        var error = new Array();
        var maskAlpha = /^[a-zA-Z ]+$/;
        var mask5 = /\S+@\S+\.\S+/;

        if (fname == '') {
            $('#fname_msg').html('');
            $('#fname').addClass('invalid');
            $('#fname_msg').html('Please enter first name');
            $('#fname_msg').removeClass("d-none");
            error.push('true');
        } else if (!maskAlpha.test(fname)) {
            $('#fname_msg').html('');
            $('#fname').addClass('invalid');
            $('#fname_msg').html('First name field can only have alphabetical characters');
            $('#fname_msg').removeClass("d-none");
            error.push('true');
        }
        if (lname == '') {
            $('#lname_msg').html('');
            $('#lname').addClass('invalid');
            $('#lname_msg').html('Please enter last name');
            $('#lname_msg').removeClass("d-none");
            error.push('true');
        } else if (!maskAlpha.test(lname)) {
            $('#lname_msg').html('');
            $('#lname').addClass('invalid');
            $('#lname_msg').html('Last name field can only have alphabetical characters');
            $('#lname_msg').removeClass("d-none");
            error.push('true');
        }
        if (TIN == '') {
            $('#tin_msg').html('');
            $('#tin').addClass('invalid');
            $('#tin_msg').html('Please enter TIN number');
            $('#tin_msg').removeClass("d-none");
            error.push('true');
        } else if (TIN.length !== 9) {
            $('#tin_msg').html('');
            $('#tin').addClass('invalid');
            $('#tin_msg').html('TIN number should be of 9 digits');
            $('#tin_msg').removeClass("d-none");
            error.push('true');
        }
        if (FNPF == '') {
            $('#fnpf_msg').html('');
            $('#fnpf').addClass('invalid');
            $('#fnpf_msg').html('Please enter FNPF details');
            $('#fnpf_msg').removeClass("d-none");
            error.push('true');
        }
        //  else if (FNPF.length !== 9) {
        //     $('#tin_msg').html('');
        //     $('#tin').addClass('invalid');
        //     $('#tin_msg').html('TIN number should be of 9 digits');
        //     $('#tin_msg').removeClass("d-none");
        //     error.push('true');
        // }
        if (genderID == '-1') {
            $('#genderDDL_msg').html('');
            $('#genderID').addClass('invalid');
            $('#genderDDL_msg').html('Please select a gender');
            $('#genderDDL_msg').removeClass("d-none");
            error.push('true');
        }
        if (dateOfBirth == '') {
            $('#dateOfBirth_msg').html('');
            $('#dateOfBirth').addClass('invalid');
            $('#dateOfBirth_msg').html('Please select date');
            $('#dateOfBirth_msg').removeClass("d-none");
            error.push('true');
        }
        if (password == '') {
            $('#password_msg').html('');
            $('#password').addClass('invalid');
            $('#password_msg').html('Please enter password');
            $('#password_msg').removeClass("d-none");
            error.push('true');
        } else if (password.length < 4) {
            $('#password_msg').html('');
            $('#password').addClass('invalid');
            $('#password_msg').html('Password too short');
            $('#password_msg').removeClass("d-none");
            error.push('true');
        }
        if (streetAddress == '') {
            $('#streetAddress_msg').html('');
            $('#streetAddress').addClass('invalid');
            $('#streetAddress_msg').html('Please enter street address');
            $('#streetAddress_msg').removeClass("d-none");
            error.push('true');
        }
        if (streetAddressLine2 == '') {
            $('#setStreetAddressLine2_msg').html('');
            $('#streetAddressLine2').addClass('invalid');
            $('#setStreetAddressLine2_msg').html('Please enter street address line 2');
            $('#setStreetAddressLine2_msg').removeClass("d-none");
            error.push('true');
        }
        if (CityTownID == '-1') {
            $('#city_msg').html('');
            $('#selectCityTown').addClass('invalid');
            $('#city_msg').html('Please select a city/town');
            $('#city_msg').removeClass("d-none");
            error.push('true');
        }
        if (phone1 == '') {
            $('#phone1_msg').html('');
            $('#phone1').addClass('invalid');
            $('#phone1_msg').html('Enter phone no');
            $('#phone1_msg').removeClass("d-none");
            error.push('true');
        }
        // else if (validate_phone2() == false) {
        //     $('#phone1_msg').html('');
        //     $('#phone1').addClass('invalid');
        //     $('#phone1_msg').append('Invalid number. Please try again');
        //     error.push('true');
        // }
        if (email == '') {
            $('#email_msg').html('');
            $('#email').addClass('invalid');
            $('#email_msg').append('The email field is required');
            $('#email_msg').removeClass("d-none");
            error.push('true');
        } else if (!mask5.test(email)) {
            $('#email_msg').html('');
            $('#email').addClass('invalid');
            $('#email_msg').append('The email field contains invalid email address');
            $('#email_msg').removeClass("d-none");

            error.push('true');
        }

        // $(document).ready(function () {
        // var phoneInput2 = 0;
        // const phoneInputField2 = document.querySelector("#phone1");
        // phoneInput2 = window.intlTelInput(phoneInputField2, {
        //     initialCountry: "fj",
        //     preferredCountries: ["fj", "nz", "au", "us", "gb"],
        //     utilsScript:
        //         "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        // });
        // function validate_phone2() {
        //     $("#phone1").empty();
        //     var phone2 = $("#phone1").val();
        //     if ((phone2 == '') || !phoneInput2.isValidNumber()) {
        //         //phone validation here
        //         $("#phone1_msg").html("Enter valid phone No.");
        //         $("#phone1_msg").css("display", "block");
        //         return false;
        //     }
        //     else {
        //         $("#phone1_msg").css("display", "none");
        //     }
        //     return true;
        // }
        // $("#phone1").focusout(function () {
        //     validate_phone2();
        // });
        // });
        if (emergencyContact == '') {
            $('#emergencyContact_msg').html('');
            $('#emergencyContact').addClass('invalid');
            $('#emergencyContact_msg').html('Enter emergency phone no');
            $('#emergencyContact_msg').removeClass("d-none");
            error.push('true');
        }
        if (emergencyName == '') {
            $('#emergencyName_msg').html('');
            $('#fname').addClass('invalid');
            $('#emergencyName_msg').html('Please enter emergency contact name');
            $('#emergencyName_msg').removeClass("d-none");
            error.push('true');
        } else if (!maskAlpha.test(emergencyName)) {
            $('#emergencyName_msg').html('');
            $('#fname').addClass('invalid');
            $('#emergencyName_msg').html('This field can only have alphabetical characters');
            $('#emergencyName_msg').removeClass("d-none");
            error.push('true');
        }
        if (postionTitleID == '-1') {
            $('#positionTitle_msg').html('');
            $('#selectPostionTitle').addClass('invalid');
            $('#positionTitle_msg').html('Please select a position title');
            $('#positionTitle_msg').removeClass("d-none");
            error.push('true');
        }
        if (role_id == '-1') {
            $('#reportsTo_msg').html('');
            $('#selectRole').addClass('invalid');
            $('#reportsTo_msg').html('Please select a position title');
            $('#reportsTo_msg').removeClass("d-none");
            error.push('true');
        }
        if (employmentTypeID == '-1') {
            $('#employmentType_msg').html('');
            $('#selectEmploymentType').addClass('invalid');
            $('#employmentType_msg').html('Please select an employment type');
            $('#employmentType_msg').removeClass("d-none");
            error.push('true');
        }
        if (startDate == '') {
            $('#startDate_msg').html('');
            $('#startDate').addClass('invalid');
            $('#startDate_msg').html('Please select date');
            $('#startDate_msg').removeClass("d-none");
            error.push('true');
        }

        var exist = !$.inArray("true", error);
        if (exist == false) {
            axios({
                method: "POST",
                url: "staffmanagement/addStaff",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {
                    first_name: fname,
                    last_name: lname,
                    email: email,
                    dob: dateOfBirth,
                    street_address: streetAddress,
                    street_address_line2: streetAddressLine2,
                    city: parseInt(CityTownID),
                    tin: TIN,
                    fnpf: FNPF,
                    phone1: phone1,
                    emergency_contact_name: emergencyName,
                    emergency_contact_phone: emergencyContact,
                    // residential_address : residentialAddress,
                    gender: parseInt(genderID),
                    role_id: parseInt(role_id),
                    employment_type: parseInt(employmentTypeID),
                    position_title: parseInt(postionTitleID),
                    start_date: startDate,
                    end_date: contractEndDate,
                    password: password,
                    // isActive: parseInt(selected),
                    // postal_address : postalAddress,
                    // other_names : otherNames,
                },
            })
                .then(async result => {
                    if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: result.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    } else if (result.data.code == 1) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Staff added successfully",
                            showConfirmButton: false,
                            timer: 1500,
                        })
                        //navigate to prev page
                        navigate("/staff")
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
        return false;
    }

    const expandDropdown = e => {
        expanded = expanded == true ? false : true;
        if (expanded) {
            $(".dropdown-menu").removeClass("hideDDL");
            $(".dropdown-menu").addClass("showDDL");
        } else {
            $(".dropdown-menu").addClass("hideDDL");
            $(".dropdown-menu").removeClass("showDDL");
        }
    };

    let goback = e => {
        e.preventDefault();
        navigate("/staff")
    }

    return (
        <div className="staffDetailsForm_div px-3">
            <Navbar />
            <div className="">
                <h2>Employee Details</h2>
                <h6>Please fill out new employee information below.</h6>
                <form id="staffDetailsForm">
                    <h5 className="mt-4 fw-bold">Personal Information</h5>
                    <div className="row mt-4">
                        <label htmlFor="name" className="form-label align-self-end mb-0">Name</label>
                        {/* First Name */}
                        <div className="form-group col-md-6 pb-0">
                            <div className="input-group">
                                <input placeholder="First Name" type="text" className="form-control" id="fname" name="fname" value={fname} onChange={(e) => setfname(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            {/* <label htmlFor="name" className="form-label align-self-end mt-2 text-secondary greyLabelCls mb-0">First Name&nbsp;</label> */}
                            <small id="fname_msg" className="showmsg"></small>
                        </div>
                        {/* Last name */}
                        <div className="form-group col-md-6  pb-0">
                            <div className="input-group">
                                <input type="text" placeholder="Last Name" className="form-control" id="lname" name="lname" value={lname} onChange={(e) => setlname(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            {/* <label htmlFor="name" className="form-label align-self-end mt-2 text-secondary greyLabelCls mb-0">Last Name&nbsp;</label> */}
                            <small id="lname_msg" className="showmsg"></small>
                        </div>
                        {/* Select Gender */}
                        <div className="form-group dropdown col-md-4">
                            <label htmlFor="genderDDL" className="form-label align-self-end mb-0 pb-2">Gender</label>
                            <div className="input-group">
                                <select id="genderDDL" className="form-select" aria-label="Gender list DDl">
                                    <option hidden disabled value="-1" selected>Select Gender</option>
                                    {
                                        genderList.map((list, key) =>
                                            <option key={key} className="dropdown-item" value={list.id}>{list.name}</option>
                                        )
                                    }
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="genderDDL_msg" class="showmsg"></small>
                        </div>
                        {/* TIN */}
                        <div className="form-group col-md-4">
                            <label htmlFor="tin" className="form-label align-self-end mb-0  pb-2">TIN</label>
                            <div className="input-group">
                                <input type="number" min="0" className="form-control" id="tin" name="tin" value={TIN} onChange={(e) => setTIN(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="tin_msg" className="showmsg"></small>
                        </div>
                        {/* FNPF */}
                        <div className="form-group col-md-4">
                            <label htmlFor="fnpf" className="form-label align-self-end mb-0  pb-2">FNPF</label>
                            <div className="input-group">
                                <input type="text" min="0" className="form-control" id="fnpf" name="fnpf" value={FNPF} onChange={(e) => setFNPF(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="fnpf_msg" className="showmsg"></small>
                        </div>
                        {/* Date of Birth */}
                        {/* <div className="row"> */}
                        <div className="form-group col-md-6">
                            <label htmlFor="dateOfBirth" className="form-label align-self-end mb-0">Birth Date&nbsp;</label>
                            <div className="input-group">
                                <input type="date" className="form-control" id="dateOfBirth" placeholder="Date of Birth" name="dateOfBirth" value={dateOfBirth} onChange={(e) => setdateOfBirth(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="dateOfBirth_msg" className="showmsg"></small>
                        </div>
                        {/* Password */}
                        <div className="form-group col-md-6">
                            <label htmlFor="password" className="form-label align-self-end mb-0">Password&nbsp;</label>
                            <div className="input-group">
                                <input type="password" className="form-control" id="password" name="password" value={password} onChange={(e) => setpassword(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="password_msg" className="showmsg"></small>
                        </div>
                        {/* </div> */}
                        {/* Address - Street Address*/}
                        <label className="form-label align-self-end mb-0 mt-2">Address</label>
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <input placeholder="Street Address" className="form-control" id="streetAddress" name="streetAddress" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            {/* <label htmlFor="streetAddress" className="form-label align-self-end mt-2 mb-0 text-secondary greyLabelCls">Street Address&nbsp;</label> */}
                            <small id="streetAddress_msg" className="showmsg"></small>
                        </div>
                        {/* Street Address Line 2 */}
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <input placeholder="Street Address Line 2 " className="form-control" id="streetAddressLine2" name="streetAddressLine2" value={streetAddressLine2} onChange={(e) => setStreetAddressLine2(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            {/* <label htmlFor="streetAddressLine2" className="form-label align-self-end mt-2 mb-0 text-secondary greyLabelCls">Street Address Line 2&nbsp;</label> */}
                            <small id="setStreetAddressLine2_msg" className="showmsg"></small>
                        </div>
                        {/* City */}
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                {/* <input className="form-control" id="city" name="city" value={city} onChange={(e) => setCity(e.target.value)}/> */}
                                {/* Get City here */}
                                <select id="selectCityTown" className="form-select" aria-label="RoleList list DDl">
                                    <option hidden disabled value="-1" selected>Select City/Town</option>
                                    {
                                        cityTownList.map((cityTown, key) =>
                                            <option key={key} className="dropdown-item" value={cityTown.id}>{cityTown.name}</option>
                                        )
                                    }
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            {/* <label htmlFor="city" className="form-label align-self-end mt-2 mb-0 text-secondary greyLabelCls">City&nbsp;</label> */}
                            <small id="city_msg" className="showmsg"></small>
                        </div>
                        {/* Phone */}
                        <div className="form-group col-md-6">
                            <label className="form-label align-self-end">Phone Number&nbsp;</label>
                            <div className="input-group">
                                <input type="number" className="form-control" id="phone1" name="phone1" value={phone1} onChange={(e) => setphone1(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="phone1_msg" className="showmsg"></small>
                        </div>
                        {/* Email */}
                        <div className="form-group col-md-6">
                            <label className="form-label align-self-end">E-mail&nbsp;</label>
                            <div className="input-group">
                                <input type="email" className="form-control" id="email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="email_msg" className="showmsg"></small>
                        </div>
                        {/* Emergency Contact Name*/}
                        <div className="form-group col-md-6">
                            <label className="form-label align-self-end">Emergency Contact Name&nbsp;</label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="emergencyName" name="emergencyName" value={emergencyName} onChange={(e) => setEmergencyName(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="emergencyName_msg" className="showmsg"></small>
                        </div>
                        {/* Emergency Contact Phone Number*/}
                        <div className="form-group col-md-6">
                            <label className="form-label align-self-end">Emergency Contact Phone Number</label>
                            <div className="input-group">
                                <input type="number" className="form-control" id="emergencyContact" name="emergencyContact" value={emergencyContact} onChange={(e) => setEmergencyContact(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="emergencyContact_msg" className="showmsg"></small>
                        </div>
                        {/* Position Information */}
                        <h5 className="mt-4 fw-bold">Position Information</h5>
                        {/* Position Title*/}
                        <div className="form-group col-md-6">
                            <label className="form-label align-self-end">Position Title</label>
                            <div className="input-group">
                                {/* <input type="text" className="form-control" id="positionTitle" name="positionTitle" value={positionTitle} onChange={(e) => setPositionTitle(e.target.value)}/> */}
                                <select id="selectPostionTitle" className="form-select" aria-label="positionTitle DDL">
                                    <option hidden disabled value="-1" selected>Select Position</option>
                                    {
                                        postionList.map((position, key) =>
                                            <option key={key} className="dropdown-item" value={position.id}>{position.name}</option>
                                        )
                                    }
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="positionTitle_msg" className="showmsg"></small>
                        </div>
                        {/* Reports To*/}
                        <div className="form-group col-md-6">
                            <label className="form-label align-self-end">Reports To Deparment</label>
                            <div className="input-group">
                                {/* <input type="text" className="form-control" id="reportsTo" name="reportsTo" value={reportsTo} onChange={(e) => setReportsTo(e.target.value)}/> */}
                                <select id="selectRole" className="form-select" aria-label="RoleList list DDl">
                                    <option hidden disabled value="-1" selected>Select Department</option>
                                    {
                                        department.map((dep, key) =>
                                            <option key={key} className="dropdown-item" value={dep.id}>{dep.name}</option>
                                        )
                                    }
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="reportsTo_msg" className="showmsg"></small>
                        </div>
                        {/* Employment Type*/}
                        <div className="form-group col-md-6">
                            <label className="form-label align-self-end">Employment Type</label>
                            <div className="input-group">
                                {/* <input type="text" className="form-control" id="employmentType" name="employmentType" value={employmentType} onChange={(e) => setEmploymentType(e.target.value)}/> */}
                                <select id="selectEmploymentType" className="form-select" aria-label="Employment Type DDL">
                                    <option hidden disabled value="-1" selected>Please Select</option>
                                    {
                                        employmentTypeList.map((employmentType, key) =>
                                            <option key={key} className="dropdown-item" value={employmentType.id}>{employmentType.name}</option>
                                        )
                                    }
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                </div>
                            </div>
                            <small id="employmentType_msg" className="showmsg"></small>
                        </div>
                        {/* Start Date */}
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name" className="form-label align-self-end mt-2 ">Start Date&nbsp;</label>
                                <div className="input-group">
                                    <input type="date" className="form-control" id="startDate" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="startDate_msg" className="showmsg"></small>
                            </div>
                            {/* Contract End Date (if applicable) */}
                            <div className="form-group col-md-6">
                                <label htmlFor="name" className="form-label align-self-end mt-2">Contract End Date (if applicable)</label>
                                <div className="input-group">
                                    <input type="date" className="form-control" id="contractEndDate" name="contractEndDate" value={contractEndDate} onChange={(e) => setContractEndDate(e.target.value)} />
                                    <div className="input-group-append">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                                    </div>
                                </div>
                                <small id="contractEndDate_msg" className="showmsg"></small>
                            </div>
                        </div>
                        {/* Save Form Button */}
                        <div className="row">
                            <div className="col-3 my-3">
                                <button type="submit" className="btn btn-primary my-2 col-12" onClick={saveStaffDetailsForm}>Save</button>
                            </div>
                            <div className="col-3 my-3">
                                <button type="" className="btn btn-secondary my-2 col-12" onClick={goback}>Go Back</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}