import Logo from "../assets/logo-Horizontal.png";
import { Link, useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap'
import "../styles/Navbar.css";
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter, fa } from "@fortawesome/free-brands-svg-icons"
import React, { useEffect, useState } from 'react';
import { dividerClasses } from "@mui/material";
import $ from 'jquery';
import { Logout } from "@mui/icons-material";
import teamProfileImg from "../assets/img/OceanicLogo.png";

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);
  const toggleNavbar = () => { setOpenLinks(!openLinks); };
  const navigate = useNavigate();
  localStorage.getItem('token');
  // let token = JSON.parse(token);  

  let userInfo = localStorage.getItem('HrApiData');

  // console.log(userInfo);
  let userDetails = JSON.parse(userInfo);
  const [FirstName, setFirstName] = useState('');
  const [LastName, seLastName] = useState('');
  const [Email, setEmail] = useState('');
  const [isLoggedin, setIsLoggedin] = useState(false);


  useEffect(() => {
    // console.log(userDetails);
    // if (userDetails == undefined || userDetails == null) {
    //   navigate("/")
    // }
  }, [])

  let Logout = () => {
    setIsLoggedin(false);
    // window.localStorage.clear('HrApiData');
    window.localStorage.clear('HrApiData');
    localStorage.removeItem(userInfo);
    window.location.href = "/login";
  };

  if (userInfo != null) {
    // alert(userInfo);
    return (
      <div className="page-wrapper chiller-theme toggled">
        <a id="show-sidebar" className="btn btn-sm btn-dark" href="#">
          <i className="fas fa-bars"></i>
        </a>
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="sidebar-content">
            <div className="sidebar-brand my-3">
              <a href="#">OCL HR App</a>
              <div id="close-sidebar">
                <i className="fas fa-times"></i>
              </div>
            </div>
            <div className="sidebar-header">
              {/* <div className="user-pic">
                <img className="img-responsive img-rounded" src={teamProfileImg}></img>
              </div> */}
              <div className="user-info">
                <span className="user-name">
                  <strong>{userDetails.firstName} {userDetails.lastName} </strong>
                </span>
                <span className="user-role">{userDetails.email}</span>
              </div>
            </div>

            {/* <!-- sidebar-search  --> */}
            <div className="sidebar-menu">
              <ul className="mt-3 side-menu-ul">
                <li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/ts">TS</Link>
                </li>
                <li>
                  <Link to="/leaves">Leave Management</Link>
                </li>
                <li>
                  <Link to="/timesheet">Timesheet</Link>
                </li>
                <li>
                  <Link to="/staff">Staff</Link>
                </li>
                <li>
                  <Link to="/lookups">Lookups</Link>
                </li>
                <li>
                  <Link to="/usermanagement">User Management</Link>
                </li>
                <li>
                  <Link to="/permissions">Permissions</Link>
                </li>
                <li>
                  <Link to="/reminder">Reminder</Link>
                </li>
                <li className="sidebar-dropdown d-none">
                  <a href="#">
                    <i className="fa fa-tachometer-alt"></i>
                    <span>Profile</span>
                    <span className="badge badge-pill badge-warning">New</span>
                  </a>
                  <div className="sidebar-submenu">
                    <ul>
                      <li>
                        <a href="#">Dashboard 1
                          <span className="badge badge-pill badge-success">Pro</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">Dashboard 2</a>
                      </li>
                      <li>
                        <a href="#">Dashboard 3</a>
                      </li>

                    </ul>
                  </div>
                </li>
                <li>
                  <Link onClick={Logout}>Logout </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
  else {

  }
}

export default Navbar;


$(function () {

  $(".sidebar-dropdown > a").click(function () {
    $(".sidebar-submenu").slideUp(200);
    if (
      $(this)
        .parent()
        .hasClass("active")
    ) {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .parent()
        .removeClass("active");
    } else {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .next(".sidebar-submenu")
        .slideDown(200);
      $(this)
        .parent()
        .addClass("active");
    }
  });

  $("#close-sidebar").click(function () {
    $(".page-wrapper").removeClass("toggled");
  });
  $("#show-sidebar").click(function () {
    $(".page-wrapper").addClass("toggled");
  });

});
