import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Padding } from "@mui/icons-material";
import { faBars, faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import $ from 'jquery';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import "../styles/Main.css";
import axios from "axios";
import Navbar from './../components/Navbar';


function AdminPermissions() {
    // const navigate = useNavigate();
    const [permissions, setPermissions] = useState([]);
    const [permissionName, setPermissionName] = useState('');
    const [permissionDescription, setpermissionDescription] = useState('');
    const location = useLocation();
    let permissionslist = [];

    function checkBoxChecker() {
        let user = localStorage.getItem("HrApiData");
        user = JSON.parse(user);
        if (user) {
            axios({
                method: "GET",
                url: "permission/getallpermissions",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(result => {
                    permissionslist = result.data.permissions;
                    axios({
                        method: "POST",
                        url: "permission/getAllRolePermision",
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                        data: {
                            role_id: location.state.from,
                        },
                    })
                        .then(result => {
                            result.data.forEach((value, index) => {
                                $('#permChecked_' + value).prop('checked', true);
                            });
                            $("#allAdminPmermissionsTable").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            // this.$router.push({name: 'login'});
            // navigate to login page
        }
    }

    function mapPermission(e, permission_id) {
        // e.preventDefault();
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);

        axios({
            method: "POST",
            url: "permission/updaterolepermision",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                permission_id: permission_id,
                role_id: location.state.from,
            },
        })
            .then(result => {
                if (result.data == 2) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    useEffect(() => {
        // alert('reloaded');
        // alert(location.state.from2, 'values-LOCATION');

        const fetchata = async () => {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "GET",
                url: "permission/getallpermissions",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
            })
                .then(async result => {
                    $("#allAdminPermissionsTable").DataTable().destroy();
                    setPermissions(result.data.permissions);
                    setTimeout(() => {
                        $("#allAdminPermissionsTable").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 50,
                            "order": [
                                [0, "asc"]
                            ]
                        });
                    });
                    checkBoxChecker();
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        }
        fetchata();
    }, []);

    const savePermission = e => {
        e.preventDefault()
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        axios({
            method: "POST",
            url: "permission/addpermission",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                permission_name: permissionName,
                description: permissionDescription,
                createdBy: user.id
            },
        })
            .then(result => {
                if (result.data.msg.type == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else if (result.data.msg.type == 3) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Please Enter Permission Name',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else if (result.data.msg.type == 2) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'This Permission already exists!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else if (result.data.msg.type == 1) {
                    $("#allAdminPermissionsTable").DataTable().destroy();
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Permissions Added Successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setPermissions(result.data.msg.data);
                    $("#allAdminPermissionsTable").DataTable({
                        lengthMenu: [
                            [5, 10, 25, 50, -1],
                            [5, 10, 25, 50, "All"],
                        ],
                        pageLength: 10,
                        "order": [
                            [0, "asc"]
                        ]
                    });
                }
                else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin 1.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin 2.',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    return (
        <div className="px-3">
            <Navbar />
            <div className="adminPermissionsDIv mb-3">
                <h1 className="text-capitalize">Role Name: {location.state.from2}</h1>
            </div>
            <div className="datatableDiv" >
                <table id="allAdminPermissionsTable">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Permission Name</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            permissions.map((permission, key) =>
                                <tr key={key}>
                                    <td className='table-data'>{permission.id}</td>
                                    <td className='table-data'>{permission.permission_name}</td>
                                    <td className='table-data'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id={`permChecked_${permission.id}`} onClick={(e) => mapPermission(e, permission.id)} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdminPermissions;