import Pepperoni from "../assets/pepperoni.jpg";
import Margherita from "../assets/margherita.jpg";
import PedroTechSpecial from "../assets/pedrotechspecial.jpg";
import Vegan from "../assets/vegan.jpg";
import Pineapple from "../assets/pineapple.jpg";
import Expensive from "../assets/expensive.jpg";

export const MenuList = [
  {
    id:1,
    name: "User 1",
    image: Pepperoni,
    role: "Web Developer",
    department: "Development",
    email: "user1@oceanic.com.fj",
    phone: 8765432,
    link: '/profile?id=1',
  },
  {
    id:"2",
    name: "User 2",
    image: Margherita,
    role: "Web Developer",
    department: "Development",
    email: "user2@oceanic.com.fj",
    phone: 8765432,
    link: '/profile?id=2',
  },
  {
    id:"3",
    name: "User 3",
    image: Vegan,
    role: "Web Developer",
    department: "Development",
    email: "user3@oceanic.com.fj",
    phone: 8765432,
    link: '/profile?id=3',
  },
];