import React from 'react'
import BannerImage from "../assets/pizza.jpeg";
import Profile from "../pages/Profile";
import { Link } from "react-router-dom";

function MenuItem({image, name, role, department, email, phone, link, id}) {
  return (
      <Link to={link} className='text-decoration-none' target='_self'>
    
        </Link>
  )
}

export default MenuItem


