import React from "react";
import "../styles/Main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import $ from 'jquery';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import axios, { Axios } from "axios";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { faLink, faPencil, faTrash, faArrowRight, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import Navbar from './../components/Navbar';
import { SpaRounded } from "@mui/icons-material";

function Lookups() {
    let [name, setName] = useState('');
    let [description, setdescription] = useState('');
    let [lookupsDetails, setlookupsDetails] = useState([]);
    let [editRowID, seteditRowID] = useState('');
    let [NonActiveLookupsDetails, setNonActiveLookupsDetails] = useState([]);
    let [nonActive, SetnonActive] = useState(false);
    let [permisionListUX, SetpermisionListUX] = useState([]);
    const [openForm, setopenForm] = useState(false);

    useEffect(() => {
        const fetchata = async () => {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "POST",
                url: "permission/getUserPermissions",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(result => {
                    SetpermisionListUX(result.data.data);
                    if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 2500,
                        })
                    }
                    else {
                        if (result.data.data.includes("canaddlookupsparent")) {
                            $("#btn_addLookups").addClass("d-block");
                        }

                        axios({
                            method: "GET",
                            url: "lookups/getActiveLookupsParent",
                            headers: {
                                Authorization: `Bearer ${user.token}`,
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            },
                            data: {},
                        })
                            .then(result => {
                                if (result.data.code == 99) {
                                    //Hide Add Staff Btn & Data table Header Fields
                                    //Show A Div with Message - User Not Permitted
                                    $(".accessDeniedMsg").css("display", "block");
                                    $("#btn_addStaff").css("display", "none");
                                    $(".staffProfileDiv").css("display", "none");
                                    $(".accessDeniedMsg h1").html("User Not Permitted");

                                    Swal.fire({
                                        position: 'top-end',
                                        icon: 'warning',
                                        title: 'Access Denied!',
                                        text: 'User does not have permission to access this page.',
                                        showConfirmButton: false,
                                        timer: 2500,
                                    })
                                } else {
                                    setlookupsDetails(result.data);
                                    // if (check == true) {
                                    setTimeout(() => {
                                        $("#lookups_parent_tbl").DataTable({
                                            lengthMenu: [
                                                [5, 10, 25, 50, -1],
                                                [5, 10, 25, 50, "All"],
                                            ],
                                            pageLength: 10,
                                        });
                                    });
                                }
                            })
                            .catch(err => {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'An error occured. Please contact Admin!',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            });
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        }
        fetchata();
    }, []);

    let lookupsSubmit = e => {
        e.preventDefault()
    }

    let addLookups = e => {
        e.preventDefault();

        if (openForm == false) {
            setopenForm(true);
            $("#addLookupsForm").css("display", "block");
            $(".saveLookupsDiv").css("display", "block");
            $(".updateLookupsDiv").css("display", "none");
            setName('');
            setdescription('');
        }
        else {
            setopenForm(false);
            $("#addLookupsForm").css("display", "none");
        }
    }

    let saveLookupsForm = e => {
        e.preventDefault();
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        axios({
            method: "POST",
            url: "lookups/addLookupsParent",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                name: name,
                description: description
            },
        })
            .then(result => {
                console.log(result, 'saved details for lookups parent List')
                if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else if (result.data.code == 3) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'This lookup already exists!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else if (result.data.code == 2) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Please Enter Lookup Name!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Lookup added successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    $("#addLookupsForm").css("display", "none");
                    setName('');
                    setdescription('');
                    $("#lookups_parent_tbl").DataTable().destroy();
                    setlookupsDetails(result.data.data);
                    setTimeout(() => {
                        $("#lookups_parent_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                }
                else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    function editOnClickLookups(e, lookups) {
        e.preventDefault();
        setopenForm(true);
        $("#addLookupsForm").css("display", "block");
        $(".saveLookupsDiv").css("display", "none");
        $(".updateLookupsDiv").css("display", "block");
        $("#lookupName").val("");
        $("#lookupDescription").val("");
        setName(lookups.name);
        setdescription(lookups.description);
        seteditRowID(lookups);
    }

    let updateLookupsForm = e => {
        e.preventDefault();
        let lookupName = $("#lookupName").val();
        if (lookupName.trim() == "") {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: "Please Enter Lookup Name.",
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            let listoflookupsParent = lookupsDetails;
            let lookup = listoflookupsParent.find(x => x.id === editRowID.id);
            if (!(lookup.name === name && lookup.description === description)) {
                let exists = listoflookupsParent.find(x => x.id != editRowID.id && x.name === name.trim());

                if (exists) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "This lookup already exists!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                } else {
                    let user_str = localStorage.getItem("HrApiData");
                    let user = JSON.parse(user_str);
                    // if (description == null) {
                    //     description = "";
                    // }
                    axios({
                        method: "POST",
                        url: "lookups/updateLookupsParent",
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        },
                        data: {
                            id: editRowID.id,
                            name: name,
                            description: description != null ? description.trim() : "",
                        },
                    })
                        .then(result => {
                            if (result.data.code == 99) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'Access Denied!',
                                    text: 'User does not have permission to access this page.',
                                    showConfirmButton: false,
                                    timer: 1500,
                                })
                            } else if (result.data.code == 3) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: "This lookup already exists!",
                                    showConfirmButton: false,
                                    timer: 1500,
                                })
                            } else if (result.data.code == 2) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: "Please Enter Lookup Name.",
                                    showConfirmButton: false,
                                    timer: 1500,
                                })
                            } else if (result.data.code == 1) {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: "Lookup updated successfully!",
                                    showConfirmButton: false,
                                    timer: 1500,
                                })
                                $("#addLookupsForm").css("display", "none");
                                setName('');
                                setdescription('');
                                $("#lookups_parent_tbl").DataTable().destroy();
                                setlookupsDetails(result.data.data);
                                setTimeout(() => {
                                    $("#lookups_parent_tbl").DataTable({
                                        lengthMenu: [
                                            [5, 10, 25, 50, -1],
                                            [5, 10, 25, 50, "All"],
                                        ],
                                        pageLength: 10,
                                    });
                                });
                            } else {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: "An error occured. Please contact Admin!",
                                    showConfirmButton: false,
                                    timer: 1500,
                                })
                            }
                        })
                        .catch(err => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'warning',
                                title: "An error occured. Please contact Admin!",
                                showConfirmButton: false,
                                timer: 1500,
                            })
                        });
                }
            }
        }
    }

    function deleteOnClickLookups(e, lookups) {
        e.preventDefault();
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        $("#addLookupsForm").css("display", "none");
        axios({
            method: "POST",
            url: "lookups/deleteLookupsParent",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                id: lookups.id,
            },
        })
            .then(result => {
                if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Lookup deleted successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    $("#lookups_parent_tbl").DataTable().destroy();
                    setlookupsDetails(result.data.data);
                    setTimeout(() => {
                        $("#lookups_parent_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });

                    $("#nonactive_lookups_parent_tbl").DataTable().destroy();
                    setNonActiveLookupsDetails(result.data.data2.lookupsinactive_list);
                    setTimeout(() => {
                        $("#nonactive_lookups_parent_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    }

    function checkednonchildActive(e) {
        if (!nonActive) {
            let user_str = localStorage.getItem("HrApiData");
            let user = JSON.parse(user_str);
            axios({
                method: "GET",
                url: "lookups/getNonActiveLookupsParent",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(result => {
                    // console.log(result.data.permissionList[0], 'Lookups Parent');
                    SetpermisionListUX(result.data.permissionList[0]);

                    if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    } else {
                        $(".noneActiveDataTableDiv").css('display', 'block');
                        $("#nonactive_lookups_parent_tbl").DataTable().destroy();
                        setNonActiveLookupsDetails(result.data.lookupsinactive_list);
                        setTimeout(() => {
                            $("#nonactive_lookups_parent_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "An error occured. Please contact Admin!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
            SetnonActive(true);
        } else {
            SetnonActive(false);
            $(".noneActiveDataTableDiv").css('display', 'none');
        }
    }

    function activateOnClickCheck(e, lookups) {
        let user_str = localStorage.getItem("HrApiData");
        let user = JSON.parse(user_str);
        axios({
            method: "POST",
            url: "lookups/activateLookupsParent",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                id: lookups.id,
            },
        })
            .then(result => {
                if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                } else if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Lookup activated successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    $("#lookups_parent_tbl").DataTable().destroy();
                    setlookupsDetails(result.data.data);
                    setTimeout(() => {
                        $("#lookups_parent_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                    $("#nonactive_lookups_parent_tbl").DataTable().destroy();
                    setNonActiveLookupsDetails(result.data.data2.lookupsinactive_list);
                    setTimeout(() => {
                        $("#nonactive_lookups_parent_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "An error occured. Please contact Admin!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: "An error occured. Please contact Admin!",
                    showConfirmButton: false,
                    timer: 1500,
                })
            });
    }

    return (
        <div className="lookupsDiv px-3">
            <Navbar />
            <div className="accessDeniedMsg pt-5 mt-5 text-center">
                <h1></h1>
            </div>
            <div className="staffProfileDiv">
                <h1>Lookups Parent List</h1>
                <button type="button" className="btn btn-secondary bg-success" id="btn_addLookups" onClick={addLookups}>
                    <FontAwesomeIcon title="Edit Lookups" className="text-white" icon={faPlus} /> &nbsp;&nbsp;Add Lookup
                </button>
                <form id="addLookupsForm" onSubmit={lookupsSubmit}>
                    <div className="row">
                        {/* Name */}
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <input type="text" className="form-control" id="lookupName" placeholder="Name" name="lookupName" value={name} onChange={(e) => setName(e.target.value)} />
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="lookupName_msg" className="showmsg"></small>
                        </div>
                        {/* Description*/}
                        <div className="form-group col-md-12">
                            <div className="input-group">
                                <textarea type="text" rows="3" className="form-control" id="lookupDescription" placeholder="Description" name="lookupDescription" value={description} onChange={(e) => setdescription(e.target.value)} />
                                <div className="input-group-append">
                                    {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                                </div>
                            </div>
                            <small id="lookupDescription_msg" className="showmsg"></small>
                        </div>
                        <div className="col-1 saveLookupsDiv">
                            <button type="submit" className="btn btn-primary my-2 col-12" id="btn_saveLookups" onClick={saveLookupsForm}>Save</button>
                        </div>
                        <div className="col-1 updateLookupsDiv">
                            <button type="submit" className="btn btn-warning my-2 col-12" id="btn_updateLookups" onClick={updateLookupsForm}>Update</button>
                        </div>
                        <div className="col-1">
                            <button type="submit" className="btn btn-primary my-2 col-12" id="btn_cancelLookups" onClick={addLookups}> Cancel</button>
                        </div>
                    </div>
                </form>
                <div className="dataTableDiv mt-3">
                    <div className="datatableDiv" >
                        <table id="lookups_parent_tbl">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lookupsDetails.map((lookups, key) =>
                                        <tr key={key}>
                                            <td className='table-data'>{lookups.name}</td>
                                            <td className='table-data'>{lookups.description}</td>
                                            <td className='table-data text-center'>

                                                {permisionListUX.includes("caneditlookupsparent") ? (
                                                    <FontAwesomeIcon title="Edit Lookup" className="text-primary px-3" icon={faPencil} onClick={(e) => editOnClickLookups(e, lookups)} />
                                                ) : (
                                                    <span></span>
                                                )}
                                                {permisionListUX.includes("canviewlookupschild") ? (
                                                    <Link to="/lookupschild" state={{ x: lookups }} className="text-dark">
                                                        <FontAwesomeIcon title="Open Lookup" className="text-success px-3" icon={faArrowRight} />
                                                    </Link>
                                                ) : (
                                                    <span></span>
                                                )}
                                                {permisionListUX.includes("deletelookupsparent") ? (
                                                    <FontAwesomeIcon title="Inactivate Lookup" className="text-danger px-3" icon={faTrash} onClick={(e) => deleteOnClickLookups(e, lookups)} />
                                                ) : (
                                                    <span></span>
                                                )}

                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <br></br>
                <label htmlFor="checkednonchildActive">View Non Active Lookups</label>&nbsp;&nbsp;
                <input className="checkbox_input" name="checkednonchildActive" type="checkbox" onClick={(e) => checkednonchildActive(e)} />
            </div>
            <div className="noneActiveDataTableDiv mt-3">
                <table id="nonactive_lookups_parent_tbl">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col" className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            NonActiveLookupsDetails.map((lookups, key) =>
                                <tr key={key}>
                                    <td className='table-data'>{lookups.name}</td>
                                    <td className='table-data'>{lookups.description}</td>
                                    <td className='table-data text-center'>
                                        {/* <FontAwesomeIcon title="Activate Lookups Parent" className="text-dark" icon={faCheck} onClick={(e) => activateOnClickCheck(e, lookups)} /> */}

                                        {permisionListUX.includes("canactivatelookupsparent") ? (
                                            <FontAwesomeIcon title="Activate Lookups Parent" className="text-dark" icon={faCheck} onClick={(e) => activateOnClickCheck(e, lookups)} />

                                        ) : (
                                            <span></span>
                                        )}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default Lookups;