import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";

function VerifyEmail() {
  const navigate = useNavigate();
  let response = "";

  useEffect(() => {
    const fetchata = async () => {
      let user_str = localStorage.getItem("HrApiData");
      let user = JSON.parse(user_str);

      const queryParameters = new URLSearchParams(window.location.search)
      let paramID = queryParameters.get("id");
      // paramID = paramID.replace('?','');
      let arryval = paramID.split('?val=');
      let key = "";
      // alert(arryval[0]);
      // alert(arryval[1]);
      axios({
        method: "POST",
        url: "users/verify_email/" + arryval[0] + "/" + arryval[1],
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: {
          id: arryval[0],
          key: arryval[1],
        },
      })
        .then(result => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: 'success',
            title: 'Verification Successful!',
          });
          navigate("/login");

        })
        .catch(err => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: 'error',
            title: 'Login Unuccessful',
            text: 'Invalid username or passowrd! Please try again',
          });
        });
    }
    fetchata();
  }, []);

  function gotoHRApp(e) {
    e.preventDefault();
    navigate('/login')
  }

  return (
    <div className="verifyEmail appPadRight">
      <div className="mt-5">
        <h1>Email Verification Successful!</h1>
        <h2>Click on the <Link onClick={(e) => gotoHRApp(e)}>link</Link> to be redirected to OCL HR Application website.</h2>
      </div>
    </div>
  );
}

export default VerifyEmail;