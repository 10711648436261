import React from "react";
import "../styles/Profile.css";
import { ProfileList } from "../helpers/ProfileList";
import ProfileItem from "../components/ProfileItem";
import testImg from "../assets/Testimg.webp"


function Profile() {
  
    return (
      <div className="profile_div">
        <h1 className="profileTitle text-center">Employee Profile</h1> 
        <div className="row bg-secondary p-4">
          <div className=" col-4 profile_img" style={{ backgroundImage: `url(${testImg})` }}></div>
          <div className=" col-8 detail_div ps-5">

          {ProfileList.map((profileItem, key) => {
            return (
              <ProfileItem
                key={key}
                image={profileItem.image}
                name={profileItem.name}
                role={profileItem.role}
                department={profileItem.department}
                phone={profileItem.phone}
                email={profileItem.email}
                link={profileItem.link}
              />
            );
          })} 
          {/* 
            <h1>Name: </h1>
            <h2>Role: </h2>
            <h3>Department: </h3>
            <h3>Email: </h3>
            <h3>Phone: </h3> */}
          </div>

        </div>
      </div>
    );
  }
  export default Profile;