import React from "react";
import "../styles/Add_Staff.css";
import { Link, useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";
import $, { data } from 'jquery';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import "../styles/Main.css";
import axios from "axios";
import { faLink, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from './../components/Navbar';

function UserManagement() {
  const [userDetails, setUserDetails] = useState([]);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [roleName, setroleName] = useState('');
  let [email, setemail] = useState('');
  let [RowId, setRowId] = useState('');
  let [selected, setSelected] = useState('');
  let [roleID, setroleID] = useState();;
  let isActive = false;
  let [permisionListUX, SetpermisionListUX] = useState([]);
  let [roles, setRoles] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchata = async () => {
      let user_str = localStorage.getItem("HrApiData");
      let user = JSON.parse(user_str);
      axios({
        method: "GET",
        url: "users/getAll",
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: {},
      })
        .then(async result => {
          SetpermisionListUX(result.data.permissions);
          if (result.data.code == 99) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Access Denied! ',
              text: 'User does not have permission to access this page.',
              showConfirmButton: false,
              timer: 2500,
            })
          }
          else if (result.data.users.length > 0) {
            $("#allUsersTable").DataTable().destroy();
            setUserDetails(result.data.users);
            setRoles(result.data.roles);
            setTimeout(() => {
              $("#allUsersTable").DataTable({
                lengthMenu: [
                  [5, 10, 25, 50, -1],
                  [5, 10, 25, 50, "All"],
                ],
                pageLength: 10,
                "order": [
                  [0, "asc"]
                ]
              });
            });
            if (result.data.permissions.includes("canviewallroles")) {
              $('#viewAllRoleBtn').css('display', 'block')
            }
          }
          else {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'No records Found',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
        .catch(err => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: 'warning',
            title: 'An error occured. Please contact Admin!'
          });
        });
    }
    // Call the function
    fetchata();
  }, []);

  let userManagementSubmit = e => {
    e.preventDefault()
    $("#userManagementForm").css("display", "block");
  }

  let closeRoles = e => {
    e.preventDefault();
    $("#userManagementForm").css("display", "none");
  }

  const handleChange = e => {
    setSelected(e.target.value);
  };

  let updateRolesUsers = (e) => {
    e.preventDefault();
    let rowID = $("#btn_updateUserManagement").val();
    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);
    var roleID = $('#selectRole').find(":selected").val();


    axios({
      method: "POST",
      url: "users/updateuseradmin",
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
      data: {
        id: parseInt(rowID),
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: roleID,  //pass this role_id from the update button to the api -> on click edit get the data from selected row (role_id)
        isActive: parseInt(selected) == 1 ? true : false,
        updatedBy: user.id,
      },
    })
      .then(result => {
        $("#allUsersTable").DataTable().destroy();
        closeRoles(e);
        setUserDetails(result.data.msg.data);
        setTimeout(() => {
          $("#allUsersTable").DataTable({
            lengthMenu: [
              [5, 10, 25, 50, -1],
              [5, 10, 25, 50, "All"],
            ],
            pageLength: 10,
            "order": [
              [0, "asc"]
            ]
          });
        });
        if (result.status == 200 && result.data != null) {
          if (result.data.code == 99) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Access Denied!',
              text: 'User does not have permission to access this page.',
              showConfirmButton: false,
              timer: 1500
            })
          } else if (result.data.msg.type == 1) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'User updated successfully!',
              showConfirmButton: false,
              timer: 1500
            })
            // navigate("/usermanagement");

          } else if (result.data.msg.type == 2) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'This email already exists',
              showConfirmButton: false,
              timer: 1500
            })
            // this.toast.warning("This email already exists.");
          } else if (result.data.code == 99) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Access Denied!',
              text: 'User does not have permission to access this page.',
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'An error occured. Please contact Admin! 2',
              showConfirmButton: false,
              timer: 1500
            })
            // this.toast.warning("An error occured. Please contact Admin!");
          }
        }
      })
      .catch(err => {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'An error occured. Please contact Admin.1',
          showConfirmButton: false,
          timer: 1500
        })
      });
  }

  function editOnClick(e, user) {
    e.preventDefault();
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setemail(user.email);
    setroleName(user.role_name);
    setRowId(user.id);
    setroleID(user.role_id);

    $("#userManagementForm").css("display", "block");
    if (user.isActive == true) {
      setSelected(1);
      $("#isactive_yes").prop("checked", true);
    } else {
      setSelected(0);
      $("#isactive_no").prop("checked", true);
    }
  }

  return (
    <div className="userManagementList px-3">
      <Navbar />
      <div className="accessDeniedMsg pt-5 mt-5 text-center">
        <h1></h1>
      </div>
      <div className="userManagementDiv">
        <h1>User Management</h1>
        <div className="mb-3">
          <Link to="/addroles">
            <button className="btn btn-secondary bg-success" id="viewAllRoleBtn">View All Roles</button>
          </Link>
        </div>
        <form id="userManagementForm" onSubmit={userManagementSubmit} className="mb-3">
          <div className="row">
            <div className="form-group col-md-6">
              {/* First Name */}
              <label htmlFor="firstName" className="form-group px-0">First Name</label>
              <div className="input-group">
                <input type="text" className="form-control" id="firstName" placeholder="" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <div className="input-group-append">
                  {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                </div>
              </div>
              <small id="email_msg" className="showmsg"></small>
            </div>
            <div className="form-group col-md-6">
              {/* Last Name */}
              <label htmlFor="lastName" className="form-group px-0">Last Name</label>
              <div className="input-group">
                <input type="text" className="form-control" id="lastName" placeholder="" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <div className="input-group-append">
                  {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                </div>
              </div>
              <small id="lastName_msg" className="showmsg"></small>
            </div>
            {/* Email */}
            <div className="form-group col-md-6">
              <label htmlFor="roleName" className="form-group px-0">Email</label>
              <div className="input-group">
                <input type="text" className="form-control" id="email" placeholder="" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                <div className="input-group-append">
                  {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
                </div>
              </div>
              <small id="email_msg" className="showmsg"></small>
            </div>
            {/* Role Name */}
            <div className="form-group col-md-6">
              <label className="form-label align-self-end">Reports To</label>
              <div className="input-group">
                <select id="selectRole" className="form-select" aria-label="RoleList list DDl" name="Role_DDL">

                  <option hidden disabled value={roleID} selected>{roleName}</option>
                  {
                    roles.map((rolename, key) =>
                      <option key={key} className="dropdown-item" value={rolename.id}>{rolename.role_name}</option>
                    )
                  }
                </select>
              </div>
              {/* <label htmlFor="roleName" className="form-group px-0">Role Name</label>
              <div className="input-group">
                <input type="text" className="form-control" id="roleName" placeholder="" name="roleName" value={roleName} onChange={(e) => setroleName(e.target.value)} />
                <div className="input-group-append"> */}
              {/* <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk}/></span> */}
              {/* </div> */}
              {/* </div> */}
              <small id="roleName_msg" className="showmsg"></small>
            </div>
            {/* Radio Button */}
            <div className="col-12 col-md-6">
              <label htmlFor="isactive" className="display_block">Active*</label>
              <div className="form-group d-flex px-0">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="isactive" id="isactive_yes" value="1" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="isactive_yes">
                    Yes
                  </label>&nbsp;&nbsp;
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="isactive" id="isactive_no" value="0" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="isactive_no">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-1 col-md-2">
              <button type="submit" className="btn btn-warning my-2 col-12" id="btn_updateUserManagement" value={RowId} onChange={(e) => setRowId(e.target.value)} onClick={updateRolesUsers}>Update</button>
            </div>
            <div className="col-1 col-md-2">
              <button type="submit" className="btn btn-primary my-2 col-12" id="btn_cancelLookups" onClick={closeRoles}> Cancel</button>
            </div>
          </div>
        </form>
      </div>

      <div className="datatableDiv">
        <table id="allUsersTable">
          <thead>
            <tr>
              {/* <th scope="col">User Role ID</th> */}
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Active</th>
              <th scope="col">Email Verified</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              userDetails.map((user, key) =>
                <tr key={key}>
                  {/* <td className='table-data'>{ user.role_id }</td> */}
                  <td className='table-data'>{user.firstName} {user.lastName}</td>
                  <td className='table-data'>{user.email}</td>
                  <td className='table-data'>{user.role_name}</td>
                  <td className='table-data'>{user.isActive ? 'Active' : 'Inactive'} </td>
                  <td className='table-data'>{user.emailconfirmed ? 'Active' : 'Inactive'} </td>
                  <td className='table-data'>

                    {permisionListUX.includes("caneditusermanagement") ? (
                      <FontAwesomeIcon title="Edit User" className="text-primary" icon={faPencil} onClick={(e) => editOnClick(e, user)} />
                    ) : (
                      <span></span>
                    )}
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserManagement;