import React from "react";
import { MenuList } from "../helpers/TeamProfileList";
import MenuItem from "../components/TeamProfileItem";
import "../styles/Staff.css";



function Menu() {
  
  return (
    <div className="menu">
      <h1 className="menuTitle">Our Team Profile</h1> 
      <div className="menuList"> 
        {MenuList.map((menuItem, key) => {
          return (
            <MenuItem
              key={key}
              image={menuItem.image}
              name={menuItem.name}
              role={menuItem.role}
              department={menuItem.department}
              phone={menuItem.phone}
              email={menuItem.email}
              link={menuItem.link}
            />
          );
        })} 
      </div>
    </div>
  );
}

export default Menu;