import React from "react";
import "../styles/TeamProfile.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../styles/Main.css";
import axios from "axios";
import {
  faLink,
  faPencil,
  faArrowRight,
  faCheck,
  faPlus,
  faAsterisk,
  faTrash,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, ChangeEvent } from "react";
import $ from "jquery";
import Swal from "sweetalert2";
import staffImg from "../assets/img/OceanicLogo.png";
import dayjs from "dayjs";
import { Start } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function Dashboard() {
  const navigate = useNavigate();

  //Open-clock API call
  const [apiResponse, setApiResponse] = useState("");
  const entries = apiResponse?.data;
  /*
  //Working API call
  // API call function
  const makeTimesheetAPICall = async () => {
    try {
      axios({
        method: "GET",
        url: "timesheet/makeAPICallController",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(async (result) => {});
    } catch (error) {
      console.log(error);
    }
  };
*/

  let [leaveDetails, setleaveDetails] = useState([]);
  let [permisionListUX, SetpermisionListUX] = useState([]);
  let user_str = localStorage.getItem("HrApiData");
  let user = JSON.parse(user_str);



  useEffect(() => {
    const fetchata = async () => {
      axios({
        method: "GET",
        url: "leavemanagement/getDashboardLeaveInfo",
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
        data: {},
      })
        .then(async (result) => {
          // console.log(result, "dashborad leaves");
          if (result.data.code == 1) {
            SetpermisionListUX(result.data.permissionList[0]);
            setleaveDetails(result.data.leavelist);
            setTimeout(() => {
              $("#leaves_tbl").DataTable({
                lengthMenu: [
                  [5, 10, 25, 50, -1],
                  [5, 10, 25, 50, "All"],
                ],
                pageLength: 10,
              });
            });
          } else if (result.data.code == 0) {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "No records Found",
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (result.data.code == 99) {
            //Hide Add Staff Btn & Data table Header Fields
            //Show A Div with Message - User Not Permitted
            $(".accessDeniedMsg").css("display", "block");
            $(".DashboardDiv").css("display", "none");
            $(".accessDeniedMsg h1").html("User Not Permitted");

            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Access Denied!",
              text: "User does not have permission to access this page.",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "An error occured. Please contact Admin",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    };
    fetchata();
  }, []);



  return (
    <div className="LeaveForm px-3">
      <Navbar />
      <div className="accessDeniedMsg pt-5 mt-5 text-center">
        <h1></h1>
      </div>
      <div className="DashboardDiv">
        <h1>Leave Dashboard </h1>
        {permisionListUX.includes("canapplyforleave") ? (
          <div className="text-start mb-3">
            <Link to="/leavemanagement">
              <button
                type="button"
                className="btn btn-secondary bg-success"
                id="btn_applyLeave"
              >
                <FontAwesomeIcon
                  title="Apply for Leave"
                  className="text-white"
                  icon={faPlus}
                />{" "}
                &nbsp;&nbsp;Apply For Leave
              </button>
            </Link>
          </div>
        ) : (
          <span></span>
        )}
      </div>
      <div className="dataTableDiv mt-3">
        <div className="datatableDiv">
          <table id="leaves_tbl">
            <thead>
              <tr>
                <th scope="col">Leave Type</th>
                <th scope="col">Total Applications</th>
                <th scope="col">Total Approved</th>
                <th scope="col">Total Declined</th>
                <th scope="col">Total Pending</th>
                {/* <th scope="col" className="text-center">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {leaveDetails.map((leaves, key) => (
                <tr key={key}>
                  <td className="table-data">{leaves.leaveType}</td>
                  <td className="table-data">{leaves.total_days}</td>
                  <td className="table-data">{leaves.total_approved}</td>
                  <td className="table-data">{leaves.total_declined}</td>
                  <td className="table-data">{leaves.total_pending}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
