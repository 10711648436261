import React from "react";
import "../styles/TeamProfile.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../components/Navbar';
import "../styles/Main.css";
import axios from "axios";
import { faLink, faPencil, faArrowRight, faCheck, faPlus, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, ChangeEvent } from "react";
import $ from 'jquery';
import Swal from 'sweetalert2';
import staffImg from '../assets/img/OceanicLogo.png';
import dayjs from "dayjs";
import { Start } from "@mui/icons-material";

function LeaveManagement() {
  const [leaveType, setLeaveType] = useState([]);
  const [name, setName] = useState([]);
  const [dateFilled, setDateFilled] = useState([]);
  const [comments, setComments] = useState([]);
  const [leaveStart, setLeaveStart] = useState([]);
  const [leaveEnd, setLeaveEnd] = useState([]);
  const [totalLeaveDays, setTotalLeaveDays] = useState([]);
  const [days, setDays] = useState();
  const navigate = useNavigate();
  const [formatdate, setformatdate] = useState([]);
  let user_str = localStorage.getItem("HrApiData");
  let user = JSON.parse(user_str);

  const [key, setkey] = useState();

  useEffect(() => {
    const fetchata = async () => {

      axios({
        method: "GET",
        url: "lookups/getAllLeaveType",
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: {},
      })
        .then(async result => {
          // console.log(result);
          if (result.data.code == 1) {
            var fname = user.firstName;
            var lname = user.lastName;
            var fullname = fname + ' ' + lname;
            setName(fullname);

            const today = new Date();
            const month = today.getMonth() + 1;
            const year = today.getFullYear();
            const date = today.getDate();
            const currentDate = month + "/" + date + "/" + year;
            setDateFilled(currentDate);
            setLeaveType(result.data.genderslist);
          }
          else if (result.data.code == 0) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'No records Found',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          else if (result.data.code == 99) {
            //Hide Add Staff Btn & Data table Header Fields
            //Show A Div with Message - User Not Permitted
            $(".accessDeniedMsg").css("display", "block");
            $(".LeaveFormDiv").css("display", "none");
            $(".accessDeniedMsg h1").html("User Not Permitted");

            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Access Denied!',
              text: 'User does not have permission to access this page.',
              showConfirmButton: false,
              timer: 2500,
            })
          }
        })
        .catch(err => {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'An error occured. Please contact Admin',
            showConfirmButton: false,
            timer: 1500,
          })
        });
    }
    // Call the function
    fetchata();
  }, []);

  let calculatedaydifference = (e) => {
    var days = daysdifference(leaveStart, leaveEnd);
    setTotalLeaveDays(days);

    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    var formatdate = year + '-' + month + '-' + date;
    setformatdate(formatdate);
  }

  // function daysdifference(firstDate, secondDate) {
  //   var startDay = new Date(firstDate);
  //   var endDay = new Date(secondDate);
  //   var millisBetween = startDay.getTime() - endDay.getTime();
  //   var days = millisBetween / (1000 * 3600 * 24);
  //   var daynum = Math.round(Math.abs(days));
  //   daynum = daynum + 1;
  //   setDays(daynum);
  //   return daynum;
  // }


  function daysdifference(firstDate, secondDate) {
    var startDay = new Date(firstDate);
    var endDay = new Date(secondDate);
    var millisBetween = startDay.getTime() - endDay.getTime();
    var days = millisBetween / (1000 * 3600 * 24);
    var daynum = Math.round(Math.abs(days));
    daynum = daynum + 1;
    setDays(daynum);
    return daynum;
  }


  const startDateKeyUp = (event) => {
    // console.log(event.key);
    if (leaveStart > leaveEnd) {
      $("#leavestart_msg").text('Date should be less than Leave End Date.');
      $(".saveLeaveBtnCls").prop("disabled", true);
    }
    else {
      $("#leavestart_msg").text('');
      $(".saveLeaveBtnCls").prop("disabled", false);
    }
    calculatedaydifference();
  }

  const endDateKeyUp = (e) => {
    // console.log(event.key);
    if (leaveEnd < leaveStart) {
      $("#leaveEnd_msg").text('Date should be more than Leave End Date.');
      $(".saveLeaveBtnCls").prop("disabled", true);
    }
    else {
      $("#leaveEnd_msg").text('');
      $(".saveLeaveBtnCls").prop("disabled", false);
    }
    calculatedaydifference();
  }

  let saveLeavesForm = (e) => {
    e.preventDefault();
    $('.showmsg').addClass("d-none");

    var leaveTypeDDL = $('select#leaveTypeDDL option:selected').val();
    var error = new Array();
    var maskAlpha = /^[a-zA-Z ]+$/;
    var mask5 = /\S+@\S+\.\S+/;


    // var days = daysdifference(leaveStart, leaveEnd);
    // setTotalLeaveDays(days);

    // const today = new Date();
    // const month = today.getMonth() + 1;
    // const year = today.getFullYear();
    // const date = today.getDate();
    // var formatdate = year + '-' + month + '-' + date;
    // setformatdate(formatdate);

    if (comments == '') {
      $('#comments_msg').html('');
      $('#comments').addClass('invalid');
      $('#comments_msg').html('Comment is required');
      $('#comments_msg').removeClass("d-none");
      error.push('true');
    }
    else if (comments.length < 15) {
      $('#comments_msg').html('');
      $('#comments').addClass('invalid');
      $('#comments_msg').html('Comment is too short');
      $('#comments_msg').removeClass("d-none");
      error.push('true');
    }
    if (leaveStart == '') {
      $('#leavestart_msg').html('');
      $('#leaveStart').addClass('invalid');
      $('#leavestart_msg').html('Please select start date');
      $('#leavestart_msg').removeClass("d-none");
      error.push('true');
    }
    if (leaveEnd == '') {
      $('#leaveEnd_msg').html('');
      $('#leaveEnd').addClass('invalid');
      $('#leaveEnd_msg').html('Please select end date');
      $('#leaveEnd_msg').removeClass("d-none");
      error.push('true');
    }
    if (leaveTypeDDL == '-1') {
      $('#leaveTypeDDL_msg').html('');
      $('#leaveTypeDDL').addClass('invalid');
      $('#leaveTypeDDL_msg').html('Please select a leave type');
      $('#leaveTypeDDL_msg').removeClass("d-none");
      error.push('true');
    }
    var exist = !$.inArray("true", error);
    // dateFilled = dateFilled;
    if (exist == false) {
      axios({
        method: "POST",
        url: "leavemanagement/addleaveapplication",
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: {
          // date_leave_applied: formatdate,
          leave_type_id: leaveTypeDDL,
          comments: comments,
          leave_start_date: leaveStart,
          leave_end_date: leaveEnd,
          total_leave_days: parseInt(days), // this should be empty after saving details
        },
      })
        .then(async result => {
          if (result.data.code == 0) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
          } else if (result.data.code == 1) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: "Leave form submitted successfully!",
              showConfirmButton: false,
              timer: 1500,
            })
            //navigate to prev page
            navigate("/dashboard");
          }
          else if (result.data.code == 99) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Access Denied! ',
              text: 'User does not have permission to access this page.',
              showConfirmButton: false,
              timer: 2500,
            })
          }
        })
        .catch(err => {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'An error occured. Please contact Admin!',
            showConfirmButton: false,
            timer: 1500,
          })
        });
    }
    return false;
  }

  const goback = (e) => {
    e.preventDefault();
    navigate("/dashboard")
  }

  return (
    <div className="LeaveForm px-3">
      <Navbar />
      <div className="accessDeniedMsg pt-5 mt-5 text-center">
        <h1></h1>
      </div>
      <div className="LeaveFormDiv">
        <h1>Leave Application Form</h1>
        <div className="row">
          {/* Applicant Name */}
          <div className="form-group col-md-4">
            <label htmlFor="name" className="form-label align-self-end">Name: &nbsp;</label>
            <div className="input-group">
              <input disabled type="text" className="form-control" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)}></input>
              <div className="input-group-append">
                <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
              </div>
            </div>
            <small id="name_msg" className="showmsg"></small>
          </div>
          {/* Date Filled */}
          <div className="form-group col-md-4">
            <label htmlFor="dateFilled" className="form-label align-self-end">Date Filled: &nbsp;</label>
            <div className="input-group">
              <input disabled type="text" className="form-control" id="dateFilled" name="dateFilled" value={dateFilled} onChange={(e) => setDateFilled(e.target.value)} />
              <div className="input-group-append">
                <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
              </div>
            </div>
            <small id="dateFilled_msg" className="showmsg"></small>
          </div>
          {/* Select Leave Type - Check box Start*/}
          {/* <label className="form-check-label mt-3">Select Leave Type:</label>
        <div className="row m-2">
          {
            // leaveType.map((list, key) =>
            //   <div className="col-4 form-check py-1">
            //     <input key={key} className="form-check-input" type="checkbox" value="" id={list.id}></input>
            //     <label className="form-check-label" htmlFor={list.id}>{list.name}</label>
            //   </div>
            // )

          }
        </div> */}
          {/* Select Leave Type - Check box End */}

          {/* leave type drop down list Start */}
          <div className="form-group dropdown col-md-4">
            <label htmlFor="dateFilled" className="form-label align-self-end">Leave Type &nbsp;</label>
            <div className="input-group">
              <select id="leaveTypeDDL" className="form-select" aria-label="Gender list DDl">
                <option hidden disabled value="-1" selected>Select Leave Type:</option>
                {
                  leaveType.map((list, key) =>
                    <option key={key} className="dropdown-item" value={list.id}>{list.name}</option>
                  )
                }
              </select>
              <div className="input-group-append">
                <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
              </div>
            </div>
            <small id="leaveTypeDDL_msg" className="showmsg"></small>

          </div>
          {/* leave type drop down list End */}

          {/* Comments */}
          <label htmlFor="comments" className="form-label align-self-end mb-0 mt-3">Comments:&nbsp;</label>
          <div className="form-group col-md-12">
            <div className="input-group">
              <textarea type="text" className="form-control" id="comments" placeholder="" name="comments" value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
              <div className="input-group-append">
                <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
              </div>
            </div>
            <small id="comments_msg" className="showmsg"></small>
          </div>
          {/* Leave Start Date */}
          <div className="col-md-4">
            <label htmlFor="leaveStart" className="form-label align-self-end mb-0 mt-3">Leave Start Date:&nbsp;</label>
            <div className="form-group ps-0">
              <div className="input-group">
                <input type="date" className="form-control" id="leaveStart" placeholder="" name="leaveStart" value={leaveStart} onChange={(e) => setLeaveStart(e.target.value)} onClick={startDateKeyUp}
                />
                <div className="input-group-append">
                  <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                </div>
              </div>
              <small id="leavestart_msg" className="showmsg"></small>
            </div>
          </div>
          {/* Leave End Date */}
          <div className="col-md-4">
            <label htmlFor="leaveEnd" className="form-label align-self-end mb-0 mt-3">Leave End Date:&nbsp;</label>
            <div className="form-group ps-0">
              <div className="input-group">
                {<input type="date" className="form-control" id="leaveEnd" placeholder="" name="leaveEnd" value={leaveEnd} onChange={(e) => setLeaveEnd(e.target.value)} onClick={endDateKeyUp} />}
                <div className="input-group-append">
                  <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                </div>
              </div>
              <small id="leaveEnd_msg" className="showmsg"></small>
            </div>
          </div>
          {/* Total Leave Days */}
          <div className="col-md-4">
            <label htmlFor="totalLeaveDays" className="form-label align-self-end mb-0 mt-3">Total Leave Day(s):&nbsp;</label>
            <div className="form-group ps-0">
              <div className="input-group">
                <input disabled type="text" className="form-control" id="totalLeaveDays" placeholder="" name="totalLeaveDays" value={totalLeaveDays} onChange={(e) => setTotalLeaveDays(e.target.value)} />
                <div className="input-group-append">
                  <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                </div>
              </div>
              <small id="totalLeaveDays_msg" className="showmsg"></small>
            </div>
          </div>
          {/* Approval Section */}
          {/* <label className="form-check-label mt-3">Approval</label> */}
          {/* Approval by Name */}
          {/* <div className="form-group col-md-6">
          <label htmlFor="name" className="form-label align-self-end">Name: &nbsp;</label>
          <div className="input-group">
            <input type="text" className="form-control" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)}></input>
            <div className="input-group-append">
              <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
            </div>
          </div>
          <small id="name_msg" className="showmsg"></small>
        </div> */}
          {/* Approval Date */}
          {/* <div className="form-group col-md-6">
          <label htmlFor="dateFilled" className="form-label align-self-end">Date Filled: &nbsp;</label>
          <div className="input-group">
            <input type="date" className="form-control" id="dateFilled" placeholder="Date Filled" name="dateFilled" value={dateFilled} onChange={(e) => setDateFilled(e.target.value)} />
            <div className="input-group-append">
              <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
            </div>
          </div>
          <small id="dateFilled_msg" className="showmsg"></small>
        </div> */}
        </div>

        {/* File Upload Field - Start */}
        {/* <div>
      <input type="file" onChange={handleFileChange} />
      <div>{file && `${file.name} - ${file.type}`}</div>
      <button onClick={handleUploadClick}>Upload</button>
    </div> */}
        {/* File Upload Field - End */}

        {/* Save Button */}
        <div className="row">
          <div className="col-2">
            <button type="submit" className="btn btn-primary my-2 col-12 saveLeaveBtnCls" onClick={saveLeavesForm}>Save</button>
          </div>
          <div className="col-2">
            <button type="submit" className="btn btn-secondary my-2 col-12" onClick={goback}>Go back</button>
          </div>
        </div>
      </div>
    </div>


  );
}

export default LeaveManagement;
