import React from "react";
import "../styles/TeamProfile.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../components/Navbar';
import "../styles/Main.css";
import axios from "axios";
import { faLink, faPencil, faArrowRight, faCheck, faPlus, faAsterisk, faTrash, faEye, faTimes, faGear, faGears, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, ChangeEvent } from "react";
import $ from 'jquery';
import Swal from 'sweetalert2';
import staffImg from '../assets/img/OceanicLogo.png';
import dayjs from "dayjs";
import { Start } from "@mui/icons-material";
import DataTable from 'react-data-table-component';
import moment from 'moment';

function Leave() {
    const navigate = useNavigate();
    let [leaveDetails, setleaveDetails] = useState([]);
    let [permisionListUX, SetpermisionListUX] = useState([]);
    let [roleDetails, setroleDetails] = useState([]);
    let [leaveID_list, setleaveID_list] = useState([]);
    let [annual_leave, setannual_leave] = useState([]);
    let [TIL_leave, setTIL_leave] = useState([]);
    let [unpaid_leave, setunpaid_leave] = useState([]);
    let [breavement_leave, setbreavement_leave] = useState([]);
    let [family_care_leave, setfamily_care_leave] = useState([]);
    let [sick_leave, setsick_leave] = useState([]);
    let [roleID, setroleID] = useState([]);
    //each row id for leave
    let [al_rowid, setal_rowid] = useState([]);
    let [bl_rowid, setbl_rowid] = useState([]);
    let [fc_rowid, setfc_rowid] = useState([]);
    let [sl_rowid, setsl_rowid] = useState([]);
    let [til_rowid, settil_rowid] = useState([]);
    let [upl_rowid, setupl_rowid] = useState([]);
    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);
    const [openForm, setopenForm] = useState(false);
    const date = moment().format("DD-MM-YYYY");
    const time = moment().format("HH mm ss");

    useEffect(() => {
        // console.log(moment().format());
        const fetchata = async () => {
            axios({
                method: "GET",
                url: "leavemanagement/getAllLeaveApplications",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(async result => {
                    // console.log(result, 'getAllLeaveApplications');
                    if (result.data.code == 1) {
                        SetpermisionListUX(result.data.permissionList[0]);
                        setleaveDetails(result.data.leavelist);
                        console.log(leaveDetails);


                        // if (check == true) {
                        setTimeout(() => {
                            $("#leaves_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });

                        // if (result.data.permissionList[0].includes("canviewleavesetting")) {
                        //     $("#btn_leaveSettings").removeClass("d-none");
                        //     $("#btn_leaveSettings").addClass("d-block");
                        // }
                        //Check this button
                    }
                    else if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'No records Found',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                    else if (result.data.code == 99) {
                        //Hide Add Staff Btn & Data table Header Fields
                        //Show A Div with Message - User Not Permitted
                        $(".accessDeniedMsg").css("display", "block");
                        $(".LeaveFormDiv").css("display", "none");
                        $(".accessDeniedMsg h1").html("User Not Permitted");

                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 2500,
                        })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
        fetchata();
    }, []);

    const setLeaveSetting = (e) => {
        e.preventDefault();

        if (openForm == false) {
            setopenForm(true);
            $('.leaveSettingDiv').removeClass("d-none");
            $('.leaveSettingDiv').addClass("d-block");
            axios({
                method: "GET",
                url: "leavemanagement/getLeaveSetting",

                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(async result => {
                    console.log(result, 'leave-roles');
                    if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: result.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    } else if (result.data.code == 1) {
                        $("#leavessettings_tbl").DataTable().destroy();
                        setroleDetails(result.data.rolelist);
                        setleaveID_list(result.data.leaveID_list);
                        setTimeout(() => {
                            $("#leavessettings_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });
                    }
                    else if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied! ',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 2500,
                        })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin!',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
        else {
            setopenForm(false);
            $('.leaveSettingDiv').removeClass("d-block");
            $('.leaveSettingDiv').addClass("d-none");
        }
    }

    let closeEditLeaveForRolesForm = e => {
        e.preventDefault()
        $("#editLeaveForRolesDiv").css("display", "none");
    }

    const editLeaveDetails = (e, x) => {
        e.preventDefault();
        $("#editLeaveForRolesDiv").css('display', 'block');
        setannual_leave(x.annual);
        setTIL_leave(x.lieu);
        setunpaid_leave(x.unpaid);
        setbreavement_leave(x.breavment);
        setsick_leave(x.sick);
        setfamily_care_leave(x.familycare);
        setroleID(x.role_id);
        //each row id for leave
        setal_rowid(x.AL_rowID);
        setbl_rowid(x.BL_rowID);
        setfc_rowid(x.FC_rowID);
        setsl_rowid(x.SL_rowID);
        settil_rowid(x.TIL_rowID);
        setupl_rowid(x.UPL_rowID);
    }

    const updateLeaveDetails = (e, data) => {
        e.preventDefault();
        var AL_ID = $('#AL_ID').attr('data-al-id');
        var BL_ID = $('#BL_ID').attr('data-bl-id');
        var FC_ID = $('#FC_ID').attr('data-fc-id');
        var SL_ID = $('#SL_ID').attr('data-sl-id');
        var TIL_ID = $('#TIL_ID').attr('data-til-id');
        var UPL_ID = $('#UPL_ID').attr('data-upl-id');

        var AL_rowID = al_rowid;
        var BL_rowID = bl_rowid;
        var FC_rowID = fc_rowid;
        var SL_rowID = sl_rowid;
        var TIL_rowID = til_rowid;
        var UPL_rowID = upl_rowid;

        var leaveTypeIDArry = [parseInt(AL_ID), parseInt(BL_ID), parseInt(FC_ID), parseInt(SL_ID), parseInt(TIL_ID), parseInt(UPL_ID)];
        var leavearrayvalues = [parseInt(annual_leave), parseInt(breavement_leave), parseInt(family_care_leave), parseInt(sick_leave), parseInt(TIL_leave), parseInt(unpaid_leave)];
        var leaverowIDs = [parseInt(AL_rowID), parseInt(BL_rowID), parseInt(FC_rowID), parseInt(SL_rowID), parseInt(TIL_rowID), parseInt(UPL_rowID)];

        axios({
            method: "POST",
            url: "leavemanagement/updateleaveroles",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },

            data: {
                role_id: roleID,
                leaveTypeIDArry: leaveTypeIDArry,
                leavearrayvalues: leavearrayvalues,
                leaverowIDs: leaverowIDs,
            },
        })
            .then(result => {
                if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                } else if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Leave details updated successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    $("#addLookupsForm").css("display", "none");
                    setannual_leave('');
                    setTIL_leave('');
                    setunpaid_leave('');
                    setbreavement_leave('');
                    setfamily_care_leave('');
                    setsick_leave('');

                    $("#leavessettings_tbl").DataTable().destroy();
                    setleaveDetails(result.data.data);
                    setroleDetails(result.data.leavesetting.rolelist);
                    setTimeout(() => {
                        $("#leavessettings_tbl").DataTable({
                            lengthMenu: [
                                [5, 10, 25, 50, -1],
                                [5, 10, 25, 50, "All"],
                            ],
                            pageLength: 10,
                        });
                    });
                    closeEditLeaveForRolesForm(e);
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: "An error occured. Please contact Admin!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: "An error occured. Please contact Admin!",
                    showConfirmButton: false,
                    timer: 1500,
                })
            });
    }

    return (
        <div className="LeaveForm px-3">
            <Navbar />
            <div className="accessDeniedMsg pt-5 mt-5 text-center">
                <h1></h1>
            </div>
            <div className="LeaveFormDiv ">
                {/* <h1> Staff Leave Applications</h1> */}
                <div className="row p-0 m-0 mb-3 d-none">
                    {permisionListUX.includes("canviewleavesetting") ? (
                        <button onClick={setLeaveSetting} type="button" className="btn btn-secondary bg-success col-2" id='btn_leaveSettings'>
                            <FontAwesomeIcon title="Apply for Leave" className="text-white" icon={faGears} /> &nbsp;&nbsp;Leave Settings
                        </button>) : (
                        <span></span>
                    )}
                </div>

                <div class="row mb-3 p-1 border border-dark" id="editLeaveForRolesDiv" >
                    <div className="col-12">
                        <form id="editLeaveForRolesForm" className="">
                            <div className="row">
                                {/* Annual Leave */}
                                <div className="form-group col-md-2 col-sm-6 col-12">
                                    <label htmlFor="annual_leave" className="form-label">Annual Leave</label>
                                    <div className="input-group">
                                        <input type="number" min="0" className="form-control" id="annual_leave" data-al-rowid={al_rowid} placeholder="" name="annual_leave" value={annual_leave} onChange={(e) => setannual_leave(e.target.value)} />
                                    </div>
                                    <small id="annual_leave_msg" className="showmsg"></small>
                                </div>
                                {/* Breavement Leave */}
                                <div className="form-group col-md-2 col-sm-6 col-12">
                                    <label htmlFor="breavement_leave" className="form-label">Breavement Leave</label>
                                    <div className="input-group">
                                        <input type="number" min="0" className="form-control" id="breavement_leave" data-bl-rowid={bl_rowid} placeholder="" name="breavement_leave" value={breavement_leave} onChange={(e) => setbreavement_leave(e.target.value)} />
                                    </div>
                                    <small id="breavement_leave_msg" className="showmsg"></small>
                                </div>
                                {/* Family Care Leave */}
                                <div className="form-group col-md-2 col-sm-6 col-12">
                                    <label htmlFor="family_care_leave" className="form-label">Family Care Leave</label>
                                    <div className="input-group">
                                        <input type="number" min="0" className="form-control" id="family_care_leave" data-fc-rowid={fc_rowid} placeholder="" name="family_care_leave" value={family_care_leave} onChange={(e) => setfamily_care_leave(e.target.value)} />
                                    </div>
                                    <small id="family_care_leave_msg" className="showmsg"></small>
                                </div>
                                {/* Sick Leave */}
                                <div className="form-group col-md-2 col-sm-6 col-12">
                                    <label htmlFor="sick_leave" className="form-label">Sick Leave</label>
                                    <div className="input-group">
                                        <input type="number" min="0" className="form-control" id="sick_leave" data-sl-rowid={sl_rowid} placeholder="" name="sick_leave" value={sick_leave} onChange={(e) => setsick_leave(e.target.value)} />
                                    </div>
                                    <small id="sick_leave_msg" className="showmsg"></small>
                                </div>
                                {/* Time in Lieu */}
                                <div className="form-group col-md-2 col-sm-6 col-12">
                                    <label htmlFor="TIL_leave" className="form-label">Time in Lieu</label>
                                    <div className="input-group">
                                        <input type="number" min="0" className="form-control" id="TIL_leave" data-til-rowid={til_rowid} placeholder="" name="TIL_leave" value={TIL_leave} onChange={(e) => setTIL_leave(e.target.value)} />
                                    </div>
                                    <small id="TIL_leave" className="showmsg"></small>
                                </div>
                                {/* Unpaid Leave */}
                                <div className="form-group col-md-2 col-sm-6 col-12">
                                    <label htmlFor="unpaid_leave" className="form-label">Unpaid Leave</label>
                                    <div className="input-group">
                                        <input type="number" min="0" className="form-control" id="unpaid_leave" data-upl-rowid={upl_rowid} placeholder="" name="unpaid_leave" value={unpaid_leave} onChange={(e) => setunpaid_leave(e.target.value)} />
                                    </div>
                                    <small id="unpaid_leave_msg" className="showmsg"></small>
                                </div>
                                {/* Update - Cancel Button */}
                                <div className="col-1 ">
                                    <button type="submit" className="btn btn-warning my-2 col-12" id="" onClick={updateLeaveDetails}>Update</button>
                                </div>
                                <div className="col-1">
                                    <button type="submit" className="btn btn-secondary my-2 col-12" id="" onClick={closeEditLeaveForRolesForm}> Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Leave Setting for each role */}
                <div className="dataTableDiv my-3 leaveSettingDiv">
                    <div className="datatableDiv" >
                        <table id="leavessettings_tbl">
                            <thead>
                                <tr>
                                    <th scope="col">Role</th>
                                    <th scope="col">Annual</th>
                                    <th scope="col">Breavement</th>
                                    <th scope="col">Family Care</th>
                                    <th scope="col">Sick</th>
                                    <th scope="col">Time in Lieu</th>
                                    <th scope="col">Unpaid</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    roleDetails.map((x, key) =>
                                        <tr key={key}>
                                            <td className='table-data' id="role_name">{x.role_name}</td>
                                            <td className='table-data' data-al-rowid={x.AL_rowID} id="AL_ID" data-al-id={leaveID_list.AL_ID}>{x.annual}</td>
                                            <td className='table-data' data-bl-rowid={x.BL_rowID} id="BL_ID" data-bl-id={leaveID_list.BL_ID}>{x.breavment}</td>
                                            <td className='table-data' data-fc-rowid={x.FC_rowID} id="FC_ID" data-fc-id={leaveID_list.FC_ID}>{x.familycare}</td>
                                            <td className='table-data' data-sl-rowid={x.SL_rowID} id="SL_ID" data-sl-id={leaveID_list.SL_ID}>{x.sick}</td>
                                            <td className='table-data' data-til-rowid={x.TIL_rowID} id="TIL_ID" data-til-id={leaveID_list.TIL_ID}>{x.lieu}</td>
                                            <td className='table-data' data-upl-rowid={x.UPL_rowID} id="UPL_ID" data-upl-id={leaveID_list.UPL_ID}>{x.unpaid}</td>
                                            <td className='table-data'>
                                                {permisionListUX.includes("caneditleavesetting") ? (
                                                    <Link to="/leavetally" state={{ x: x }} className="text-dark">
                                                        <FontAwesomeIcon title="View Leave Application" className="text-primary px-3" icon={faPencil} onClick={(e) => editLeaveDetails(e, x, leaveID_list)} />
                                                    </Link>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Leave Details for logged in user */}
                <h2>
                    Pending Leave Application for: {user.firstName} {user.lastName}
                </h2>

                <div className="dataTableDiv mt-0">
                    <div className="datatableDiv" >
                        <table id="leaves_tbl">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Leave Type</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Total Days</th>
                                    <th scope="col">Date Applied</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    leaveDetails.map((leaves, key) =>
                                        <tr key={key}>
                                            <td className='table-data'>{leaves.first_name} {leaves.last_name} </td>
                                            <td className='table-data'>{leaves.leavetypename}</td>
                                            <td className='table-data'>{moment(leaves.leave_start_date).format("DD-MM-YYYY")}</td>
                                            <td className='table-data'>{moment(leaves.leave_end_date).format("DD-MM-YYYY")}</td>
                                            <td className='table-data'>{leaves.total_leave_days}</td>
                                            <td className='table-data'>{moment(leaves.createdAt).format("DD-MM-YYYY")}</td>
                                            <td className='table-data text-center'>
                                                {permisionListUX.includes("canviewleavedetails") ? (
                                                    <Link to="/leavesinsidepage" state={{ x: leaves }} className="text-dark">
                                                        <FontAwesomeIcon title="View Leave Application" className="text-primary px-3" icon={faEye} />
                                                    </Link>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Leave;
