import React, { useState } from "react";
import axios from "axios";

const FileUpload = () => {
  //Variables and states.
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);

  //Current user authorization.
  let user_str = localStorage.getItem("HrApiData");
  let user = JSON.parse(user_str);

  //Constant variables for file validation.
  const MAX_FILE_SIZE_MB = 10;
  const MAX_FILENAME_LENGTH = 50;

  //Function to handle file change on selection.
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    //Validate the file size.
    if (selectedFile && selectedFile.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(`File size should be less than ${MAX_FILE_SIZE_MB} MB`);
      return;
    }

    //Validate the filename length
    if (selectedFile && selectedFile.name.length > MAX_FILENAME_LENGTH) {
      setError(
        `Filename length should be less than ${MAX_FILENAME_LENGTH} characters`
      );
      return;
    }

    setError(null);

    //Set the state of the file.
    setPdfFile(selectedFile);

    //Display the selected PDF-Not yet uploaded.
    if (selectedFile) {
      const pdfObjectURL = URL.createObjectURL(selectedFile);
      setPdfUrl(pdfObjectURL);
    } else {
      setPdfUrl(null);
    }
  };

  //Function to handle file upload.
  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("pdfFile", pdfFile);

      const response = await axios.post(
        "http://localhost:4000/fileupload/uploadPDFFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const uploadedPdfFilename = response.data.data.filename;
      const uploadedPdfPath = response.data.data.path;

      console.log("File Upload Response:", response.data);

      setPdfUrl(uploadedPdfPath);
    } catch (error) {
      console.error("File Upload Error:", error.message);
    }
  };

  return (
    <div>
      <h2>File Upload</h2>
      <input type="file" accept=".pdf" onChange={handleFileChange} />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <button onClick={handleFileUpload} disabled={!pdfFile || error}>
        Upload PDF
      </button>

      {pdfUrl && (
        <div>
          <h3>Selected PDF:</h3>
          <iframe title="pdfViewer" src={pdfUrl} width="100%" height="500px" />
        </div>
      )}
    </div>
  );
};

export default FileUpload;
