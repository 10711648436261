import React, { useState } from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/pizza.jpeg";
import axios, { Axios } from "axios";
import "../styles/Main.css";
import Navbar from "./../components/Navbar";

function Home() {
  // const[joke, setJoke] = useState("")

  const connect_to_api = () => {
    // const axios = require('axios');
    let data = JSON.stringify({
      email: "shivneel@oceanic.com.fj",
      password: "oceanic",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "users/authenticate",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="home">
      <Navbar />
      <div className="">
        <div className="text-center">
          <h1>COMING SOON</h1>
        </div>
      </div>
    </div>

    // <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
    //   <Navbar />
    //   <div className="headerContainer">
    //     <h1>Oceanic Pizzeria </h1>
    //     <p> PIZZA TO FIT ANY TASTE</p>
    //     <Link to="/menu">
    //       <button> ORDER NOW </button>
    //     </Link>
    //     <br></br>
    //     <br></br>
    //     <Link>
    //       <button className="text-uppercase" onClick={connect_to_api}> Connect To API </button>
    //     </Link>
    //   </div>
    // </div>
  );
}

export default Home;
