import React from 'react'
import BannerImage from "../assets/pizza.jpeg";


function ProfileItem({image, name, role, department, email, phone, link}) {
  return (
    <div>
      <div className='profileItem'>
        <a href={'/profile/${id}'} target='_self' className='text-decoration-none' >
        <div style={{ backgroundImage: `url(${image})` }}></div>
        <h1>Name: {name}</h1>
        <h2>Role: {role}</h2>
        <h2>Department: {department}</h2>
        <h2>Email: {email}</h2>
        <h2>Phone: {phone}</h2>
        </a>
      </div>
    </div>
  )
}

export default ProfileItem


