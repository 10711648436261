import React from "react";
import "../styles/TeamProfile.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navbar from '../components/Navbar';
import "../styles/Main.css";
import axios from "axios";
import { faLink, faPencil, faArrowRight, faCheck, faPlus, faAsterisk, faTrash, faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, ChangeEvent } from "react";
import $ from 'jquery';
import Swal from 'sweetalert2';
import staffImg from '../assets/img/OceanicLogo.png';
import dayjs from "dayjs";
import { Start } from "@mui/icons-material";
import DataTable from 'react-data-table-component';


function LeaveInsidePage() {
    const navigate = useNavigate();
    let [leaveDetails, setleaveDetails] = useState([]);
    let [declinecomments, setdeclinecomments] = useState([]);
    let [permisionListUX, SetpermisionListUX] = useState([]);
    let user_str = localStorage.getItem("HrApiData");
    let user = JSON.parse(user_str);
    const location = useLocation();

    useEffect(() => {
        // console.log(location.state.x, 'location');

        const fetchata = async () => {
            axios({
                method: "GET",
                url: "leavemanagement/getAllLeaveApplications",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {},
            })
                .then(async result => {
                    // console.log(result, 'getAllLeaveApplications');
                    SetpermisionListUX(result.data.permissionList[0]);
                    if (result.data.code == 1) {
                        setleaveDetails(result.data.leavelist);
                        // if (check == true) {
                        setTimeout(() => {
                            $("#leaves_tbl").DataTable({
                                lengthMenu: [
                                    [5, 10, 25, 50, -1],
                                    [5, 10, 25, 50, "All"],
                                ],
                                pageLength: 10,
                            });
                        });
                    }
                    else if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'No records Found',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                    else if (result.data.code == 99) {
                        //Hide Add Staff Btn & Data table Header Fields
                        //Show A Div with Message - User Not Permitted
                        $(".accessDeniedMsg").css("display", "block");
                        $(".LeaveFormDiv").css("display", "none");
                        $(".accessDeniedMsg h1").html("User Not Permitted");

                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 2500,
                        })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
        fetchata();
    }, []);

    const viewOnClickRow = (e) => {
        e.preventDefault();
    }

    const approveOnClickRow = (e, leaveData) => {
        e.preventDefault();
        axios({
            method: "POST",
            url: "leavemanagement/approveApplication",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            data: {
                id: leaveData.id,
            },
        })
            .then(async result => {
                // console.log(result, 'leavelist');
                if (result.data.code == 1) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Leave approved successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    setleaveDetails(result.data.leavelist);
                    navigate('/leaves');
                }
                else if (result.data.code == 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'No records Found',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }
                else if (result.data.code == 99) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Access Denied!',
                        text: 'User does not have permission to access this page.',
                        showConfirmButton: false,
                        timer: 2500,
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'An error occured. Please contact Admin',
                    showConfirmButton: false,
                    timer: 1500,
                })
            });
    }

    const declineOnClickRow = (e, leaveData) => {
        var error = new Array();
        $('.decline_form').addClass('d-block');
        e.preventDefault();
        if (declinecomments.length == 0) {
            $('#decline_comments_msg').html('');
            $('#decline_comments').addClass('invalid');
            $('#decline_comments_msg').html('Comment is required');
            $('#decline_comments_msg').removeClass("d-none");
            error.push('true');
        }
        var exist = !$.inArray("true", error);
        // dateFilled = dateFilled;
        if (exist == false) {
            axios({
                method: "POST",
                url: "leavemanagement/declineApplication",
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                data: {
                    id: leaveData.id,
                    comments: declinecomments,
                },
            })
                .then(async result => {
                    // console.log(result, 'leavelist');
                    //check if comments is filled then submitt else do not show


                    if (result.data.code == 1) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Leave declined successfully!",
                            showConfirmButton: false,
                            timer: 1500,
                        })
                        setleaveDetails(result.data.leavelist);
                        navigate('/leaves');
                    }
                    else if (result.data.code == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'No records Found',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                    else if (result.data.code == 99) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Access Denied!',
                            text: 'User does not have permission to access this page.',
                            showConfirmButton: false,
                            timer: 2500,
                        })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'An error occured. Please contact Admin',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                });
        }
        return false;
    }

    let goback = e => {
        e.preventDefault();
        navigate("/leaves")
    }

    return (
        <div className="LeaveForm px-3">
            <Navbar />
            <div className="accessDeniedMsg pt-5 mt-5 text-center">
                <h1></h1>
            </div>
            <div className="LeaveFormDiv mb-5">
                <h1>{location.state.x.first_name} {location.state.x.last_name} - {location.state.x.leavetypename} - Application </h1>
            </div>
            <div className="row text-start p-5 mx-5 bg-light">
                <div className="form-group col-md-6">
                    <label htmlFor="name" className="form-label align-self-end"><strong>Name:</strong> {location.state.x.first_name} {location.state.x.last_name}</label>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="" className="form-label align-self-end"><strong>Leave Type:</strong> {location.state.x.leavetypename}</label>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="" className="form-label align-self-end"><strong>Leave Start Date:</strong> {location.state.x.leave_start_date}</label>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="" className="form-label align-self-end"><strong>Leave End Date:</strong> {location.state.x.leave_end_date}</label>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="" className="form-label align-self-end"><strong>Total Leave Days:</strong> {location.state.x.total_leave_days}</label>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="" className="form-label align-self-end"><strong>Date Created:</strong> {location.state.x.createdAt}</label>
                </div>
                <div className="form-group col-md-12">
                    <label htmlFor="" className="form-label align-self-end"><strong>Comments:</strong> {location.state.x.comments}</label>
                </div>
                <div className="row text-center">
                    <div className="col-12">
                        {permisionListUX.includes("canapproveleave") ? (
                            <button type="submit" className="btn btn-success my-2 col-2 mx-3" onClick={(e) => approveOnClickRow(e, location.state.x)}>Approve</button>
                        ) : (
                            <span></span>
                        )}
                        {permisionListUX.includes("candeclineleave") ? (
                            <button type="submit" className="btn btn-danger my-2 col-2 mx-3" onClick={(e) => declineOnClickRow(e, location.state.x)}>Decline</button>

                        ) : (
                            <span></span>
                        )}
                        <button type="submit" className="btn btn-secondary my-2 col-2 mx-3" onClick={(e) => goback(e)}>Go Back</button>

                    </div>
                </div>
                <form className="decline_form">
                    {/* Comments for leave decline*/}
                    <label htmlFor="comments" className="form-label align-self-end mb-0 mt-3">Comments:&nbsp;</label>
                    <div className="form-group col-md-12 mx-0 px-0">
                        <div className="input-group">
                            <textarea type="text" className="form-control" id="decline_comments" placeholder="" name="decline_comments" value={declinecomments} onChange={(e) => setdeclinecomments(e.target.value)}></textarea>
                            <div className="input-group-append">
                                <span className="input-group-text"><FontAwesomeIcon icon={faAsterisk} /></span>
                            </div>
                        </div>
                        <small id="decline_comments_msg" className="showmsg"></small>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LeaveInsidePage;
